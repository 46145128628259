const preKey = 'MAP_VIEW';

exports.VEHICLES_FETCHING = `${preKey}_VEHICLES_FETCHING`;
exports.VEHICLES_ERROR = `${preKey}_VEHICLES_ERROR`;
exports.VEHICLES_SUCCESS = `${preKey}_VEHICLES_SUCCESS`;

exports.FOCUS_VEHICLE = `${preKey}_FOCUS_VEHICLE`;
exports.UPDATE_VEHICLE_BY_SOCKET = `${preKey}_UPDATE_VEHICLE_BY_SOCKET`;
exports.PIE_STATUS_SELECTED = `${preKey}_PIE_STATUS_SELECTED`;

exports.UPDATE_VEHICLES_STATUS = `${preKey}_UPDATE_VEHICLES_STATUS`;
