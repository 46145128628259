/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import TableroGeneral from '@views/TableroGeneral';
import { mainMenuIconBaseProps as iconProps } from '@utils/params';

const path = '/tablero-general';

const tableroGeneral = {
  path,
  name: 'Tablero General',
  icon: <FontAwesomeIcon {...iconProps} icon={faChartLine} />,
  component: TableroGeneral,
  key: 'item-1',
  children: [
    {
      path: `${path}/:vehicleId`,
      name: '',
      key: 'item-1',
    },
    {
      path: `${path}/tablas`,
      name: '',
      key: 'item-1',
    },
    {
      path: `${path}/mapa`,
      name: '',
      key: 'item-1',
    },
  ],
};

export default tableroGeneral;
