import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import  CargadorView from '@views/CargadoresCPO/views/CargadorView';
import { /* TablesView, */ CrearCargador, MapsView /* VehicleView */ } from './views';

const GeneralStatusCPO = () => {
  const match = useRouteMatch();

  const mapRoute = `${match.path}/mapa`;
  const cargadorRoute = `${match.path}/mapa/:cargadorId`;
  const crearCargadorRoute = `${match.path}/crear_cargador`;
  const editarCargadorRoute = `${match.path}/editar_cargador/:cargadorId`;

  // const { name, children } = cargadoresCPO;  
  // const vehicleRoute = `${match.path}/:vehicleId`;

  return (
    <Switch>
      {/* <Route path={tableRoute} component={TablesView} /> */}
      <Route  path={cargadorRoute} component={CargadorView} />
      <Route path={mapRoute} component={MapsView} />
      <Route path={crearCargadorRoute} component={CrearCargador} />
      <Route path={editarCargadorRoute} component={CrearCargador} />
      
      <Redirect to={mapRoute} />
    </Switch>
  );
};

export default GeneralStatusCPO;
