import { post } from './management';

const sendStart = (body) => post('/commands/START_SESSION', body,true);
const sendStop = (body) => post('/commands/STOP_SESSION', body,true);
const sendRestart = (body) => post('/commands/RESET', body,true);


const setChargingProfile = (body) => post('/commands/SET_CHARGING_PROFILE', body,true);;


export default { sendStart, sendStop,sendRestart,setChargingProfile };