import moment from 'moment';

import { missingRanges } from '@utils/helpers/dateSetup';

function normalizeData(jorneys, charges, dateRange) {
  if (!jorneys || !charges) return null;

  let chartData = [];

  const arraysInfo = [
    { array: jorneys, status: 'Conducción', fill: '#5D7092' },
    { array: charges, status: 'Recarga', fill: '#5AD8A6' },
  ];

  arraysInfo.forEach(({ array, status, fill }) => {
    for (let i = 0; i < array.length; i += 1) {
      const item = array[i];
      const { time_start: start, time_end: end } = item;
      chartData.push({ start: moment(start), end: moment(end), status, fill });
    }
  });

  chartData.sort(({ start: startA }, { start: startB }) => {
    if (startA.isSame(startB)) return 0;
    return startA.isBefore(startB) ? -1 : 1;
  });

  if (chartData.length > 0) {
    const last = chartData[chartData.length - 1];
    const { end: lastDate } = last;
    if (!lastDate.isValid()) last.end = moment();
  }

  chartData = chartData.filter(({ start, end }) => start.isValid() && end.isValid());

  const rangesArray = chartData.map(({ start, end }) => [start, end]);
  let gapsDateRanges = missingRanges(rangesArray, dateRange);
  gapsDateRanges = gapsDateRanges.map(([start, end]) => ({
    start,
    end,
    status: 'Paradas',
    fill: '#5B8FF9',
  }));

  chartData = [...chartData, ...gapsDateRanges];

  chartData.sort(({ start: startA }, { start: startB }) => {
    if (startA.isSame(startB)) return 0;
    return startA.isBefore(startB) ? -1 : 1;
  });

  return chartData;
}

export default normalizeData;
