import React, { useEffect,useState } from 'react';
import { Card,Row,Col } from 'antd';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Text, Brush
} from 'recharts';
import { VerticalLabel } from './components';
import PropTypes from 'prop-types';
import './style.less';

const CardGraphic = ( props ) => {
    const { title , graphicType , dataRaw, type, labelY } = props;

    const [ defaultVBarSize , setDefaultVBarSize ] = useState(50);
    const [ data , setData ] = useState({
        array:[]
    });

    const [ xAxisLabels , setXAxisLabels ] = useState({
        data:[]
    });

    const colorMapAxis = {
        TIPO_1:`#FF812E`,
        TIPO_2:`#0dc390`,
        CHADEMO:`#52a7da`,
    }

    const [ ticksYDomains , setTickYDomain ] = useState([]);

    const defaultXAxis = [`TIPO_1`,`TIPO_2`,`CHADEMO`];

    // const data = [
    //     {
    //       name: 'SURCA001', tipo_1: 4000, tipo_2: 2400, 
    //     },
    //     {
    //       name: 'SURCA002', tipo_1: 3000, tipo_2: 1398,
    //     },
    //     {
    //       name: 'SURCA003', tipo_1: 2000, tipo_2: 9800,
    //     },
    // ];

    const dataEstado = [
        {
            name: 'CC_Ovi tipo 1', disponible: 3200, cargando: 2200, fallo: 2400, sincarga: 3400, 
        },
        {
            name: 'CC_Ovi tipo 2', disponible: 5900, cargando: 1900, fallo: 2400, sincarga: 400,
        },
        {
            name: 'CC_Ovi tipo 3', disponible: 4000, cargando: 2000, fallo: 2800, sincarga: 2400,
        },
    ];

    useEffect(() => {
        let arrayTemp = [];
        let xAxisLabelsTemp = [];
        switch(type){
            case 'energia_entregada':
                for( let i = 0 ; i < dataRaw.length ; i++ ){
                    arrayTemp.push({
                        name:`${dataRaw[i].evse_uid__evse_id}`,
                        [`${dataRaw[i].connector_id__standard}`]:dataRaw[i].kwh !== 0 ? dataRaw[i].kwh : 0
                    });
        
                    xAxisLabelsTemp.push(`${dataRaw[i].connector_id__standard}`);
                }

                if(xAxisLabelsTemp.length >= 1){
                    setXAxisLabels({data:[...new Set(xAxisLabelsTemp)]});
                }else{
                    setXAxisLabels({data:defaultXAxis});
                }
                break;
            case 'tiempo_uso':
                for( let i = 0 ; i < dataRaw.length ; i++ ){
                    arrayTemp.push({
                        name:`${dataRaw[i].evse_uid__evse_id}`,
                        [`${dataRaw[i].connector_id__standard}`]: obtener_horas(dataRaw[i].time_use)
                    });
        
                    xAxisLabelsTemp.push(`${dataRaw[i].connector_id__standard}`);
                }

                if(xAxisLabelsTemp.length >= 1){
                    setXAxisLabels({data:[...new Set(xAxisLabelsTemp)]});
                }else{
                    setXAxisLabels({data:defaultXAxis});
                }
                break;
            case 'disponibilidad':
                let dataInterpeter = {
                    na:{
                        disponible:0,
                        fallo:0
                    }
                }
                if(dataRaw.length > 0){
                    dataInterpeter = {}
                    let arrayTickTemp = [];
                    // for(let h = 0 ; h < 40 ; h ++){ // PARA PRUEBAS DE MULTIPLES CARGADORES
                        for( let i = 0 ; i < dataRaw.length ; i++ ){
                            const uidTemp = `${dataRaw[i].evse_uid}`;
                            arrayTickTemp.push(uidTemp);
                            if(!dataInterpeter[`${uidTemp}`]){
                                dataInterpeter[`${uidTemp}`] = {};
                            }
        
                            switch(dataRaw[i].status){
                                case 'ONLINE':
                                    dataInterpeter[`${uidTemp}`]['disponible'] = parseFloat(dataRaw[i].duration);
                                    break;
                                case 'OFFLINE':
                                    dataInterpeter[`${uidTemp}`]['fallo'] = parseFloat(dataRaw[i].duration);
                                    break;
                            }
                            
                
                            
                        }
                    // }
                    setTickYDomain(arrayTickTemp);       
                }

                

                let keys = Object.keys(dataInterpeter);
                for(let i = 0 ; i < keys.length ; i++){
                    let dispoTemp = dataInterpeter[`${keys[i]}`].disponible ? dataInterpeter[`${keys[i]}`].disponible : 0;
                    let falloTemp = dataInterpeter[`${keys[i]}`].fallo ? dataInterpeter[`${keys[i]}`].fallo : 0;
                    arrayTemp.push({
                        name:`${keys[i]}`,
                        disponible: parseFloat(dispoTemp),
                        fallo: parseFloat(falloTemp),
                        desconocido: parseFloat(100 - (dispoTemp + falloTemp))
                    });
                    // xAxisLabelsTemp.push(`${keys[i]}`);
                }

                break;
        }
        
        
        
        
        setData({array:arrayTemp});
    },[dataRaw]);

    const obtener_horas = ( item ) => {
        let segundosTotales = item;
        let minutosTotales = segundosTotales/60;

        // console.log(item);
        // if(!item) return 0;
        // let itemSplit = item.split(':');
        // let horas = parseInt(itemSplit[0]);
        // let minutos = parseInt(itemSplit[1]);
        // let segTempSplit = itemSplit[2].split('.');
        // let segundos = parseInt(segTempSplit[0]);
        // let milis = segTempSplit.length > 1 ?  parseInt(segTempSplit[1]) : 0;
        // let horasFinal = parseFloat((parseInt(horas) + parseFloat(mapeo_horas(minutos,0,60,0,1)).toFixed(1)));
        return minutosTotales;
    }

    const formatter_duration = ( value ) => {
        if(isNaN(value)) value = 0;
        let minutesInit = parseInt(value);
        let sec = parseInt(minutesInit * 60);
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor(sec / 60) % 60;
        let secLess = sec % 60 ;
        
        return `${hours}:${minutes}:${secLess}`;
    }
    // useEffect(()=>{
    //     console.log(xAxisLabels);
    // },[xAxisLabels]);

    const CustomizedAxisTick = props => {
        const { x, y, payload } = props
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text dy={16} textAnchor='middle' fill='#666'>{payload.value}%</text>
          </g>
        )
    
    
    }

    const CustomizedYAxisTick = props => {
        const { x, y, payload } = props
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text dy={5} textAnchor='end' fill='#666'>{payload.value}</text>
          </g>
        )
    }

    const StaticXAxis = () => {
        const dxIni = 0;
        const widthCustom = 100;
        const widthSteps = widthCustom/10;
        return (
            <div className={'x-axis-wrapper'}>
                <svg className="recharts-surface svg-custom-xaxis" width="100%" >
                    <g className="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis"  >
                        <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="800" height="30" x={dxIni}    viewBox="0 0 800 300" stroke="#666" fill="none" x1={dxIni}  x2={`${widthCustom}%`}  ></line>
                        <g className="recharts-cartesian-axis-ticks">
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*0}%`} y1="5"   x2={`${widthSteps*0}%`} y2="0"  ></line>
                                <g >
                                    <text dx={dxIni} dy="16" textAnchor="left" fill="#666">0%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*1}%`} y1="5"  x2={`${widthSteps*1}%`} y2="0" ></line>
                                <g >
                                    <text dx={`${widthSteps*1}%`} dy="16" textAnchor="middle" fill="#666">10%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*2}%`} y1="5"   x2={`${widthSteps*2}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*2}%`} dy="16" textAnchor="middle" fill="#666">20%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*3}%`} y1="5"  x2={`${widthSteps*3}%`} y2="0"  ></line>
                                <g >
                                    <text dx={`${widthSteps*3}%`} dy="16" textAnchor="middle" fill="#666">30%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*4}%`} y1="5"  x2={`${widthSteps*4}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*4}%`} dy="16" textAnchor="middle" fill="#666">40%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*5}%`} y1="5"  x2={`${widthSteps*5}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*5}%`} dy="16" textAnchor="middle" fill="#666">50%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*6}%`} y1="5"  x2={`${widthSteps*6}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*6}%`} dy="16" textAnchor="middle" fill="#666">60%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*7}%`} y1="5"  x2={`${widthSteps*7}%`}  y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*7}%`} dy="16" textAnchor="middle" fill="#666">70%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*8}%`} y1="5"  x2={`${widthSteps*8}%`}  y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*8}%`} dy="16" textAnchor="middle" fill="#666">80%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*9}%`} y1="5"  x2={`${widthSteps*9}%`} y2="0"  ></line>
                                <g>
                                    <text dx={`${widthSteps*9}%`} dy="16" textAnchor="middle" fill="#666">90%</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"  stroke="#666" fill="none" x1={`${widthSteps*10}%`} y1="5"  x2={`${widthSteps*10}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*10}%`} dy="16" textAnchor="end" fill="#666">100%</text>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }


    const cargarGrafica = () => {
        switch(graphicType){
            case 'bar-horizontal':
                return(
                    <div className={'metrica-bar-horizontal'}>
                        <StaticXAxis/>
                        <ResponsiveContainer minHeight={(30 * (data.array.length+4))}>
                            <BarChart
                            // width={500}
                            // height={500}
                            data={data.array}
                            layout={'vertical'}
                            >
                                <CartesianGrid strokeDasharray="3 3"    />
                                <XAxis hide axisLine tick={CustomizedAxisTick} domain={[0,100]} ticks={[0,10,20,30,40,50,60,70,80,90,100]} type={'number'} />
                                <YAxis  interval={0} tick={CustomizedYAxisTick} width={110}  dataKey={'name'} type={'category'}/>
                                <Tooltip />
                                <Legend  align={'left'} verticalAlign={'top'} height={60} />
                                <Bar dataKey="disponible" name={'Disponible'} stackId="a" fill="#6cc16b" />
                                <Bar dataKey="fallo" name={'Fallo de conexión'} stackId="a" fill="#f70e4b" />
                                <Bar dataKey="desconocido" name={'Desconocido'} stackId="a" fill="#adadad" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                );
            case 'bar-vertical':
                return(
                    <ResponsiveContainer minHeight={300}>
                        <BarChart
                        data={data.array}
                        layout={'horizontal'}
                        >
                            <CartesianGrid strokeDasharray="3 3"  />
                            <XAxis type={'category'} dataKey="name" />
                            <YAxis angle={-90}  type={'number'} label={
                                <Text
                                x={0}
                                y={0}
                                dx={25}
                                dy={150}
                                offset={0}
                                angle={-90}>
                                    {labelY ? labelY : ''}
                                </Text>
                            } />
                            {
                                type === 'tiempo_uso' &&
                                <Tooltip formatter={ (value, name, props) => ([`${formatter_duration(value)}`,`${name}`])} /> ||
                                <Tooltip  /> 
                            }
                            <Legend />
                            {
                                xAxisLabels.data.map((value,index)=>(
                                    <Bar key={`barXaxis_${index}`} dataKey={`${value}`} stackId="a" fill={colorMapAxis[`${value}`]}  />        
                                ))
                            }
                            {/* <Bar dataKey="tipo_1" stackId="a" fill="#f08219"  />
                            <Bar dataKey="tipo_2" stackId="a" fill="#19f07d" /> */}
                        </BarChart>
                    </ResponsiveContainer>
                );
            default:
                return(
                    <ResponsiveContainer minHeight={300}>
                        <BarChart
                        data={data.array}
                        >
                            <CartesianGrid strokeDasharray="3 3"  />
                            <XAxis axisLine={true} dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="tipo_1" stackId="a" fill="#f08219"  />
                            <Bar dataKey="tipo_2" stackId="a" fill="#19f07d" />
                        </BarChart>
                    </ResponsiveContainer>
                );
        }
        
    }

    return(
        <Card>
            <Row>
                <Col span={24}>
                    <p className={'card-graphic_title'}>{title}</p>
                </Col>
                <Col span={24}>
                    {
                        cargarGrafica(graphicType)
                    }
                </Col>
            </Row>
        </Card>
    );
}   

CardGraphic.propTypes = {
    title: PropTypes.string,
    graphicType:PropTypes.oneOf(['bar-horizontal','bar-vertical']),
}

CardGraphic.defaultProps = {
    title:'Titulo grafica',
    graphicType:'default',
    dataRaw:[]
}

export default CardGraphic;