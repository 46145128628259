import { apiLocations } from '../../utils/services/cpo';
import locationsConst from '@store/constants/locationsConst'

const getLocations = (user) => async (dispatch, getState) => {
    const {
        LOCATION_FETCHING_ITEMS: fetching,
        LOCATION_SUCCESS_ITEMS: success,
        LOCATION_ERROR_ITEMS: error,
    } = locationsConst;

    let response;
    dispatch({type:fetching});
    try{
        response = await apiLocations.locations(user.user_id);
    }catch(err){
        return dispatch({type:error,payload:err});
    }

    // console.log(response);
    const locations = response.map((item,index) => {
        return {
            key: `${item.evse["id"]}_${index}`,
            id: item.evse["evse"],
            raw_id:item.evse["id"],
            operador: item.evse["evse_operator"],
            estacion: item.evse["evse_location_name"],
            raw_conector_id:item.id,
            conector_id: item.conn_id,
            conector: item.standard,
            estado: item.status_connector,
            uso: item.evse["evse_type_use"],
            direccion: item.evse["evse_location_address"],
            acciones: `${item.evse["evse"]}`
        }
    });

    const payload = {
        locations:locations,
        locationsRaw:response
    }

    return dispatch({type:success,payload});
}

const updateLocation = (json) => async (dispatch,getState)  => {
    const evsesObject = getState().locations;
    const arrayLocations = evsesObject.locations;

    const {
        LOCATION_UPDATE_ITEMS: update,
        LOCATION_SUCCESS_ITEMS: success,
    } = locationsConst;


    for(let i = 0 ; i < arrayLocations.length ; i++){
        if(arrayLocations[i].raw_id === json.evse_id && arrayLocations[i].raw_conector_id === json.connector_id){
            arrayLocations[i] = {
                ...arrayLocations[i],
                estado:json.status
            }
            break;
        }
    }


    const dResult = dispatch({type:update,payload:arrayLocations});
    return dResult;

}

/**
 * 
 * client: 0
event: "CHANGE_STATUS"
evse_id: "testevse"
status: "AVAILABLE"
 */


export default {
    getLocations,
    updateLocation,
}