exports.FETCHING_VEHICLES = 'FETCHING_VEHICLES';
exports.ERROR_VEHICLES = 'ERROR_VEHICLES';
exports.SUCCESS_VEHICLES = 'SUCCESS_VEHICLES';

exports.UPDATE_VEHICLES_SELECTED = 'UPDATE_VEHICLES_SELECTED';
exports.UPDATE_ALARMS_SELECTED = 'UPDATE_ALARMS_SELECTED';
exports.SET_RANGE_DATE = 'SET_RANGE_DATE';

exports.FETCHING_DISTANCE_CHART = 'FETCHING_DISTANCE_CHART';
exports.SUCCESS_DISTANCE_CHART = 'SUCCESS_DISTANCE_CHART';
exports.ERROR_DISTANCE_CHART = 'ERROR_DISTANCE_CHART';

exports.UPDATE_VEHICLE = 'UPDATE_VEHICLE_RF';

exports.FETCHING_EFICIENCIA_RF = 'FETCHING_EFICIENCIA_RF';
exports.SUCCESS_EFICIENCIA_RF = 'SUCCESS_EFICIENCIA_RF';
exports.ERROR_EFICIENCIA_RF = 'ERROR_EFICIENCIA_RF';

exports.SELECT_GROUP_ID_RF = 'SELECT_GROUP_ID_RF';
exports.SELECT_DATE_TYPE_RF = 'SELECT_DATE_TYPE_RF';
