import React, { useState, useCallback } from 'react';
import { Button, Modal, Typography, Input, Space, Form, message } from 'antd';

import { apiUser } from '@utils/services';

const { Paragraph } = Typography;

const RecoveryPassword = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onOk = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (error) {
      return null;
    }

    setLoading(true);
    let response;
    try {
      response = await apiUser.resetPassword(values);
    } catch (err) {
      return null;
    }
    message.success(`Hemos enviado instrucciones a tu correo electrónico.`);
    setLoading(false);

    setTimeout(() => {
      setVisible(false);
    }, 1500);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Button type="link" onClick={() => setVisible(!visible)}>
        ¿Olvidaste tu contraseña?
      </Button>
      <Modal
        title="¿Olvidaste tu contraseña?"
        visible={visible}
        confirmLoading={loading}
        onOk={onOk}
        onCancel={() => setVisible(false)}
      >
        <Form form={form}>
          <Space direction="vertical" size="large">
            <Paragraph>
              Por favor escribe tu correo con el cual estas registrado para enviarte las
              instrucciones necesarias para volver a acceder a tu cuenta
            </Paragraph>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Por favor ingresa tu correo' },
                { type: 'email', message: 'Por favor ingresa un correo valido' },
              ]}
            >
              <Input type="email" placeholder="Correo" onBlur={console.log} />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default RecoveryPassword;
