import ConfigClientes from '@views/ConfigClientes';

const path = '/configuracion-cuenta/clientes';

const clientesMenu = {
  path,
  name: 'Clientes',
  bread:'Configuración de cuenta/clientes',
  icon: null,
  component:ConfigClientes,
  breadCompose:() => {
    return [[null,`Configuración de cuenta`],[null,`clientes`]];
  },
  key: 'config-user-clientes',
  children: [],
};

export default clientesMenu;