import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { List, Typography, Button, Space, Statistic, Divider } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { carOn, carOff, carChar, carStop, carDisconnected } from '@assets/img/markers';
import { tableroGeneral } from '@utils/menus/main/itemsMenu';
import actions from '@store/reducers/tableroGeneral/mapView/actions';

const { Paragraph } = Typography;
const imgStatusDic = {
  ON: carOn,
  OFF: carOff,
  CHA: carChar,
  BAD: carDisconnected,
};

function VehicleItem({ item }) {
  const dispatch = useDispatch();
  const { lic, sta, dev, ts, soc = -1, spe = -1, ct = [] } = item;
  const imgStatus = sta in imgStatusDic ? imgStatusDic[sta] : carStop;

  const max = useCallback(
    (input) => {
      if (toString.call(input) !== '[object Array]') return false;
      return Math.max.apply(null, input);
    },
    // eslint-disable-next-line
    []
  );

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(actions.setFocusVehicle(item));
    },
    // eslint-disable-next-line
    [item]
  );

  const showVehicle = useCallback(
    (event) => {
      event.stopPropagation();
    },
    // eslint-disable-next-line
    []
  );

  const strDate = ts ? moment(ts).format('D MMM YY HH:mm') : '';
  const battery = soc !== -1 ? `${soc} %` : '- %';
  const velocity = spe !== -1 ? `${spe} Km/h` : '- Km/h';
  const temperature = ct.length > 0 ? `${max(ct)} °C` : '- °C';

  return (
    <List.Item className="list__item" onClick={onClick}>
      <div className="list__item__vehicle--type">
        <img src={imgStatus} alt={`pin_${lic}_${sta}`} style={{ width: 40 }} />
      </div>

      <div className="list__item__vehicle--name">
        <Paragraph strong>{`${dev} - ${lic}`}</Paragraph>
        <Paragraph>{`Último dato: ${strDate}`}</Paragraph>

        <Space direction="horizontal" size="small">
          <Statistic valueStyle={{ fontSize: 14 }} value={battery} />
          <Divider type="vertical" />
          <Statistic valueStyle={{ fontSize: 14 }} value={velocity} />
          <Divider type="vertical" />
          <Statistic valueStyle={{ fontSize: 14 }} value={temperature} />
        </Space>
      </div>

      <Button type="link" className="button">
        <Link to={`${tableroGeneral.path}/${lic}`} onClick={showVehicle}>
          Ver
        </Link>
      </Button>
    </List.Item>
  );
}

VehicleItem.propTypes = {
  item: PropTypes.shape({
    lic: PropTypes.string,
    dev: PropTypes.string,
    status: PropTypes.string,
    mod: PropTypes.string,
    ts: PropTypes.string,
    soc: PropTypes.number,
    spe: PropTypes.number,
    ct: PropTypes.array,
    sta: PropTypes.string,
  }).isRequired,
};

export default VehicleItem;
