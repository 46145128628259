import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import AlertasModulo from '@views/Alertas';


const path = '/alertas';

const alertas = {
  path,
  name: 'Alerta',
  icon: <BellOutlined />,
  component: AlertasModulo,
  key: 'alerta',
  children: [],
};

export default alertas;
