import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { apiReportes } from '@utils/services';

function useRequest() {
  const placa = useSelector(({ vehicleView }) => vehicleView.placa);
  const [reqState, setReqState] = useState({
    error: null,
    data: null,
    loading: false,
  });
  const [showMore, setShowMore] = useState(false);
  const [date, setDate] = useState(moment().startOf('day'));
  const lastDate = useRef(null);

  const normalizeData = useCallback(
    (response) => {
      let lastItem = null;
      const startIndex =
        !reqState.data || (lastDate.current && !lastDate.current.isSame(date))
          ? 0
          : reqState.data.length;
      console.log('startIndex: ', startIndex);

      const data = response.map((item, index) => {
        const {
          time,
          odometer,
          voltage,
          current,
          soc,
          speed,
          status,
          device_temperature: temperature,
        } = item;
        const key = `log_${startIndex + index}`;

        const friendlyTime = moment(time).format('MMM DD YYYY h:mm:ss a');
        const kwh = (voltage * current) / 3600;
        let eficiencia = lastItem ? kwh / (odometer - lastItem.odometer) : '-';
        // eslint-disable-next-line no-restricted-globals
        eficiencia = isNaN(eficiencia) ? '-' : eficiencia;

        lastItem = item;
        return {
          key,
          odometer,
          soc,
          speed,
          kwh,
          eficiencia,
          current,
          status,
          temperature,
          time: friendlyTime,
          date: time,
        };
      });

      const { data: lastData } = reqState;

      // if (lastData && !lastDate.current)
      //   setReqState({ ...reqState, data: [...lastData, ...data], showMore: false });
      // else setReqState({ ...reqState, data });

      if ((lastDate.current && !lastDate.current.isSame(date)) || !lastData) {
        setReqState({ ...reqState, data });
      } else {
        setReqState({ ...reqState, data: [...lastData, ...data], showMore: false });
      }

      setShowMore(false);
      lastDate.current = date;
    },
    // eslint-disable-next-line
    [reqState, date, lastDate]
  );

  const request = useCallback(
    (newDate = date) => {
      const vehicles = [placa];

      const data = lastDate.current && lastDate.current.isSame(date) ? reqState.data : [];
      setReqState({ ...reqState, data, loading: true });
      apiReportes
        .getLogsDevice({ date: newDate, vehicles })
        .then(normalizeData)
        .catch((error) => setReqState({ ...reqState, error }));
    },
    // eslint-disable-next-line
    [placa, reqState, date]
  );

  const onClickMore = useCallback(
    () => {
      const { data } = reqState;
      const lastData = data[data.length - 1];
      const { date: lastDate } = lastData;

      request(moment(lastDate).add(1, 'seconds'));
    },
    // eslint-disable-next-line
    [reqState]
  );

  useEffect(
    () => {
      if (!date || !placa) return;

      request();
    },
    // eslint-disable-next-line
    [date, placa]
  );

  return { ...reqState, onClickMore, showMore, setShowMore, placa, date, setDate };
}

export default useRequest;
