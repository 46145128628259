import React, { useState, useCallback, useEffect } from 'react';
import Breadcrumb from '@containers/Breadcrumb';
import { Button, Form, Input, Select, Row, Col, Divider, Affix, Modal, message } from 'antd';
import Map from '@components/mapa/GoogleMap';
import { OpenLocationCode } from 'open-location-code';
import { MarkerClusterer, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { faArrowsAlt, faPlug,faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { apiConfigClientes } from '@utils/services/index';
import { apiLocations, } from '@utils/services/cpo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    AimOutlined,
    CloseOutlined,
    PlusOutlined,
    UserOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './style.less';
import { useRef } from 'react';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { FormularioLocation,Formulario, FormularioEvse, FormularioBrand, FormularioModel } from './components'

const { Option } = Select;

const CrearCargador = ( props ) => {
    const textLanguage = Spanish({screen:'evse-form'})
    const {
        match: {
            params: { cargadorId },
        }
    } = props;

    const userHistory = useHistory();
    const breadcrumbData = [
        ['/tablero-cpo', 'Tablero General'], 
        ...(cargadorId ? [[null, 'Editar Cargador'],[null,cargadorId]] : [[null, 'Nuevo cargador']])  
    ];
    const user = useSelector((state) => state.user);
    const [ topFix , setTopFix ] = useState(10);
    const [ bottomFix , setBottomFix ] = useState(0);
    const [ isModalBrandVisible, setModalBrandVisible ] = useState(false);
    const [ isModalModelVisible, setModalModelVisible ] = useState(false);
    const [ isModalVisible, setModalVisible ] = useState(false);
    const [ locations, setLocations ] = useState({
        data:[],
        loading:false
    });
    const [ dataLocationSend , setDataLocationForm ] = useState({
        address:'',
        name:'',
        city:0,
        country:0,
        plus_code:''
    });

    const [ dataFormSend, setDataForm ] = useState({
        evse_id:  "",
        location:  '',
        type_use: "0",
        evse_version: "0",
        ip: '', 
        puerto: '',
        chargerbox_id:'', 
        evse_brand:0,
        evse_model:0,
        maximum_consumption:"",
        latitude:"",
        longitude:""
    });
    const [ conectores, setConectores ] = useState({
        data:[
            {
                conn_id: 1,
                standard: "0",
                max_voltage: "",
                max_amperage: "",
                max_electric_power: ""
            }
        ]   
    });

    const [ conectoresMapState, setConectoresMapState] = useState([
        {
            standard: {
                msg:`Asignar un standard valido`,
                status:'success'
            },
            max_voltage: {
                msg:`Asignar un voltaje valido`,
                status:'success'
            },
            max_amperage: {
                msg:`Asignar un amperaje valido`,
                status:'success'
            },
            max_electric_power: {
                msg:`Asignar una potencia valida`,
                status:'success'
            },
        }
    ]);

    const [ evseMapState , setEvseMapState ] = useState({
        client:{
            msg:`Asignar un cliente valido`,
            status:'success'
        },
        evse_id:{
            msg:"Asignar un identificador",
            status:'success'
        },
        location:{
            msg:'Seleccionar un Locación',
            status:'success'
        },
        type_use:{
            msg:"Seleccionar un tipo de uso",
            status:'success'
        },
        evse_version:{
            msg:"Seleccionar una version",
            status:'success'
        },
        evse_brand:{
            msg:'Seleccionar una marca',
            status:'success'
        },
        evse_model:{
            msg:'Seleccionar un modelo',
            status:'success'
        },
        ip:{
            msg:'Asignar una ip valida',
            status:'success'
        },
        puerto:{
            msg:'Asignar un puerto',
            status:'success'
        },
        maximum_consumption:{
            msg:"Asignar una potencia maxima",
            status:'success'
        }
    })

    const [ locationMapState, setLocationMapState ] = useState({
        address:{
            msg:`Debes asignar una direccion`,
            status:'success'
        },
        name:{
            msg:`Debes asignar un nombre`,
            status:'success'
        },
        city:{
            msg:`Debes seleccionar una ciudad`,
            status:'success'
        },
        country:{
            msg:`Debes seleccionar un país`,
            status:'success'
        },
        plus_code:{
            msg:`Debes asignar un codigo plus`,
            status:'success'
        },
    });

    useEffect( () => {
        cargarLocations();
    },[]);


    const cargarLocations = () => {
        setLocations({...locations,loading:true});
        apiLocations.allLocations(user.user_id).then( (rslt) => {
            setLocations({loading:false,data:rslt});
        }).catch( (err) => {
            setLocations({...locations,loading:false});
            console.log(err);
        });
    }
    
    const cancelLocacion = () => {
        limpiarLocationForm();
        setModalVisible(false);
        limpiarLocationForm();
    }

    const modifyFormSend = (param,value) => {
        let formTemp = {
            ...dataFormSend,
            [`${param}`]:value,
        }

        // switch(param){
        //     case 'evse_brand':
        //         formTemp['evse_model'] = 0;
        //         cargarModelos(value);
        //         break;
        // }
        setDataForm(formTemp);
    }

    const crearLocation = () => {
        const comprobacion = comprobarCamposLocation();

        if(comprobacion != true){
            message.warn(comprobacion[1]);
            return;
        }


        const dataLocation = {
            ...dataLocationSend,
            client:user.clientSelected.client_id
        };
        console.log(dataLocation);
        apiLocations.createLocation(dataLocation).then( (rslt) => {
            console.log(rslt);
            setDataForm({
                ...dataFormSend,
                location:rslt.id,
            });
            cargarLocations();
            limpiarLocationForm();
            cancelLocacion();
        }).catch( (err) => {
            message.warn(textLanguage.EVSE_LOCATION_FORM_C_FAIL);
            console.log(err);
        });
    }

    const crearEvse = async () => {
        const comprobacion = comprobarCamposEvse();
        const comprobacionC = comprobarCamposConectores();
        
        if(comprobacion != true || comprobacionC != true) return;
        

        await crearEvseRequest();
        userHistory.replace('/tablero-cpo');
    }

    const crearYContinuar = async () => {
        const comprobacion = comprobarCamposEvse();
        const comprobacionC = comprobarCamposConectores();
        
        if(comprobacion != true || comprobacionC != true) return;

        await crearEvseRequest();
        limpiarFormularios();
    }

    const crearEvseRequest = () => {
        return new Promise( (resolve,reject)=> {
            let data = {
                ...dataFormSend,
                chargerbox_id:dataFormSend.evse_id,
                connectors_list:conectores.data
            }
    
            if(dataFormSend.evse_version === 'v16'){
                delete data.ip;
                delete data.puerto;
            }
            
            if(cargadorId){
                data['evse_ip'] = data.ip ? data.ip : '';
                data['port_evse'] =  data.puerto ? data.puerto : '';
                delete data.ip;
                delete data.puerto;
                
                data.location = `${data.location}`;
                apiLocations.editEvse(data).then( (rslt) => {
                    message.success(textLanguage.EVSE_EVSE_FORM_U_SUCCESS({name:dataFormSend.evse_id}));
                    resolve();
                }).catch( (err) => {
                    console.log(err);
                    message.warn(textLanguage.EVSE_EVSE_FORM_U_FAIL);
                    reject();
                })
            }else{
                apiLocations.createEvse(data).then( (rslt) => {
                    message.success(textLanguage.EVSE_EVSE_FORM_C_SUCCESS({name:dataFormSend.evse_id}));
                    resolve();
                }).catch( (err) => {
                    console.log(err);
                    message.warn(textLanguage.EVSE_EVSE_FORM_C_FAIL);
                    reject();
                })
            }
            console.log(data);
        });
    }

    const limpiarFormularios = () => {
        limpiarDataForm();
        limpiarLocationForm();
        limpiarConectoresForm();
    }

    const limpiarDataForm = () => {
        setDataForm({
            // client:'',
            evse_id: '',
            location: '',
            type_use: "0",
            evse_version: "0",
            ip:'', 
            puerto:'',
            chargerbox_id:'', 
            evse_brand: 0,
            evse_model: 0,
            maximum_consumption: "",
            latitude: "",
            longitude: ""
        });
    }

    const limpiarLocationForm = () => {
        setDataLocationForm({
            address:'',
            name:'',
            city:0,
            country:0,
            client:0,
            plus_code:''
        });
    }

    const limpiarConectoresForm = () => {
        setConectores({
            data:[
                {
                    conn_id:1,
                    standard: "0",
                    max_voltage: "",
                    max_amperage: "",
                    max_electric_power: ""
                }
            ]
        });
        
        setConectoresMapState([
            {
                standard: {
                    msg:`Asignar un standard valido`,
                    status:'success'
                },
                max_voltage: {
                    msg:`Asignar un voltaje valido`,
                    status:'success'
                },
                max_amperage: {
                    msg:`Asignar un amperaje valido`,
                    status:'success'
                },
                max_electric_power: {
                    msg:`Asignar una potencia valida`,
                    status:'success'
                },
            }
        ]);

        
    }

    const updateConector = (index,key,value) => {
        let conectoresTemp = conectores.data;
        for(let i = 0 ; i < conectoresTemp.length ; i++){
            if(conectoresTemp[i].conn_id == index){
                conectoresTemp[i][`${key}`] = value;
                break;
            }
        }
        setConectores({data:conectoresTemp});
    }

    const comprobarCamposLocation = () => {
        const locationDefault = {
            address:'',
            name:'',
            city:0,
            country:0,
            plus_code:''
        }

        let keys = Object.keys(dataLocationSend);

        let rslt = null;
        let locationStatusTemp = locationMapState;

        for(let i = 0 ; i < keys.length ; i++){
            if(dataLocationSend[`${keys[i]}`] === locationDefault[`${keys[i]}`]){
                locationStatusTemp = {
                    ...locationStatusTemp,
                    [`${keys[i]}`]:{
                        ...locationStatusTemp[`${keys[i]}`],
                        status:'error'
                    }
                }; 

                if(!rslt) rslt = [false,locationStatusTemp[`${keys[i]}`].msg];
            }else{
                locationStatusTemp = {
                    ...locationStatusTemp,
                    [`${keys[i]}`]:{
                        ...locationStatusTemp[`${keys[i]}`],
                        status:'success'
                    }
                }; 
            } 
        }

        setLocationMapState(locationStatusTemp);

        if(rslt) return rslt;

        return true;
        
    }

    const comprobarCamposConectores = () => {
        const conectorDefault = {
            standard: "0",
            max_voltage: "",
            max_amperage: "",
            max_electric_power: ""
        }
        let rslt = null;
        let conStateTemp = conectoresMapState;
        for(let i = 0 ; i < conectores.data.length ; i++){
            const dataConector = conectores.data[i];
            let keys = Object.keys(conectorDefault);

            for(let k = 0 ; k < keys.length ; k++){
                if(dataConector[`${keys[k]}`] === conectorDefault[`${keys[k]}`]){
                    conStateTemp[i][`${keys[k]}`].status = 'error';
                    if(!rslt) rslt = [false,`${dataConector[`conn_id`]}: ${conStateTemp[i][`${keys[k]}`].msg} `]
                }else{
                    conStateTemp[i][`${keys[k]}`].status = 'success';
                }
            }
        }

        setConectoresMapState(conStateTemp)

        if(rslt) return rslt;

        return true;
    }

    const comprobarCamposEvse = () => {
        let evseDefault = {
            evse_id: "",
            location: '',
            type_use: "0",
            evse_version: "0",
            evse_brand: 0,
            evse_model: 0,
            maximum_consumption: "",
        }

        if(dataFormSend.evse_version === 'v15'){
            evseDefault['ip'] = '';
            evseDefault['puerto'] = '';
        }
        let keys = Object.keys(dataFormSend);

        let rslt = null;
        let evseStatusTemp = evseMapState;

        for(let i = 0 ; i < keys.length ; i++){
            if(dataFormSend[`${keys[i]}`] === evseDefault[`${keys[i]}`]){
                evseStatusTemp = {
                    ...evseStatusTemp,
                    [`${keys[i]}`]:{
                        ...evseStatusTemp[`${keys[i]}`],
                        status:'error'
                    }
                }; 

                if(!rslt) rslt = [false,evseStatusTemp[`${keys[i]}`].msg];
            }else{
                evseStatusTemp = {
                    ...evseStatusTemp,
                    [`${keys[i]}`]:{
                        ...evseStatusTemp[`${keys[i]}`],
                        status:'success'
                    }
                }; 
            } 
        }

        setEvseMapState(evseStatusTemp);

        if(rslt) return rslt;

        return true;

    }

    return(
        <Row id="tablero-general-cpo_crear">
            <Affix style={{
                position:'sticky',
                width:'100%',
                zIndex:1,
                left:0,
                top:0
            }} offsetTop={topFix}>
                <Breadcrumb breadcrumbData={breadcrumbData}></Breadcrumb>
            </Affix>
        
            <FormularioEvse 
            cargadorId={cargadorId}
            dataEvseForm={dataFormSend} 
            setDataEvseForm={setDataForm}
            modifyFormSend={modifyFormSend} 
            setModalVisible={setModalVisible}
            setModalBrandVisible={setModalBrandVisible}
            setModalModelVisible={setModalModelVisible}
            evseMapState={evseMapState}
            conectores={conectores}
            locations={locations.data}
            locationsLoading={locations.loading}
            setConectores={setConectores}
            updateConector={updateConector}
            conectoresMapState={conectoresMapState}
            setConectoresMapState={setConectoresMapState} />

            <Affix
            style={{
                position:'sticky',
                width:'100%',
                zIndex:1,
                bottom:0,
                left:0
            }}
            offsetBottom={bottomFix}>
                <Row
                style={{
                    backgroundColor:'white',
                    width:'100%',
                    paddingTop:10,
                    paddingBottom:10,
                    display:'flex',
                    alignItems:'center',
                }}>
                    <Col span={24}
                    style={{
                        display:'flex',
                        justifyContent:'flex-end'

                    }}>
                        <Button onClick={() => userHistory.replace('/tablero-cpo')} style={{marginRight:5}}>Cancelar</Button>
                        { cargadorId ? '' : (<Button onClick={() => limpiarFormularios()} style={{marginRight:5}}>Limpiar</Button>)}
                        { cargadorId ? '' : (<Button onClick={crearYContinuar} style={{marginRight:5}}>{'Crear y añadir otro'}</Button>)}
                        <Button onClick={crearEvse} style={{marginRight:5}} icon={<PlusOutlined  />} type={'primary'}>{cargadorId ? 'Editar' : 'Crear'}</Button>
                    </Col>
                </Row>
            
            </Affix>
                    
            <FormularioLocation 
            isModalVisible={isModalVisible} 
            setModalVisible={setModalVisible} 
            dataLocationSend={dataLocationSend} 
            setDataLocationForm={setDataLocationForm}
            handleCrear={crearLocation} handleCancelar={cancelLocacion}/>

            <FormularioBrand
            isVisible={isModalBrandVisible}
            setVisible={setModalBrandVisible}
            dataFormSend={dataFormSend}
            setDataForm={setDataForm}
            />

            <FormularioModel
            isVisible={isModalModelVisible}
            setVisible={setModalModelVisible}
            dataFormSend={dataFormSend}
            setDataForm={setDataForm}
            />
       
        </Row>
    );
}


export default CrearCargador;