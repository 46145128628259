import React from 'react';
import { Card, Spin, Alert, Typography, Divider, Space, Button, DatePicker } from 'antd';
import moment from 'moment';

import useRequest from './hooks/useRequest';
import Table from '../../components/BasicTable';

const { Paragraph, Title } = Typography;

const columns = [
  {
    title: 'TIEMPO',
    dataIndex: 'time',
  },
  {
    title: 'DISTANCIA (Km)',
    dataIndex: 'odometer',
  },
  {
    title: 'ENERGÍA (kWh)',
    dataIndex: 'kwh',
  },
  {
    title: 'SOC (%)',
    dataIndex: 'soc',
  },
  {
    title: 'VELOCIDAD (Km/h)',
    dataIndex: 'speed',
  },
  {
    title: 'EFICIENCIA (kWh/km)',
    dataIndex: 'eficiencia',
  },
  {
    title: 'CORRIENTE (A)',
    dataIndex: 'current',
  },
  {
    title: 'ESTADO',
    dataIndex: 'status',
  },
  {
    title: 'TEMPERATURA (°C)',
    dataIndex: 'temperature',
  },
];

function LogsDevice() {
  const { data, error, loading, onClickMore, showMore, setShowMore, date, setDate } = useRequest();

  return (
    <Card
      title="Log de dispositivo en vehículo"
      className="logs-devices"
      extra={loading ? <Spin size="small" /> : null}
    >
      {data && (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
            <DatePicker
              value={date}
              onChange={setDate}
              size="large"
              format="YYYY-MM-DD h:mm:ss a"
              showTime={{ defaultValue: moment('00:00:00 am', 'h:mm:ss a') }}
              disabledDate={(current) => current && current >= moment()}
            />
          </div>

          <Table
            columns={columns}
            data={data}
            scroll={{ y: 450 }}
            id="logs-device-table"
            onBottomScrollingEvent={setShowMore}
          />

          <Divider style={{ marginTop: 0 }} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space align="center">
              <Title level={4} style={{ margin: 0 }}>
                {data.length}
              </Title>
              <Paragraph style={{ margin: 0 }}>resultados</Paragraph>
              {showMore && (
                <Button type="link" onClick={onClickMore} loading={loading}>
                  Cargar más
                </Button>
              )}
            </Space>
          </div>
        </>
      )}

      {error && (
        <Alert
          type="error"
          message="Error"
          description="Lo sentimos hubo un error en la consulta"
        />
      )}
    </Card>
  );
}

export default LogsDevice;
