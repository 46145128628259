import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Select, Typography, Divider, DatePicker } from 'antd';

import BasicTable from '../components/BasicTable';
import CardTemplate from '../components/CardTemplate';

const { Option } = Select;
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const Operation = (props) => {
  const { className } = props;
  const [dateSelect, setDateSelect] = useState('current-day');

  const displayRangePicker = dateSelect === 'range' ? 'inline-flex' : 'none';

  return (
    <div className={className}>
      <Space direction="vertical">
        <Space>
          <Paragraph style={{ margin: 0 }}>Fecha: </Paragraph>
          <Select defaultValue={dateSelect} style={{ width: 200 }} onChange={setDateSelect}>
            <Option value="current-day">Hoy</Option>
            <Option value="current-week">Esta semana</Option>
            <Option value="current-month">Este mes</Option>
            <Option value="current-year">Este año</Option>
            <Option value="range">Rango personalizado</Option>
          </Select>

          <RangePicker style={{ display: displayRangePicker }} />

          <Divider type="vertical" style={{ display: 'none' }} />

          <Paragraph style={{ margin: 0 }}>Conductor: </Paragraph>
          <Select style={{ width: 200 }} loading>
            <Option value="current-day">Hoy</Option>
            <Option value="current-week">Esta semana</Option>
          </Select>
        </Space>

        <Space size="large" className="cards">
          <CardTemplate title="Operación">
            <BasicTable columns={null} data={null} isLoading />
          </CardTemplate>
          <CardTemplate title="Información del vehículo">
            <BasicTable columns={null} data={null} isLoading />
          </CardTemplate>
        </Space>
      </Space>
    </div>
  );
};

Operation.propTypes = {
  className: PropTypes.string,
};

Operation.defaultProps = {
  className: '',
};

export default Operation;
