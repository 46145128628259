import React, { useState  } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import './style.less';
import { useSelector } from 'react-redux';

import logoCompuesto from '@assets/img/logo/LogoCelsiaBlanco.png';
import logoSimbolo from '@assets/img/logo/SimboloCelsia.png';
import useSocket from '@hooks/useSocket';
import HeaderCelsia from '../../containers/Header';
import MainContent from './MainContent';
import SideNavSwitch from '../SideNavSwitch'
import useCheckVehiclesConn from './hooks/useCheckVehiclesConn';

const { Header, Sider, Content } = Layout;

const Main = (props) => {
  useCheckVehiclesConn();
  const { user } = useSelector((state) => state);
  useSocket('socket',user);
  const { history } = props;
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  const classMenu = isMenuCollapsed ? 'menu--collapsed' : 'menu';

  const logo = isMenuCollapsed ? logoSimbolo : logoCompuesto;
  const isConfig = () => {
    return (history.location.pathname.startsWith('/configuracion-cuenta') || history.location.pathname.startsWith('/configuracion-sitio'));
  }

  const evaluateIsCollapse = () => {
    return isConfig() ? false : isMenuCollapsed;
  }
  const padding = isConfig() ? 12 : isMenuCollapsed ? 16 : 12;

  // const match = useRouteMatch();
  
  // let rutasMenu = [
  //   { 
  //     path:`${match.path}`,
  //     name:"dashboard",
  //     key:"dashboard_menu",
  //     exact:false,
  //     component:(<MainMenu className={`menu-wrapper__${classMenu}`} history={history} />)
  //   },
  //   { 
  //     path: "/configuracion-sitio", 
  //     name:"config", 
  //     exact:false,
  //     key:"config_menu",
  //     component:(<ConfigMenu className={`menu-wrapper__${classMenu}`} history={history} />)
  //   },
  // ];
  
  return (
    <Layout id="main">
      <Sider trigger={null} collapsed={evaluateIsCollapse() } className="menu-wrapper" width={isConfig() ? 200 : 270}>
        <div className="menu-wrapper__logo">
          <img src={(isConfig() ? logoCompuesto : logo)} alt="Logo" style={{ padding }} />
        </div>
        <SideNavSwitch {...props} />
        {/* <MainMenu className={`menu-wrapper__${classMenu}`} history={history} /> */}
        {/* <ConfigMenu  className={`menu-wrapper__${classMenu}`} history={history} /> */}
      </Sider>
      <Layout className="content-wrapper">
        <Header className="content-wrapper__header">
          <HeaderCelsia
            toggleMenuCollapsed={() => setMenuCollapsed(!isMenuCollapsed)}
            isMenuCollapsed={ evaluateIsCollapse() }
          />
        </Header>
        <Content className="content-wrapper__main-content">
          <MainContent />
        </Content>
      </Layout>
    </Layout>
  );
};

Main.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Main;
