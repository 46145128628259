import React from 'react';
import { Space, Typography } from 'antd';
import { ResponsiveContainer, BarChart, Bar, YAxis, XAxis, CartesianGrid } from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

const { Paragraph } = Typography;

function ChartRangesStatus(props) {
  const { rangesData, dateRange, barSize } = props;
  const categories = {};

  const data = rangesData.map((item) => {
    let tempData = {};
    item.forEach(({ start, end, status, fill = ' #080808' }, index) => {
      categories[status] = fill;
      const value = moment(end).valueOf() - moment(start).valueOf();
      tempData[`${index}_${status}`] = value;

      // details
      const dataKey = moment(start).locale('es').format('ddd DD MMM');
      tempData = { ...tempData, end, dataKey };
    });
    return tempData;
  });

  const [startG, endG] = dateRange;
  const utcStartG = moment(startG).valueOf();
  const utcEndG = moment(endG).valueOf();

  const tickEnd = utcEndG - utcStartG;
  const numberOfTicks = 24;
  const itemTick = Math.floor(tickEnd / numberOfTicks);
  const ticks = [];

  for (let i = 0; i <= numberOfTicks; i += 1) {
    const newValue = itemTick * i + 1000;
    ticks.push(newValue);
  }

  return (
    <>
      {/* Custom Legeng */}
      <Space size="large" style={{ marginBottom: 20 }}>
        {Object.entries(categories).map((item, index) => {
          const [status, color] = item;
          const key = `cat_${index}`;
          return (
            <Space key={key} align="center">
              <div style={{ width: 16, height: 16, backgroundColor: color }} />
              <Paragraph style={{ margin: 0 }}>{status}</Paragraph>
            </Space>
          );
        })}
      </Space>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={barSize + 60}>
        <BarChart data={data} layout="vertical">
          <CartesianGrid horizontal={false} />
          <YAxis type="category" dataKey="dataKey" hide />
          <XAxis
            domain={['dataMin', tickEnd]}
            type="number"
            tickFormatter={(unixTime) => moment(unixTime + utcStartG).format('h a')}
            ticks={ticks}
          />

          {data.map((object, index) => {
            const stackId = `stack_${index}`;
            const { end, dataKey, ...rest } = object;

            const bars = Object.keys(rest).map((key) => {
              const [, status] = key.split('_');
              const fill = categories[status];
              return (
                <Bar key={key} dataKey={key} stackId={stackId} fill={fill} barSize={barSize} />
              );
            });
            return bars;
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

ChartRangesStatus.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.object).isRequired,
  rangesData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        start: PropTypes.any,
        end: PropTypes.any,
        fill: PropTypes.string,
      })
    )
  ).isRequired,
  barSize: PropTypes.number,
};

ChartRangesStatus.defaultProps = {
  barSize: 30,
};

export default ChartRangesStatus;

// console.log(rangesData);

//   const categories = new Set([]);
//   const data = rangesData.map((item) => {
//     console.log(item);
//   });

//   console.log(categories);

//   return (
//     <ResponsiveContainer width="100%" height={100}>
//       <BarChart data={data} layout="vertical">
//         {/* <Bar dataKey="uv" stackId="a" fill="#8884d8" barSize={30} />
//         <Bar dataKey="pv" stackId="a" fill="#82ca9d" barSize={30} /> */}

//         {/* {data.map((item, index) => {
//           const key = `bar_${index}`;
//           const { status } = item;
//           return <Bar key={key} dataKey={status} stackId="a" fill="#82ca9d" barSize={30} />;
//         })} */}

//         <YAxis type="category" hide />
//         <XAxis type="number" />
//         <Tooltip />
//       </BarChart>
//     </ResponsiveContainer>
//   );
