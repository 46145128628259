import React from 'react';
import { Card, Table } from 'antd';
import PropTypes from 'prop-types';

const columns = [
  {
    title: 'Alertas y notificaciones',
    dataIndex: 'alert',
    sorter: (a, b) => a.alert > b.alert,
  },
  {
    title: 'Vehículos',
    dataIndex: 'vehicles',
    align: 'center',
    sorter: (a, b) => a.vehicles - b.vehicles,
  },
];

const dataSource = [
  {
    key: '1',
    alert: 'Falla frenos',
    vehicles: '3',
  },
  {
    key: '2',
    alert: 'Puertas abiertas',
    vehicles: '5',
  },
  {
    key: '3',
    alert: 'Bajo voltaje en celda',
    vehicles: '8',
  },
  {
    key: '4',
    alert: 'Temperatura en el controlador',
    vehicles: '2',
  },
  {
    key: '5',
    alert: 'Velocidad sobre los 60 km/h',
    vehicles: '5',
  },
  {
    key: '6',
    alert: 'AC Apagado',
    vehicles: '8',
  },
  {
    key: '7',
    alert: 'Puerta abierta en movimiento',
    vehicles: '2',
  },
];

const AlertasFilterCard = (props) => {
  const { loading } = props;

  return (
    <Card>
      <Table
        loading={loading}
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        rowSelection={{ type: 'checkbox' }}
        style={{ overflow: 'hidden', height: '100%' }}
        scroll={{ y: 340 }}
      />
    </Card>
  );
};

AlertasFilterCard.propTypes = {
  loading: PropTypes.bool,
};

AlertasFilterCard.defaultProps = {
  loading: false,
};

export default AlertasFilterCard;
