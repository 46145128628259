import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Space, Spin } from 'antd';

const { Paragraph } = Typography;

function VehicleInfo() {
  const vehicleData = useSelector(({ vehicleView }) => vehicleView.vehicleData);
  const loading = useSelector(({ vehicleView }) => vehicleView.vehicleLoading);

  const getStatusValueByOrigin = useCallback(
    (origin) => {
      switch (origin) {
        case 'CHA':
          return 'Cargando';
        case 'OFF':
          return 'Apagado';
        case 'ON':
          return 'Encendido';
        case 'BAD':
          return 'Sin conexión';
        default:
          return 'Desconocido';
      }
    },
    // eslint-disable-next-line
    [vehicleData]
  );

  const { dev, sta, lic } = vehicleData || {};

  const dataSource = [
    {
      name: 'Identificador',
      value: dev || '-',
    },
    {
      name: 'Placa',
      value: lic || '-',
    },
    {
      name: 'Marca - Linea',
      value: 'Desconocida',
    },
    {
      name: 'Estado del vehículo',
      value: sta ? getStatusValueByOrigin(sta) : '-',
    },
    {
      name: 'Kilometraje',
      value: 'Desconocido',
    },
  ];

  return (
    <Card title="Información del vehículo" extra={loading ? <Spin size="small" /> : null}>
      {dataSource &&
        dataSource.map((item, index) => {
          const { name, value } = item;
          const key = `key${index}`;
          return (
            <div key={key}>
              <Space size="small">
                <Paragraph disabled>{name}:</Paragraph>
                <Paragraph strong>{value}</Paragraph>
              </Space>
            </div>
          );
        })}
    </Card>
  );
}

export default VehicleInfo;
