const getLocalString = ( date ) => {
    let localDate = date
    try{
        return `${localDate.getFullYear()}-${localDate.getMonth()+1}-${localDate.getDate()} ${localDate.getHours()}:${localDate.getMinutes()}:${localDate.getSeconds()}`
    }catch(err){
        localDate = new Date(localDate);
        return `${localDate.getFullYear()}-${localDate.getMonth()+1}-${localDate.getDate()} ${localDate.getHours()}:${localDate.getMinutes()}:${localDate.getSeconds()}`

    }   
}

export default getLocalString;