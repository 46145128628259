/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { tableroGeneral } from '@utils/menus/main/itemsMenu';
import Map from '@components/mapa/GoogleMap';
import Breadcrumb from '@containers/Breadcrumb';
import actions from '@store/reducers/tableroGeneral/mapView/actions';

import SwitchTableMap from '../../components/SwitchTableMap';
import { GeneralChart, VehiclesTable, MapMarkerList } from './containers';
import './style.less';

function MapView() {
  
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);

  const { name } = tableroGeneral;
  const breadcrumbData = [[null, name]];

  useEffect(
    () => {
      dispatch(actions.fetchVehicles());
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div id="tablero-general_mapa">
      <Breadcrumb breadcrumbData={breadcrumbData} extra={<SwitchTableMap value="mapa" />} />
      <Map getMap={setMap}>
        <MapMarkerList map={map} />

        <div className="grid-info">
          <GeneralChart />
          <VehiclesTable />
        </div>
      </Map>
    </div>
  );
}

export default MapView;
