import React from 'react';
import { PageHeader, Button, Dropdown, Menu, Space } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { logout } from '@utils/auth/createAuthProvider';
import NotificationCenter from '../NotificationCenter.js';
import { Link, useHistory } from 'react-router-dom';

const menu = ( action ) => (
  <Menu style={{
    display:"flex",
    flexDirection:'column',
    alignItems:"center"
  }}>
    <Menu.Item>
      <Link 
      to={"/configuracion-sitio/perfil"}
      style={{
        border:'none'
      }}>Configuración de cuenta</Link>
    </Menu.Item>
    <Menu.Item
    style={{
      width:'100%',
      display:'flex',
      justifyContent:'center'
    }}>
      <Button  onClick={() => action()}>Cerrar sesión</Button>
    </Menu.Item>
  </Menu>
);

const Header = ({ className, toggleMenuCollapsed, isMenuCollapsed }) => {
  const username = useSelector((state) => state.user.username);
  const history = useHistory();

  const iconStyle = { fontSize: '21px',color:'white' };
  const IconTarget = () =>
    isMenuCollapsed ? (
      <MenuUnfoldOutlined style={iconStyle} />
  ) : (
      <MenuFoldOutlined style={iconStyle} />
  );

  const toLogin = () => {
    logout();
    history.replace('/ingreso-cpo');
  }

  const toFaq = () => {
    window.open('/faq','_blank');
  }

  return (
    <PageHeader
      className={className}
      title=""
      onBack={toggleMenuCollapsed}
      backIcon={<IconTarget />}
      extra={[
        // <NotificationCenter key={2} />,
        <FontAwesomeIcon key={2} onClick={toFaq} icon={faQuestionCircle} style={{ cursor:'pointer', fontSize: 16,color:"white" }} />,
        <Dropdown key={1} placement="bottomRight" overlay={menu(toLogin)} arrow>
          <Space size="small">
            {/* <Avatar size="small" style={{ marginRight: 0 }} /> */}
            <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 24,color:"white" }} />
            <Button type="text" style={{ padding: 0,color:"white" }}>
              {username}
            </Button>
          </Space>
        </Dropdown>,
      ]}
    />
  );
};

Header.propTypes = {
  className: PropTypes.string,
  isMenuCollapsed: PropTypes.bool.isRequired,
  toggleMenuCollapsed: PropTypes.func.isRequired,
};

Header.defaultProps = {
  className: null,
};

export default Header;
