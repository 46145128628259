/* eslint-disable no-undef */
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { TablaCargadores } from '@views/CargadoresCPO/views/AdministrarCargadores/components';
import { MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
// import { tableroCPO } from '@utils/menus/main/itemsMenu';
import Map from '@components/mapa/GoogleMap';
import Breadcrumb from '@containers/Breadcrumb';
import { apiLocations } from '@utils/services/cpo';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import DataCards from './components/DataCards';
import './style.less';
import { Button, message,Card,Row,Col } from 'antd';
import { OpenLocationCode } from 'open-location-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlug } from '@fortawesome/free-solid-svg-icons';
import { Spanish } from '@utils/helpers/dictIdiomas'

const MapsView = () => {
  const textLanguage = Spanish({screen:'cpo-tablero'})
  const openCode = new OpenLocationCode();
  const location = useLocation(); 
  const history = useHistory();
  const [map, setMap] = useState(null);
  const { user } = useSelector((state) => state);
  const [ infoVisible , setInfoVisible ] = useState({
    lat:0,
    lng:0,
    obj:{
      location:'Unicentro',
      state:'Disponible',
      connectors:[
        {
          type:'Tipo 1',
          total:4,
          available:2
        },
        {
          type:'Tipo 2',
          total:4,
          available:1
        },
        {
          type:'SCHUKO',
          total:4,
          available:4
        }
      ]
    },
    visible:false,
  });
  const [state, setState] = useState({
    locations: [],
  });
  const [locations, setLocations] = useState([]);

  // const { name } = tableroCPO;
  const breadcrumbData = [[null, 'Tablero General']];

  const getMap = useCallback((mapInstance) => {
    setMap(mapInstance);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    setState({ ...state });
    apiLocations
      .allLocations(user.user_id)
      .then((info) => {
        let general = info.map((e) => {
          let connectorsTemp = {};
          const connectorsArrayEnd = [];


          const evsesLocation = e.evses;
          if(evsesLocation && evsesLocation.length > 0){
            for(let e = 0 ;  e < evsesLocation.length ; e++){
              const evseObj = evsesLocation[e];
              if(evseObj.connectors && evseObj.connectors.length > 0){
                const conTemp = evseObj.connectors;
                for(let i = 0 ; i < conTemp.length ; i++){
                  const conObj = conTemp[i];
                  if(!connectorsTemp[`${conObj.standard}`]){
                    connectorsTemp[`${conObj.standard}`] = {
                      type:conObj.standard,
                      total:1,
                      available: conObj.status_connector == 'Disponible' ? 1 :0
                    }
                  }else{
                    connectorsTemp[`${conObj.standard}`] = {
                      ...connectorsTemp[`${conObj.standard}`],
                      total:connectorsTemp[`${conObj.standard}`].total + 1,
                      available: conObj.status_connector == 'Disponible' ? connectorsTemp[`${conObj.standard}`].available + 1 : connectorsTemp[`${conObj.standard}`].available
                    }
                  } 
                }
              }
            }
          }

          let keyTypes = Object.keys(connectorsTemp);

          for(let i = 0 ; i < keyTypes.length ; i++){
            connectorsArrayEnd.push(connectorsTemp[`${keyTypes[i]}`]);
          }

          let data = {
            lat:0,
            lng:0,
            obj:{
              location:e.name,
              state:e.state,
              connectors:connectorsArrayEnd
            }
          }
          
          try{
            const decode_plus = openCode.decode(e.plus_code);
            data = {
              ...data,
              lat: parseFloat(decode_plus.latitudeCenter),
              lng: parseFloat(decode_plus.longitudeCenter),
            };
          }catch(err){
            // console.log(e);
            // console.warn(`${e.name} no tiene codigo plus`);
            if(e.name != undefined) message.warning(textLanguage.EVSES_EVSE_NO_PLUSCODE({name:e.name,pluscode:e.plus_code}));
          }
          
          return data;

        });
        
        setLocations(general);
      })
      .catch((error) => {
        setState({ ...state, isFething: false });
        message.error(textLanguage.EVSES_REQUEST_FAIL);
      });
    // eslint-disable-next-line
  }, []);

  const handleCrearCargador = () => {
    history.push('crear_cargador');
  }



  const handleMarkerclick = ( obj ) => {
    console.log(obj);
    setInfoVisible(obj)
  }

  return (
    <div id="tablero-general-cpo_mapa">
      <Breadcrumb breadcrumbData={breadcrumbData} extra={<Button type="primary" onClick={handleCrearCargador} icon={<PlusOutlined  />}>Nuevo Cargador</Button>} />

      <section className="second-filter">
        <DataCards />
        <div className={'contenedor-mapa'}>
          {/* <div className={'contenedor-legend'}>
            <Card className={'mapa_legend'}>
              <p><FontAwesomeIcon color={'#f60141'} icon={faCircle}/> Fallo de conexión</p>
              <p><FontAwesomeIcon color={'#00c08c'} icon={faCircle}/> Disponible</p>
              <p><FontAwesomeIcon color={'#49a2d8'} icon={faCircle}/> Cargando</p>
              <p><FontAwesomeIcon color={'#999999'} icon={faCircle}/> Fuera de servicio</p>
            </Card>
          </div> */}
          
          <Map>
            <MarkerClusterer>
              {(clusterer) => {
                return locations.map((general, index) => {
                  const key = `marker_${index}`;
                  return <Marker onClick={ () => handleMarkerclick(general)} key={key} position={general} clusterer={clusterer} >
                      {
                        (infoVisible == general) && 
                        <InfoWindow onCloseClick={()=>setInfoVisible({...infoVisible,visible:false})}>
                          <div style={{padding:10}}>
                            <h3>{infoVisible.obj ? infoVisible.obj.location : 'Cargando'}</h3>
                            <p style={{marginBottom:20}}><FontAwesomeIcon color={'#00c08c'} icon={faCircle}/> <strong>{'Disponibles'}</strong></p>
                            {
                              (infoVisible.obj && infoVisible.obj.connectors.length > 0) ? infoVisible.obj.connectors.map( (v,i) => {
                                return (
                                  <div key={`info-tipo_${i}`} style={{marginBottom:10}} >
                                    <p style={{marginBottom:0,display:'inline-block'}}><FontAwesomeIcon color={'gray'} icon={faPlug}/> {v.type}</p>
                                    <p style={{marginLeft:5,float:'right',marginBottom:0,display:'inline-block'}}><strong style={{color:'#00c08c',marginRight:4}}>{`${v.available}`}</strong>{`de ${v.total}`}</p>
                                  </div>
                                );
                              }) : <p style={{marginBottom:0,display:'inline-block'}}><FontAwesomeIcon color={'gray'} icon={faPlug}/> {'Sin conectores'}</p>
                            }
                          </div>
                        </InfoWindow>
                      }
                    </Marker>;
                })
              }}
              
            </MarkerClusterer>
            
          </Map>
        </div>
      </section>

      <div className="locations-table">
        <TablaCargadores />
      </div>
    </div>
  );
};

export default MapsView;
