import React, { useState } from 'react';
import { Card, Table } from 'antd';

const columns = [
  {
    title: 'CONDUCTOR',
    dataIndex: 'conductor',
    key: 'conductor',
    sorter: true,
  },
  {
    title: 'VEHÍCULO (ID)',
    dataIndex: 'idVehicle',
    key: 'idVehicle',
    sorter: true,
  },
  {
    title: 'PLACA',
    dataIndex: 'placa',
    key: 'placa',
    sorter: true,
  },
  {
    title: 'FECHA',
    dataIndex: 'fecha',
    key: 'fecha',
    sorter: true,
  },
  {
    title: 'ALERTA',
    dataIndex: 'alerta',
    key: 'alerta',
    sorter: true,
  },
  {
    title: 'DETALLES',
    dataIndex: 'detalles',
    key: 'detalles',
  },
];

const data = [];

for (let i = 0; i < 25; i += 1) {
  const obj = {
    key: `${i}`,
    conductor: 'Andrés Perez',
    idVehicle: 'CH011110',
    placa: 'SWQ338',
    fecha: '20/05/2020, 14:57',
    alerta: 'Temperatura en el controlador',
    detalles: 'ver',
  };
  data.push(obj);
}

const AlertasPreventivasMantenimiento = () => {
  const [pageSize, setPageSize] = useState(10);

  const onChange = (page, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <Card className="alertas-prev-mant" size="small" title="Alertas preventivas y de mantenimiento">
      <Table
        columns={columns}
        dataSource={data}
        tableLayout="auto"
        pagination={{
          pageSize,
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          onChange,
        }}
      />
    </Card>
  );
};

export default AlertasPreventivasMantenimiento;
