import React,{ useEffect, useState } from 'react';
import { Row,Col,Card, Table, Form, Input, Select, Button } from 'antd';
import './style.less';
import { apiProfiles,apiCommands,apiLocations } from '@utils/services/cpo'

const Cargadores = () => {
    const { Option } = Select;
    const [ form ] = Form.useForm();
    const [ profiles , setProfiles ] = useState({
        data:[
            // {
            //     value:'1',
            //     name:'profile 1'
            // }
        ],
        loading:false,
    });

    const [ connectors , setConnectors ] = useState({
        selected:[],
        maximum_consumption:0,
        filters:{
            locations:[],
            profiles:[],
            connectors:[],
        },
        data:[
            // {
            //     key: '1',
            //     location: 'Unicentro',
            //     connector: 'connector_1',
            //     profile: 'profile 1',
            //     description:'prueba'
            // },
            // {
            //     key: '2',
            //     location: 'Unicentro',
            //     connector: 'connector_1',
            //     profile: 'profile 1',
            //     description:'prueba'
            // },
        ],
        loading:false,
    });

    const columns = [
        {
            title: 'Estación',
            dataIndex: 'location',
            key: 'location',
            filters: connectors.filters.locations,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
            sorter: (a, b) => a.location.length - b.location.length,
        },
        {
            title: 'Cargador',
            dataIndex: 'connector',
            key: 'connector',
            filters: connectors.filters.connectors,
            onFilter: (value, record) => record.connector.indexOf(value) === 0,
            sorter: (a, b) => a.connector.length - b.connector.length,
        },
        {
            title: 'Perfil',
            dataIndex: 'profile',
            key: 'profile',
            filters: connectors.filters.profiles,
            onFilter: (value, record) => record.profile.indexOf(value) === 0,
            sorter: (a, b) => a.profile.length - b.profile.length,
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
    ]

    useEffect( () => {
        loadProfiles();
        loadConnectors();
    },[]);

    const loadProfiles = () => {
        setProfiles({...profiles,loading:true});
        apiProfiles.getProfiles().then( (rslt) => {
            console.log(rslt);
            setProfiles({selected:[],data:rslt,loading:false});
        }).catch( (err) => {
            setProfiles({...profiles,loading:false});
            console.log(err);
        });
    }

    const loadConnectors = () => {
        setConnectors({...connectors,loading:true});
        apiLocations.getEvsesProfiles().then( (rslt) => {
            console.log(rslt);
            let locationFilter = [];
            let perfilFilter = [];
            let connectorsFilter = [];

            const dataConnectors = rslt.map((v,i)=>{
                let profiles = '';
                let descriptions = '';
                locationFilter.push(v.location.name);

                if(v.profiles.length > 0){
                    let profTemps = v.profiles.filter( (v,i,self) => {
                        return v.active;
                    });

                    if(profTemps[0]){
                        perfilFilter.push(profTemps[0].profile.name);
                        profiles = profTemps[0].profile.name;
                        descriptions = `${profTemps[0].profile.value} ${profTemps[0].profile.unit}`;
                    } 
                } 

                connectorsFilter.push(v.evse_id);

                return {
                    key:v.evse_id,
                    location:v.location.name,
                    connector:v.evse_id,
                    profile: profiles == '' ? 'Ninguno' : profiles,
                    description:profiles == '' ? 'Ninguno' : `Max ${descriptions}`,
                    maximum_consumption:v.maximum_consumption,
                }
            });

            locationFilter = locationFilter.filter( (item,pos,self) => {
                return self.indexOf(item) == pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });

            perfilFilter = perfilFilter.filter( (item,pos,self) => {
                return self.indexOf(item) == pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });

            connectorsFilter = connectorsFilter.filter( (item,pos,self) => {
                return self.indexOf(item) == pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });

            setConnectors({filters:{connectors:connectorsFilter,profiles:perfilFilter,locations:locationFilter},selected:[],data:dataConnectors,loading:false});
        }).catch( (err) => {
            setConnectors({...connectors,loading:false});
            console.log(err);
        });
    }

    const handleApplyProfile = () => {
        form.validateFields().then( (rslt) => {
            console.log(rslt);
            if(rslt.errorFields) return;
            console.log(connectors.selected);
            let promisesArray = [];
            for(let i = 0 ; i < connectors.selected.length ; i++){
                promisesArray.push(apiCommands.setChargingProfile({profile_id:rslt.profile_id,evse_uid:connectors.selected[i]}));
            }

            Promise.all(promisesArray).then( (rslt) => {
                console.log(rslt);
                loadConnectors();
            }).catch( (err) => {
                console.log(err);
            });
        }).catch( (err) => {
            console.log(err);
        });
    }

    const handleOnSelectChange = ( selectedRowKey ) => {
        console.log(selectedRowKey);
        let tPotencia = 0;
        for(let i = 0 ; i < selectedRowKey.length ; i++){
            const rawItem = connectors.data.filter( (v,i2,self) => v.key == selectedRowKey[i])[0];
            if(rawItem) tPotencia += rawItem.maximum_consumption;
        }
        setConnectors({...connectors,selected:selectedRowKey,maximum_consumption:tPotencia});
    }

    return(
        <Row className={'container-cargadores'}>
            <Col span={24} >
                <Card className={'cargadores-tabla-info'}>
                    <div className={'container-selected_item'}>
                        <p>{
                        `${connectors.selected.length > 0 
                        ? `Tienes seleccionado ${connectors.selected.length} cargador${connectors.selected.length > 1 && 'es' || ''}` 
                        : `No tienes seleccionado un cargador`} `}
                        </p>
                        {
                            connectors.selected.length > 0 ? (
                                <p className={'potencia-info'}>{`Potencia total: ${ connectors.maximum_consumption } W`}</p>
                            ) : ''
                        }
                    </div>
                    <Form form={form} layout={'inline'}>
                        <Form.Item  
                        name={'profile_id'}
                        className={'form-profile_input'}
                        rules={[{required:true,message:'Debes seleccionar un perfil'}]}
                        label={'Aplicar perfil'}>
                            <Select loading={profiles.loading} placeholder={'Seleccionar un perfil'}>
                                <Option key={'none'} value={'none'} >
                                    {'Ninguno'}
                                </Option>
                                {
                                    profiles.data.map((v) => (
                                        <Option key={v.name} value={v.id} >
                                            {v.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Button onClick={handleApplyProfile} type={'primary'} >Aplicar</Button>
                    </Form>
                </Card>
            </Col>

            <Col span={24}>
                <Card>
                    <Table 
                    rowSelection={{
                        selectedRowKey:connectors.selected,
                        onChange:handleOnSelectChange,
                    }}
                    loading={connectors.loading} 
                    dataSource={connectors.data} 
                    columns={columns}/>
                </Card>
            </Col>
        </Row>
    );
}

export default Cargadores;