import { get,post,put } from './management';

const locations = (user_id) => {
  return get(`/locations/get-connectors/${user_id}/?export=false`, true);
};

const getLocations = (user_id) => {
  return get(`/locations/get-locations/`, true);
}

const allLocations = (user_id) => {
  return get(`/locations/${user_id}/`, true);
};

const createLocation = (body) => {
  return post(`/locations/create-location/`,body, true);
}

const createEvse = (body) => {
  return post(`/locations/evse/`,body, true);
}

const editEvse = (body) => {
  return put(`/locations/update_evse/`,body, true);
}

const getEvse = (evseId) => {  
    return get('/locations/evse/'+evseId+'/', true);    
};

const createBrandModel = ( body ) => {
  return post(`/locations/create-brand-model/`,body, true);
}

const createBrand = ( body ) => {
  return post(`/locations/create-brand/`,body, true);
}

const createModel = ( body ) => {
  return post(`/locations/create-model/`,body, true);
}

const getBrandsLocation = () => {  
  return get('/locations/brands/', true);    
};

const getModelsBrandsLocation = (brand) => {  
  return get('/locations/models?brand='+brand, true);    
};

const getEvsesProfiles = () => {
  return get('/locations/get-evses-profiles/', true);    
}

export default { 
  getEvsesProfiles,
  createBrand,
  createModel,
  locations, 
  allLocations, 
  getEvse, 
  createEvse, 
  editEvse, 
  getLocations, 
  createLocation, 
  getBrandsLocation,
  getModelsBrandsLocation,
  createBrandModel };