import InProgressView from '../../../../views/InProgressView';

const path = '/configuracion-cuenta/roles';

const rolesMenu = {
  path,
  name: 'Roles',
  icon: null,
  component:InProgressView,
  key: 'config-user-roles',
  children: [],
};

export default rolesMenu;