import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Space,Row,Col,Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlug,faBolt, faClock, faStopwatch, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import ChargerInfoItem from '../components/ChargerInfoItem';
import ChargerSVG from '@assets/svg/evcharger.svg'
const GroupItems = ({ className, data }) => {
  return (
    <div>
      {data.map((item) => {
        const { name, value } = item;
        return (
          <ChargerInfoItem
            key={name}
            className={`${className}__data-item`}
            name={name}
            value={value}
          />
        );
      })}
    </div>
  );
};

GroupItems.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ChargerInfo = (props) => {
  const evseData = props.evseData;
  const { className, fetchingChargerDetail } = props;

  useEffect( () => {
    if(evseData.length > 0){
      try{
        evseData[4].value = `${evseData[4].value} - ${evseData[5].value}`;
        evseData.splice(5,1)
      }catch(e){}
    }
  },[evseData]);
  
  return (
    <Card className={className} loading={fetchingChargerDetail}>
      <Row>
        <Col span={18}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={14}>
                  <h2 style={{fontSize:'32px',fontWeight:'bold',wordBreak:'break-all'}}>{evseData[0] ? evseData[0].value : 'Desconocido'}</h2>
                </Col>
                <Col span={10} style={{alignItems: 'center',display: 'flex'}}>
                  <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'#00c08c'}  icon={faCircle} />Disponible
                </Col>
                <Col span={24}>
                  <GroupItems className={className} data={evseData.filter((v,i) => {
                      return v.name != 'id' && v.name != 'Uso' && v.name != 'Operador' ;
                    })} />
                </Col>
              </Row>
              
              
            </Col>
          </Row>
        </Col>
        <Col span={6} style={{display: 'flex',alignItems: 'flex-end'}} >
          <Image width={'100%'} style={{paddingTop:'20px'}} src={ChargerSVG} />
        </Col>
      </Row>
    </Card>
  );
};

ChargerInfo.propTypes = {
  className: PropTypes.string,
  fetchingVehicleDetail: PropTypes.bool,
};

ChargerInfo.defaultProps = {
  className: '',
  fetchingVehicleDetail: false,
};

export default (ChargerInfo);
