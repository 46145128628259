import React,{useEffect,useState} from 'react';
import { Card, Col,Empty, Spin } from 'antd';
import { PieChart, Pie, Sector, ResponsiveContainer,Legend, Cell,Tooltip } from 'recharts';
import mapColor from '@utils/helpers/colorMap'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import './style.less'
import { checkPropTypes } from 'prop-types';

const CardCake = ( props ) => {

  const { dataRequest } = props;
  const [ data , setData ] = useState({
    data:[],
    dataInner:[],
    loading:false,
  });
  const [ maxValue , setMaxValue ] = useState(0);
  useEffect(() => {
    const connectors = [];
    const data = dataRequest.data;
    let  maxTemp = 0;
    for(let i = 0 ; i < data.length ; i++){
      let location = data[i];
      let con = location.connectors;

      let conectorTemp = {
        key:i,
        name:`${location.evse_uid}`,
        value:location.total_time_used,
        location:`${location.location_name}`,
        connectors:con,
      }
      if(maxTemp < location.total_time_used) maxTemp = location.total_time_used;
      connectors.push(conectorTemp);
    }

    setMaxValue(maxTemp)

    console.log(connectors);

    setData({
      ...data,
      dataInner:connectors
    });
  },[dataRequest.data]);

  const formatter_duration = ( value ) => {
    if(isNaN(value)) value = 0;
    let minutesInit = parseInt(value);
    let sec = parseInt(minutesInit * 60);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor(sec / 60) % 60;
    let secLess = sec % 60 ;

    return `
    ${`${hours}`.length == 1 ? `0${hours}` : hours}:
    ${`${minutes}`.length == 1 ? `0${minutes}` : minutes}:
    ${`${secLess}`.length == 1 ? `0${secLess}` : secLess}`;
  }

  const centerShape = ( props ) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text className={"count-evses"} x={cx} y={cy-10} dy={8} fontSize={32} fontWeight={'bold'} textAnchor="middle" fill={fill}>
          {data.dataInner.length}
        </text>
        <text className={"count-evses"} x={cx} y={cy+10} dy={8} textAnchor="middle" fill={fill}>
          {'Cargadores'}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
      
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Time used: ${formatter_duration(value)}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const CustomTooltip = ( { active, payload, label } ) => {
    if (active && payload && payload.length) {
      console.log(payload);
      const pTemp = payload[0].payload;
      const conns = pTemp.connectors;
      return (
        <div className="uso-tooltip">
          <p className="location">{`${pTemp.location}`}</p>
          <p className="evse">{`${pTemp.name} ${formatter_duration(pTemp.value)}`}</p>
          {
            conns.map((v,i) => {
              return(
                <div key={i}>
                  <p className={'type'}>{`${v.conn_id} - ${v.standard} : ${formatter_duration(v.time_usage)}`}</p>
                </div>
              )
            })
          }
        </div>
      );
    }
    return null;
  }

  const style = {
    top: '50%',
    left: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };

  return(
    <Card className={'card-cake'}>
      <h2 className={'energy-title'}>Tiempo de uso de cargadores</h2>
      <p>No apareceran los cargadores que no han sido utilizados</p>
      {
        !dataRequest.loading && (
          <ResponsiveContainer width="100%" height={500}>
            <PieChart className={'graphic-cake'} width={500} height={500}>
              <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} />

              <Pie
                  activeIndex={0}
                  activeShape={centerShape}
                  data={data.dataInner}
                  cx="65%"
                  cy="50%"
                  innerRadius={80}
                  outerRadius={145}
                  fill="#8884d8"
                  dataKey="value"
                  // onMouseEnter={( _ , index ) => setActiveIndex(index)}
              >
                {data.dataInner.map( (entry,index) => {
                  return (<Cell key={`cell-${index}`} fill={mapColor()[index % mapColor().length]} />)
                })}
              </Pie>
              <Tooltip content={<CustomTooltip/>} />
            </PieChart>
          </ResponsiveContainer>
        )
      }
      
      { dataRequest.loading && <Spin className={'loading-cake'}/>}
      { (!dataRequest.loading && maxValue == 0) && <Empty className={'empty-cake'}  /> }
    </Card>
  );
}

export default CardCake;