import { getTenantUrl, baseRequest } from './urls';

function getVehicleByTracker(tracker) {
  const endpoint = `${getTenantUrl()}/vehicles/info/${tracker}`;
  return baseRequest({ endpoint, requireAuth: true });
}

function getVehicleByLic(lic) {
  const params = new URLSearchParams({
    license_plate: lic,
  });

  const endpoint = `${getTenantUrl()}/vehicles/filter/?${params}`;
  return baseRequest({ endpoint, requireAuth: true });
}

function getAllVehicles() {
  const endpoint = `${getTenantUrl()}/vehicles/filter/`;
  return baseRequest({ endpoint, requireAuth: true });
}

export default { getVehicleByTracker, getVehicleByLic, getAllVehicles };
