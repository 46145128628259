/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const BasicTable = (props) => {
  const { columns, data, isLoading, footer, scroll, id, onBottomScrollingEvent } = props;

  const scrollEvent = useCallback(
    ({ target }) => {
      const perc = (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100;
      onBottomScrollingEvent(perc >= 100);
    },
    //
    []
  );

  useEffect(
    () => {
      if (!id) return () => {};

      const table = document.querySelector(`#${id} .ant-table-body`);
      table.addEventListener('scroll', scrollEvent);

      return function cleanupLister() {
        table.removeEventListener('scroll', scrollEvent);
      };
    },
    //
    []
  );

  return (
    <Table
      id={id}
      rowSelection={false}
      size="small"
      columns={columns}
      dataSource={data}
      style={{ overflow: 'hidden', height: '100%' }}
      scroll={scroll}
      loading={isLoading}
      pagination={false}
      footer={footer}
    />
  );
};

BasicTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  footer: PropTypes.func,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  id: PropTypes.string,
  onBottomScrollingEvent: PropTypes.func,
};

BasicTable.defaultProps = {
  columns: null,
  data: null,
  isLoading: false,
  footer: null,
  scroll: {
    y: 240,
  },
  id: null,
  onBottomScrollingEvent: null,
};

export default BasicTable;
