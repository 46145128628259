import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChargingStation, faDesktop,  } from '@fortawesome/free-solid-svg-icons';

import { Card, Col, Row } from 'antd';

const SelectorRol = ( props ) => {

    return(
        <Card
        style={{
            width:'100%',
            borderColor:'transparent'
        }}
        bodyStyle={{
            padding:'10px'
        }}>
            <Row>
                <Col xs={4}
                style={{
                    display:"flex",
                    justifyContent:'center',
                    alignItems:'center',
                    fontSize:'21px'
                }}>
                    { ( props.icon == 2 ? <FontAwesomeIcon  icon={faEye} style={{ fontSize: 20,color:"#3F3F3F" }} /> :
                    props.icon == 1 ? <FontAwesomeIcon  icon={faChargingStation} style={{ fontSize: 20,color:"#3F3F3F" }} /> :
                    props.icon == 3 ? <FontAwesomeIcon  icon={faDesktop} style={{ fontSize: 20,color:"#3F3F3F" }} /> : ``)}
                </Col>
                <Col xs={20}
                style={{ textAlign:"left" }}>
                    <h3 style={{
                        marginBottom:'2px'
                    }}>{props.titulo}</h3>
                    <h5 style={{
                        whiteSpace: "break-spaces"
                    }}>{props.descripcion}</h5>
                </Col>
            </Row>
            
        </Card>
    )
}

export default SelectorRol;