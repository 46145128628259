import { Row,Col } from 'antd';
import React from 'react';
import Breadcrumb from '@containers/Breadcrumb';
import './style.less'
import { Tabs } from 'antd';

import { Cargadores , Perfiles } from './views'

const { TabPane } = Tabs;

const PerfilesCarga = () => {
    
    const breadcrumbData = [[null,'Perfiles de carga']]

    return(
        <Row className={'container_perfiles'}>
            <Breadcrumb breadcrumbData={breadcrumbData}/>

            <Col span={24} className={'perfiles_tabs_container'}>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Cargadores" key="cargadores">
                        <Cargadores/>
                    </TabPane>
                    <TabPane tab="Perfiles" key="perfiles">
                        <Perfiles/>
                    </TabPane>
                </Tabs>
            </Col>
            
        </Row>
    );
}


export default PerfilesCarga;