import React, { useEffect, useState } from 'react';
import {Typography, Card, Table, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';
import { comprobar_permiso } from '@utils/auth/permissions';
import { IconDeletePopup } from '@components/ui';
import { useSelector } from 'react-redux';

const { Paragraph } = Typography;
const { TabPane } = Tabs;



const TablaUsuarios = (props) => {

    const [pageSize, setPageSize] = useState(10);
    const user = useSelector((state) => state.user);
    const { data, loading, refreshData, re_invitar, editar_user, eliminar_user  } = props;

    const onChange = (page, pageSize) => {
        setPageSize(pageSize);
      };
    

    const columns = [
        {
            title:'NOMBRE',
            dataIndex:'nombre',
            key:'nombre',
            defaultSortOrder: 'ascend',
            sorter:(a,b) => a.nombre.localeCompare(b.nombre),
        },
        {
            title:'CORREO ELECTRONICO',
            dataIndex:'correo',
            key:'correo',
            sorter:(a,b) => a.correo.localeCompare(b.correo),
        },
        {
            title:'ROL',
            dataIndex:'rol',
            key:'rol',
            sorter:(a,b) => {
                if(a.rol != undefined && b.rol != undefined){
                    return a.rol[0].localeCompare(b.rol[0])
                }else{
                    return 0
                }
                
            },
        },
        {
            title:'ULTIMA ACTIVIDAD',
            dataIndex:'last_login',
            key:'last_login',
            sorter:(a,b) => {
                let dateA = new Date(a.last_login);
                let dateB = new Date(b.last_login);
                return dateA.getTime() - dateB.getTime();
            },
        },
        {
            title:'ESTADO',
            dataIndex:'estado',
            key:'estado',
            sorter: (a,b) => a.estado - b.estado,
            render: ( text , record, index ) => {
                return(
                    <IconDeletePopup icon={text == 1 ? faCheck : faTimes} style={{  
                        fontSize: 20,
                        color:text == 1 ? "#FF812E" : "gray", 
                        cursor:`${comprobar_permiso(user,12) ? 'pointer' : 'arrow'}`,
                        pointerEvents:`${comprobar_permiso(user,12) ? 'all' : 'none'}`,
                    }} eliminarCliente={() => eliminar_user(record)} title={text == 1 ? `¿Estas seguro que deseas desactivarlo?` : `¿Estas seguro que deseas activarlo?`} record={record}  />
                );
            }
        },
        {
            title:'ACCIONES',
            dataIndex:'acciones',
            key:'acciones',
            render: (text, record, index) => {
                return (
                <div style={{
                    display:"flex",
                    justifyContent:"space-around",
                }}>
                    
                    <p style={{ fontSize: 16,color:"#FF812E",margin:0, cursor:"pointer"} } onClick={() => re_invitar(record)} >Reenviar invitación</p>
                    <FontAwesomeIcon icon={faPen} onClick={() => editar_user(record)} style={{ fontSize: 20,color:"#FF812E",cursor:"pointer", visibility:`${comprobar_permiso(user,20) ? `visible` : `hidden`}`, }} />
                    {/* <IconDeletePopup title={record.estado != "activo" ? `¿Deseas reactivar este usuario?` : `¿Estas seguro que deseas eliminarlo?` } record={record} eliminarCliente={() => eliminar_user(record)}/> */}
                </div>
                )
            }
        },
    ]

    useEffect( () => {
        refreshData();
    },[]);

    return(
        <Card>
            <h4>Organizaciones</h4>
            <div className="filtros-tabla">
                <Table
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                loading={loading}
                pagination={{
                pageSize,
                pageSizeOptions: [10, 20, 50],
                showSizeChanger: true,
                onChange:onChange,
                }} />
            </div>
        </Card>
    );
}

export default TablaUsuarios;