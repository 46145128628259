/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';

import user from './userReducer';
import vehicles from './vehiclesReducer';
import rendimientoFlotas from './rendimientoFlotasReducer';
import alertas from './alertasReducer';
import reportes from './reportes';
import tableroGeneral from './tableroGeneral';
import locations from './locationReducer';

const appReducer = combineReducers({
  vehicles,
  user,
  rendimientoFlotas,
  alertas,
  locations,
  ...reportes,
  ...tableroGeneral,
  socket:null,
});

const rootReducer = (state, action) => {
  const { type } = action;
  if (type === 'RESET_STORE') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
