import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Spin } from 'antd';

import Table from '../components/BasicTable';

const columns = [
  {
    title: 'VARIABLE',
    dataIndex: 'var',
    width: '60%',
  },
  {
    title: 'DETALLE',
    dataIndex: 'value',
  },
];

function VehicleVars() {
  const vehicleData = useSelector(({ vehicleView }) => vehicleView.vehicleData);
  const { ct, sta, vol, cur, odo } = vehicleData || {};

  const data = [
    {
      key: '1',
      var: 'Voltaje',
      value: vol || '-',
    },
    {
      key: '2',
      var: 'Corriente',
      value: cur || '-',
    },
    {
      key: '3',
      var: 'Odómetro',
      value: odo || '-',
    },
    {
      key: '4',
      var: 'Estado',
      value: sta || '-',
    },
    {
      key: '5',
      var: 'Temperatura motor (°C)',
      value: Array.isArray(ct) ? `${Math.max.apply(null, ct)} °C` : '-',
    },
  ];

  return (
    <Card title="Variables actuales del sistema" extra={vehicleData ? null : <Spin size="small" />}>
      <Table columns={columns} data={data} />
    </Card>
  );
}

export default VehicleVars;
