import { del, get, post, put } from './management';


const getProfiles = () => {
    return get('/profiles/', true);
};

const postProfile = ( body ) => {
    return post('/profiles/', body , true);
};

const updateProfile = ( body ) => {
    let bodyTemp = {...body}
    delete bodyTemp.id;

    return put(`/profiles/${body.id}` , bodyTemp , true);
};

const deleteProfile = ( body ) => {
    return del(`/profiles/${body.id}` , true);
}

export default { getProfiles, postProfile, updateProfile, deleteProfile };