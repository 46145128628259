import React from 'react';
import { Radio, Typography } from 'antd';
import PropTypes from 'prop-types';

import { tableroCPO } from '../../../utils/menus/main/itemsMenu';
import history from '../../../utils/history';

const { Paragraph } = Typography;

const onChange = (event) => {
  const { value } = event.target;
  history.push(`${tableroCPO.path}/${value}`);
};

const SwitchTableMap = (props) => {
  const { value } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Paragraph style={{ margin: 0, marginRight: 12 }} strong>
        Ver datos :
      </Paragraph>
      <Radio.Group onChange={onChange} defaultValue={value}>
        <Radio.Button value="tablas" disabled>
          Tablas
        </Radio.Button>
        <Radio.Button value="mapa">Mapa</Radio.Button>
      </Radio.Group>
    </div>
  );
};

SwitchTableMap.propTypes = {
  value: PropTypes.oneOf(['tablas', 'mapa']).isRequired,
};

export default SwitchTableMap;
