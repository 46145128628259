/*
  Referencia linea de tiempo: https://github.com/recharts/recharts/issues/956
*/

import React, { useEffect, useCallback, useState } from 'react';
import { Card, Empty, Spin, Alert } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

// import actions from '@store/reducers/tableroGeneral/vehicleView/actions';
import { apiReportes } from '@utils/services';
import { getRangeToday } from '@utils/helpers/requestHelpers';
import { categoricalColors } from '@utils/params';

function VelocidadCorriente() {
  const placa = useSelector(({ vehicleView }) => vehicleView.placa);
  const [reqState, setReqState] = useState({ loading: false, error: null, chartData: null });

  const normalizeChartData = useCallback(
    ({ result, dateRange }) => {
      const [start] = dateRange;

      const chartData = [];
      for (let i = 0; i < 23; i += 1) {
        const tempDate = moment(start).add(i, 'hours');

        let velocidad = null;
        let corriente = null;

        for (let j = 0; j < result.length; j += 1) {
          const item = result[j];
          const { date, speed, current } = item;
          const targetDate = moment(date);
          const hasSame = targetDate.isSame(tempDate);
          if (hasSame) {
            velocidad = speed;
            corriente = current;
            break;
          }
        }

        const name = tempDate.format('h a');
        chartData.push({ name, velocidad, corriente });
      }

      setReqState({ ...reqState, chartData });
    },
    // eslint-disable-next-line
    []
  );

  const requestData = useCallback(
    () => {
      if (!placa) return;
      const dateRange = getRangeToday();
      const granularity = 'hours';
      const lics = [placa];

      setReqState({ ...reqState, loading: true });
      apiReportes
        .velocidadCorrienteReport({ dateRange, granularity, lics })
        .then((result) => normalizeChartData({ result, dateRange }))
        .catch((err) => {
          setReqState({ ...reqState, error: err });
        });
    },
    // eslint-disable-next-line
    [placa]
  );

  useEffect(
    () => {
      requestData(placa);
    },
    // eslint-disable-next-line
    [placa]
  );

  const { loading, error, chartData } = reqState;

  return (
    <Card title="Velocidad (Km/h) y Corriente (A)" extra={loading ? <Spin size="small" /> : null}>
      {loading && <Empty description="Esperando información del vehículo" />}
      {error && (
        <Alert
          type="error"
          message={error.message}
          showIcon
          description="Error al consultar los datos para la gráfica"
        />
      )}
      {chartData && (
        <ResponsiveContainer width="100%" height={220}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />

            <YAxis yAxisId="left" label={{ value: 'Km/h', position: 'insideLeft', angle: -90 }} />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{ value: 'A', position: 'insideRight' }}
            />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="velocidad"
              stroke={categoricalColors[0]}
              strokeWidth={2}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="corriente"
              stroke={categoricalColors[1]}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
}

export default VelocidadCorriente;
