import moment from 'moment';

function getAutoGranularity(dateRange) {
  const [start, end] = dateRange;

  let granularity;
  const diff = end.diff(start, 'days');

  if (diff >= 7 * 3) granularity = 'weeks';
  else if (diff <= 1) granularity = 'hours';
  else granularity = 'days';

  return granularity;
}

function getRangeToday() {
  const start = moment().startOf('day');
  const end = moment();
  return [start, end];
}

export { getAutoGranularity, getRangeToday };
