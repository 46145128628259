import React from 'react';
import { Card, Typography, Statistic, Space, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const CardTemplateData = (props) => {
  const { title, mainValue, suffix, footerTitle, footerValue, FooterElement, GraphData } = props;

  return (
    <Card title={title} extra={<InfoCircleOutlined />}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Statistic value={mainValue} suffix={suffix} />

        {GraphData && <GraphData />}
      </Space>
      <Divider style={{ margin: '16px 0 8px 0' }} />

      {FooterElement ? (
        <FooterElement />
      ) : (
        <Space>
          <Paragraph style={{ margin: 0 }}>{footerTitle}</Paragraph>
          <Statistic valueStyle={{ fontSize: 14 }} value={`${footerValue} ${suffix}`} />
        </Space>
      )}
    </Card>
  );
};

CardTemplateData.propTypes = {
  title: PropTypes.string,
  mainValue: PropTypes.string,
  suffix: PropTypes.string,
  footerTitle: PropTypes.string,
  footerValue: PropTypes.string,
  FooterElement: PropTypes.func,
  GraphData: PropTypes.func,
};

CardTemplateData.defaultProps = {
  title: '',
  mainValue: '0',
  suffix: '',
  footerTitle: '',
  footerValue: '0',
  FooterElement: null,
  GraphData: null,
};

export default CardTemplateData;
