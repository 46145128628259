/* eslint-disable camelcase */
import moment from 'moment';
import { dateFormat } from '@utils/params';
import { getTenantUrl, baseRequest } from './urls';

function getCommonParams({ dateRange, lics, granularity }) {
  const rangeDateFormat = dateRange.map((date) => moment.utc(date).format(dateFormat));
  const [start_date, end_date] = rangeDateFormat;
  const vehicles = lics.map((lic) => `'${lic}'`).join(',');

  const params = new URLSearchParams({
    start_date,
    end_date,
    granularity,
    vehicles,
  });

  if (!granularity) params.delete('granularity');

  return params;
}

function distanceByRangeAndLics({ dateRange, lics, granularity, signal }) {
  const params = getCommonParams({ dateRange, lics, granularity });
  const endpoint = `${getTenantUrl()}/vehicles/distance-traveled/${params}`;
  return baseRequest({ endpoint, signal, requireAuth: true });
}

function eficienciaByRangeAndLics() {}

function velocidadCorrienteReport({ dateRange, lics, granularity, signal }) {
  const params = getCommonParams({ dateRange, lics, granularity });
  const endpoint = `${getTenantUrl()}/vehicles/speed-current/${params}`;
  return baseRequest({ endpoint, signal, requireAuth: true });
}

function getJourneys({ dateRange, lics, signal }) {
  const params = getCommonParams({ dateRange, lics });
  const endpoint = `${getTenantUrl()}/vehicles/get-journeys/${params}`;
  return baseRequest({ endpoint, requireAuth: true, signal });
}

function getCharges({ dateRange, lics, signal }) {
  const params = getCommonParams({ dateRange, lics });
  const endpoint = `${getTenantUrl()}/vehicles/get-charges/${params}`;
  return baseRequest({ endpoint, requireAuth: true, signal });
}

function getLogsDevice({ date, vehicles, signal }) {
  const params = new URLSearchParams({
    date: moment.utc(date).format(dateFormat),
    vehicles: vehicles.join(','),
  });
  const endpoint = `${getTenantUrl()}/vehicles/logs/${params}`;
  return baseRequest({ endpoint, requireAuth: true, signal });
}

export default {
  distanceByRangeAndLics,
  eficienciaByRangeAndLics,
  velocidadCorrienteReport,
  getJourneys,
  getCharges,
  getLogsDevice,
};
