import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import InProgressView from '@views/InProgressView';
import HistorialRecargas from '@views/HistorialRecargas';

const path = '/historial-recargas';

const historialRecargas = {
  path,
  name: 'Historial de recargas',
  icon: <FontAwesomeIcon icon={faHistory} className="anticon" style={{ marginRight: 10, fontSize: 21, minWidth: "auto" }}  />,
  component: HistorialRecargas,
  key: 'historialRecargas',
  children: [],
};

export default historialRecargas;
