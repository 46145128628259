import locationConst from '../constants/locationsConst';

const {
    LOCATION_ERROR_ITEMS,
    LOCATION_FETCHING_ITEMS,
    LOCATION_SUCCESS_ITEMS,
    LOCATION_UPDATE_ITEMS
} = locationConst;


const initState = {
    locations:[],
    locationsRaw:[],
    error:null,
    fetching:false,
    isReadyToSocket:false,
}

const locationReducer = ( state = initState , action) => {
    const { type, payload } = action;

    switch(type){
        case LOCATION_ERROR_ITEMS:
            return{
                ...state,
                error:payload,
                fetching:false,
            }
        case LOCATION_FETCHING_ITEMS:
            return{
                ...state,
                fetching:true,
            }
        case LOCATION_SUCCESS_ITEMS:
            return{
                ...state,
                ...payload,
                fetching:false,
            }
        case LOCATION_UPDATE_ITEMS:
            return{
                ...state,
                locations:payload,
                fetching:false
            }
        default:
            return state;
    };
}

export default locationReducer;