import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import PropTypes from 'prop-types';

import { apiVehicles } from '@utils/services';

const VehiclesGroupSelect = (props) => {
  const { onChange, defaultValue: propValue } = props;
  const [state, setState] = useState({ groups: [], isFething: false, defaultValue: null });

  useEffect(() => {
    setState({ ...state, isFething: true });

    apiVehicles
      .vehicleGroups()
      .then((groups) => {
        if (groups && groups.length <= 0) {
          setState({ ...state, isFething: false });
          return;
        }

        const defValue = propValue || groups[0].id;
        setState({ ...state, groups, isFething: false, defaultValue: defValue || null });
        onChange(defValue);
      })
      .catch((error) => {
        setState({ ...state, isFething: false });
        message.error(error);
      });

    // eslint-disable-next-line
  }, []);

  const { groups, isFething, defaultValue } = state;

  return (
    <Select
      style={{ width: 180 }}
      loading={isFething}
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={onChange}
    >
      {groups.map(({ id, desc }) => {
        return (
          <Select.Option key={id} value={id}>
            {desc}
          </Select.Option>
        );
      })}
    </Select>
  );
};

VehiclesGroupSelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
};

VehiclesGroupSelect.defaultProps = {
  onChange: null,
  defaultValue: null,
};

export default VehiclesGroupSelect;
