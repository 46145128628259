/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine, faEye } from '@fortawesome/free-solid-svg-icons';

import { RendimientoFlotas, RendimientoVehiculo } from '@views/Reportes/views';
import { mainMenuIconBaseProps as iconProps } from '@utils/params';
import { SpecialIcons } from '@components/ui';
import InProgressView from '@views/InProgressView';
import PerfilesCargaView from '@views/PerfilesCarga';

const { FleetIcon } = SpecialIcons;

const path = '/perfilescarga';

const PerfilesRecarga = {
  path,
  name: 'Perfiles de carga',
  icon: <FontAwesomeIcon {...iconProps} icon={faEye} />,
  key: 'perfilescarga',
  component:PerfilesCargaView,
  children:[]
  
};

export default PerfilesRecarga;
