import { get } from './management';

const operators = (exactEndpoint = null) => {
  if (!exactEndpoint) {
    return get('/locations/get-operators/', true);
  }
  return get(exactEndpoint, true, true);
};

const locationsName = (exactEndpoint = null) => {
  if (!exactEndpoint) {
    return get('/locations/get-location-name/', true);
  }
  return get(exactEndpoint, true, true);
};

const statusEvse = (exactEndpoint = null) => {
  if (!exactEndpoint) {
    return get('/locations/get-evses-status/', true);
  }
  return get(exactEndpoint, true, true);
};

export default { operators, locationsName, statusEvse };