import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import actionsMapView from '@store/reducers/tableroGeneral/mapView/actions';

function useCheckVehiclesConn() {
  const dispatch = useDispatch();

  const checkConn = useCallback(
    () => {
      const { checkVehiclesConnection } = actionsMapView;
      dispatch(checkVehiclesConnection());
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const handle = setInterval(checkConn, 30000);
      return () => {
        clearInterval(handle);
      };
    },
    // eslint-disable-next-line
    []
  );
}

export default useCheckVehiclesConn;
