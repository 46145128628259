import { 
    CONFIG_PROFILE_USERS_SWITCH_ACTIVE,
    CONFIG_PROFILE_USERS_SWITCH_INACTIVE,

    CONFIG_PROFILE_CLIENTS_C_SUCCESS,
    CONFIG_PROFILE_CLIENTS_C_FAIL,
    CONFIG_PROFILE_CLIENTS_C_DETAIL,

    CONFIG_PROFILE_CLIENTS_R_FAIL,

    CONFIG_PROFILE_CLIENTS_U_SUCCESS,
    CONFIG_PROFILE_CLIENTS_U_FAIL,

    CONFIG_PROFILE_CLIENTS_D_FAIL,
    CONFIG_PROFILE_CLIENTS_D_SUCCESS,

    ALERTS_CPO_R_FAIL,
    ALERTS_CPO_TYPE_CF,

    RFID_CARDS_ACTIVE_INACTIVE,
    RFID_CARDS_C_SUCCESS,
    RFID_CARDS_C_FAIL,
    RFID_CARDS_U_SUCCESS,
    RFID_CARDS_U_FAIL,

    RFID_CARDS_WHITE_REQUEST_SUCCESS,
    RFID_CARDS_WHITE_REQUEST_FAIL,

    METRICS_REQUEST_FAIL,

    EVSES_REQUEST_FAIL,
    EVSES_EVSE_NO_PLUSCODE,
    EVSES_INFO_FAIL,

    EVSE_EVSE_FORM_C_SUCCESS,
    EVSE_EVSE_FORM_C_FAIL,
    EVSE_EVSE_FORM_U_SUCCESS,
    EVSE_EVSE_FORM_U_FAIL,

    EVSE_LOCATION_FORM_C_FAIL,
    EVSE_LOCATION_FORM_R_FAIL,

    EVSE_EVSE_R_FAIL,

    REQUEST_DOWNLOAD_CSV,
    REQUEST_DOWNLOAD_CSV_FAIL,
    REQUEST_CON_ERROR,
} from './constantes';


const Spanish = ( { screen } ) => {
    let dict = {}
    switch(screen){
        case `config-users`:
        dict = {
            [`${CONFIG_PROFILE_USERS_SWITCH_ACTIVE}`]:({username}) => (`Usuario ${username} activo`),
            [`${CONFIG_PROFILE_USERS_SWITCH_INACTIVE}`]: ({username}) => (`Usuario ${username} inactivo`)
        }
            break;

        case `config-clients`:
            dict = {
                [`${CONFIG_PROFILE_CLIENTS_C_SUCCESS}`]:({name}) => (`El cliente ${name} fue creado exitosamente.`),
                [`${CONFIG_PROFILE_CLIENTS_C_FAIL}`]:({name}) => (`No se pudo crear el cliente ${name}`),
                [`${CONFIG_PROFILE_CLIENTS_C_DETAIL}`]:({details}) => (`Debes corregir los siguientes detalles:\n${details}`),

                [`${CONFIG_PROFILE_CLIENTS_R_FAIL}`]:`Sucedio un error al cargar la información`,
                
                [`${CONFIG_PROFILE_CLIENTS_U_SUCCESS}`]:({name}) => (`Cliente ${name} actualizado exitosamente.`),
                [`${CONFIG_PROFILE_CLIENTS_U_FAIL}`]:({name}) => (`Cliente ${name} no se pudo editar\nIntentalo mas tarde.`),
                
                [`${CONFIG_PROFILE_CLIENTS_D_SUCCESS}`]:({name,active}) => (`${name} ${active ? `activado correctamente` : `desactivado correctamente`}`),
                [`${CONFIG_PROFILE_CLIENTS_D_FAIL}`]:({name}) => (`No se pudo desactivar el cliente ${name}`),
            }
                break;
        
        case `cpo-alerts`:
            dict = {
                [`${ALERTS_CPO_R_FAIL}`]:`Hubo un error al obtener el historial de alertas`,

                [`${ALERTS_CPO_TYPE_CF}`]:`Fallo de conexión`,
            }
            break;

        case `cpo-rfid`:
            dict = {
                [`${RFID_CARDS_ACTIVE_INACTIVE}`]:({active})=>`La tarjeta fue ${active ? `activada` : `desactivada`} correctamente`,
                [`${RFID_CARDS_C_SUCCESS}`]:`La tarjeta fue creada correctamente`,
                [`${RFID_CARDS_C_FAIL}`]:`Ocurrio un error al crear la tarjeta`,

                [`${RFID_CARDS_U_SUCCESS}`]:`La tarjeta fue actualizada correctamente`,
                [`${RFID_CARDS_U_FAIL}`]:`Ocurrio un error al actualizar la tarjeta`,

                [`${RFID_CARDS_WHITE_REQUEST_SUCCESS}`]:`Tarjetas fueron enviadas a lista blanca correctamente`,
                [`${RFID_CARDS_WHITE_REQUEST_FAIL}`]:`Ha ocurrido un error al enviar las tarjetas`,
            }
            break;
        case `cpo-metrics`:
            dict = {
                [`${METRICS_REQUEST_FAIL}`]:`Ocurrio un error al obtener la información`,
            }
            break;
        case `cpo-tablero`:
            dict = {
                [`${EVSES_REQUEST_FAIL}`]:`Ocurrio un error al obtener las estaciones`,
                [`${EVSES_EVSE_NO_PLUSCODE}`]:({name,pluscode}) => `${name} No tiene un codigo plus valido. '${pluscode}'`,
                [`${EVSES_INFO_FAIL}`]:`Ocurrio un error al obtener la información de las estaciones`,
            }
            break;
        case `evse-form`:
            dict = {
                [`${EVSE_EVSE_FORM_C_SUCCESS}`]:({name}) => `${name} fue creado exitosamente`,
                [`${EVSE_EVSE_FORM_C_FAIL}`]:`Ocurrio un error al crear la estación`,
                [`${EVSE_EVSE_FORM_U_SUCCESS}`]:({name}) => `${name} fue actualizado correctamente`,
                [`${EVSE_EVSE_FORM_U_FAIL}`]:`Ocurrio un error al actualizar la ubicación`,
                [`${EVSE_LOCATION_FORM_C_FAIL}`]:`Ocurrio un error al crear la ubicación`,
                [`${EVSE_LOCATION_FORM_R_FAIL}`]:`Ocurrio un error al obtener las ubicaciones`,
            }
            break;
        case `evse-view`:
            dict = {
                [`${EVSE_EVSE_R_FAIL}`]:({name}) => `No se pudo obtener la información de ${name}`,
                
            }
            break;
        
        default: {};
    }

    dict = {
        ...dict,
        [`${REQUEST_DOWNLOAD_CSV}`]:`Solicitando la descarga del documento`,
        [`${REQUEST_DOWNLOAD_CSV_FAIL}`]:`Existe un error al intentar descargar el documento\nIntenta mas tarde.`,
        [`${REQUEST_CON_ERROR}`]:`Existe un error en la conexión\nIntenta mas tarde.`,
    }

    return dict;
}


export default Spanish;