import { get, getCSV, post, put } from './management';


const obtener_tarjetas = ( descarga ) => {
    if(descarga == false){
        return get(`/card/list/?export=false`,true);
    }else{
        return getCSV(`/card/list/?export=true`,true);
    }
}

const crear_tarjeta = ( body ) => {
    return post(`/card/create/`,body,true);
}

const editar_tarjeta = ( body ) => {
    const id = body.id;
    delete body.id;
    return put(`/card/update/${id}/`,body,true);
}

const inactivar_tarjeta = ( body ) => {
    const id = body.id;
    delete body.id;
    return put(`/card/change-status/${id}/`,body,true);
}

const enviar_lista_blanca = ( body ) => {
    return post(`/ocpi/cpo/commands/SEND_LOCAL_LIST`,body,true);
}


export default { obtener_tarjetas, crear_tarjeta, editar_tarjeta,inactivar_tarjeta, enviar_lista_blanca };