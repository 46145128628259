import React,{ useEffect, useState } from 'react';
import { Card, Table,Col,Button, Input, Select,message } from 'antd';
import { DateSelectorGroup } from '@components/ui';
import { apiSessions } from '@utils/services/cpo'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
const TablaRecargas = ( props ) => {
    const textLanguage = Spanish({screen:'evse-view'})
    const { recargas,cargadorId } = props;

    const [ data, setData ] = useState({
        data:[],
        loading:false,
    });

    const rangoHoy = () => {
        let hoyTemp1 = new Date();
        let hoyTemp2 = new Date();

        hoyTemp1.setHours(0);
        hoyTemp1.setMinutes(0);
        hoyTemp1.setMilliseconds(0);

        hoyTemp2.setHours(23);
        hoyTemp2.setMinutes(59);
        hoyTemp2.setMilliseconds(59);

        return [hoyTemp1,hoyTemp2]
    }

    const [rangoDate , setRangoDate] = useState(rangoHoy());


    const onChangeDateRange = (start,end) => {
        let dateStart = new Date(start);
        let dateEnd = new Date(end);

        setRangoDate([dateStart,dateEnd]);
    };

    const columns = [
        {
            title: 'Conector ID',
            dataIndex: 'conector_id',
            key: 'conector_id',
            sorter: (a, b) => a.conector_id - b.conector_id,
        },
        {
            title: 'Conector',
            dataIndex: 'conector_name',
            key: 'conector_name',
            sorter: (a, b) => `${a.conector_name}`.localeCompare(b.conector_name),
        },
        {
            title: 'RFID',
            dataIndex: 'rfid',
            key: 'rfid',
            sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
        },
        {
            title: 'Inicio',
            dataIndex: 'start',
            key: 'start',
            sorter: (a, b) => a.start_millis - b.start_millis,
        },
        {
            title: 'Fin',
            dataIndex: 'end',
            key: 'end',
            sorter: (a, b) => a.end_millis - b.end_millis,
        },
        {
            title: 'Duracion',
            dataIndex: 'duracion',
            key: 'duracion',
            sorter: (a, b) => a.duracion.length - b.duracion.length,
        },
        {
            title: 'Energía',
            dataIndex: 'energia',
            key: 'energia',
            sorter: (a, b) => parseFloat(a.energia) - parseFloat(b.energia),
            render: (e) => <p>{e}kWh</p>,
        },
    ]

    useEffect( () => {
        cargarData();
    },[]);

    useEffect( () => {
        cargarData();
    },[rangoDate]);

    const getSessionsCSV = () => {
        message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
        export_to_csv(null,data.data,'Recargas');
    }

    const cargarData = () => {
        setData({...data.data,loading:true});
        apiSessions.getHistoSession({
            start_date:`${getUTCString(rangoDate[0])}`,
            end_date:`${getUTCString(rangoDate[1])}`,
            evse_uid:cargadorId
        }).then( (rslt) => {
            // console.log(rslt);
            setData({
                data:rslt.map( (v,i) => {
                    return {
                        key:i,
                        conector_id:v.connector_id.conn_id,
                        conector_name:v.connector_id.standard,
                        rfid:v.tag_id,
                        start:getDateLocalString(v.start_date_time),
                        start_millis:new Date(v.start_date_time).getTime(),
                        end:getDateLocalString(v.end_date_time),
                        end_millis:new Date(v.end_date_time).getTime(),
                        duracion:v.duration,
                        energia:`${v.kwh}`,
                    }
                }),
                loading:false,
            });
        }).catch( (err) => {
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
            setData({data:[],loading:false});
        });
    }

    return(
        <div>
            <Col span={24} className={'recargas_filtro'}>
                <DateSelectorGroup
                onChangeDateRange={onChangeDateRange}/>

                <Button onClick={getSessionsCSV} style={{float:'right'}} type={'primary'}>Descargar CSV</Button>
                {/* <Select
                    showSearch
                    style={{ width: 200,float:'right' ,marginRight:'5px' }}
                    placeholder="Buscar"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                </Select> */}
            </Col>
            
            <Card>
                <Table
                    columns={columns}
                    dataSource={data.data}
                    loading={data.loading}
                />
            </Card>
        </div>
        
    );
}


export default TablaRecargas;