import React from 'react';
import { Row,Col,Card,Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine } from '@fortawesome/free-solid-svg-icons';
import './style.less';
const CardCategory = ( props ) => {
    const { icon, iconColor, mainValue, description, toolChilds } = props;
    return(
        <Card className={'card-category'}>
            <Row>
                <Col span={24}>
                    <FontAwesomeIcon className={'card-category_icon'} color={iconColor} icon={icon} />
                </Col>
                <Col span={24}>
                    <p className={'card-category_mainvalue'}>{mainValue}</p>
                </Col>
                <Col span={24}>
                    <p className={'card-category_description'}>{description}</p>
                    {toolChilds}
                </Col>
            </Row>
        </Card>
    );

}

CardCategory.defaultProps = {
    icon:faChartLine,
    mainValue:0,
    description:'Description'
}

export default CardCategory;