import React from 'react';
import { Space, Select } from 'antd';
import './style.less';

import { DateSelectorGroup } from '../../../../components/ui';
import SwitchTableMap from '../../components/SwitchTableMap';
import {
  EnergiaRecargada,
  EstadoVehiculos,
  TiempoOperacion,
  AlertasHabitosConduccion,
  NotificacionesPreventivas,
  ResumenGeneral,
  AlertasPrevMant,
} from './components';

const { Option } = Select;

const onChangeDateRange = (/* start, end */) => {
  // console.log(start);
  // console.log(end);
};

const TablesView = () => {
  return (
    <div id="tablero-general_tablas">
      <div className="switch-tablas-mapa">
        <SwitchTableMap value="tablas" />
      </div>

      <div className="general-grid">
        <Space className="range-date" size="large">
          <DateSelectorGroup onChangeDateRange={onChangeDateRange} />
          <Select defaultValue="a" style={{ width: 180 }}>
            <Option value="a">Eléctrico 9m</Option>
            <Option value="b">Otros</Option>
          </Select>
        </Space>

        <EnergiaRecargada />
        <TiempoOperacion />
        <AlertasHabitosConduccion />
        <NotificacionesPreventivas />
        <EstadoVehiculos />
        <ResumenGeneral />
        <AlertasPrevMant />
      </div>
    </div>
  );
};

export default TablesView;
