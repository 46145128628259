import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import mainMenuData from '../../utils/menus/main';
import configUserMenuData from '../../utils/menus/config_user';
import configSitioMenuData from '../../utils/menus/config_sitio';
import InProgressView from '../InProgressView';
import NotFound from '../NotFound';

const MainContent = () => {
  const { path: defaultPath } = mainMenuData[0];
  // const { path: defaultPath } = configUserMenuData[0];

  let menuArray = configUserMenuData.concat(mainMenuData).concat(configSitioMenuData);

  return (
    <Switch>
      {
        menuArray.map((value) => {
          const { exact = false, path, path2, component, children, isSubMenu, key } = value;
  
          if (!isSubMenu) {
            const finalComp = !component
              ? () => <InProgressView title="Sección en desarrollo" />
              : component;
            return path2 ? <Route key={key} path={[path,path2]} exact={exact} component={finalComp} /> : <Route key={key} path={path} exact={exact} component={finalComp} />;
          }
  
          return children.map((child) => {
            const {
              exact: childExact = false,
              component: childComponent,
              path: childPath,
              key: childKey,
            } = child;
  
            const finalComp = !childComponent
              ? () => <InProgressView title="Sección en desarrollo" />
              : childComponent;
  
            return <Route key={childKey} path={childPath} exact={childExact} component={finalComp} />;
          });
        })
      // mainMenuData.map((value) => {
      //   const { exact = false, path, component, children, isSubMenu, key } = value;

      //   if (!isSubMenu) {
      //     const finalComp = !component
      //       ? () => <InProgressView title="Sección en desarrollo" />
      //       : component;
      //     return <Route key={key} path={path} exact={exact} component={finalComp} />;
      //   }

      //   return children.map((child) => {
      //     const {
      //       exact: childExact = false,
      //       component: childComponent,
      //       path: childPath,
      //       key: childKey,
      //     } = child;

      //     const finalComp = !childComponent
      //       ? () => <InProgressView title="Sección en desarrollo" />
      //       : childComponent;

      //     return <Route key={childKey} path={childPath} exact={childExact} component={finalComp} />;
      //   });
      // })
      }

      {/* <Route key={'notfound'} component={NotFound} /> */}

      <Redirect to={defaultPath} />
    </Switch>
  );
};

export default MainContent;
