import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAuth } from '@utils/auth/createAuthProvider';
import { userActions } from '@store/actions';
import Main from '@views/Main';
import Sign from '@views/Sign';
import RecuperarContrasena from '@views/RecuperarContrasena';
import NotFound from './views/NotFound';
import Faq from './views/FAQ';
const MainRoutes = (props) => {
  const { userClients, fetchUser } = props;
  const [logged] = useAuth();

  useEffect(() => {
    if (logged && userClients.length === 0) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userClients, fetchUser]);

  return (
    <Switch>
      <Route path="/recuperar-contrasena/:uid/:token" component={RecuperarContrasena} />
      <Route path="/faq" component={Faq} />
      {!logged && (
        <>
          {/* <Route component={NotFound} /> */}
          <Route path="/ingreso-cpo" component={Sign} />
          <Redirect to="/ingreso-cpo" />
        </>
      )}


      {logged && userClients.length > 0 && (
        <>
          <Route path="/" component={Main} />
        </>
      )}
    </Switch>
  );
};

MainRoutes.propTypes = {
  userClients: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userClients: state.user.user_clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchUser } = userActions;
  return {
    fetchUser: () => dispatch(fetchUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
