import InProgressView from '../../../../views/InProgressView';

const path = '/configuracion-sitio/alertas';

const alertasMenu = {
  path,
  name: 'Alertas',
  icon: null,
  component:InProgressView,
  key: 'config-sitio-alertas',
  children: [],
};

export default alertasMenu;