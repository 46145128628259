import React from 'react';
import { Card,Row,Col, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './style.less'
const CardDefault = ( props ) => {

    const { title,mainValue,bottomMsg,bottomCount,children } = props;

    return(
        <Card className={'card-default'}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col className={'card-default_title'} span={22}>
                            {title}
                        </Col>
                        <Col className={'card-default_info'} span={2}>
                            {/* <FontAwesomeIcon   icon={faInfoCircle} /> */}
                        </Col>
                    </Row>
                </Col>
                <Col className={'card-default_mainvalue'} span={24}>
                    <p>{mainValue}</p>
                </Col>
                <Col span={24}>
                    {children}
                </Col>
                <Col span={24}>
                    <Divider className={'card-default_divider'}/>
                    <Row>
                        <Col span={20}>
                            <p className={'bottomMsg'}>{bottomMsg}</p>
                        </Col>
                        <Col span={4}>
                            <p className={'bottomCount'}>{bottomCount}</p>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </Card>
    );
}

CardDefault.defaultProps = {
    title:'Titulo card',
    bottomMsg:'mensaje',
    bottomCount:'35',
    mainValue:'85'
}

export default CardDefault;