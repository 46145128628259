import React from 'react';
import { Progress } from 'antd';

import CardTemplateLayout from './CardTemplateData';

const TiempoOperacion = () => {
  return (
    <CardTemplateLayout
      title="Tiempo de operación"
      mainValue="30h:33m:21s"
      footerTitle="Tiempo detenido"
      footerValue="15h:20m:12s"
      GraphData={() => <Progress style={{ width: '100%' }} percent={56} />}
    />
  );
};

export default TiempoOperacion;
