import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Space, Select, Typography, DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const DateSelectorGroup = (props) => {
  const { onChangeDateRange, defaultValue, rangeDefault } = props;

  const [dateSelect, setDateSelect] = useState(defaultValue);
  const displayRangePicker = dateSelect === 'range' ? 'inline-flex' : 'none';
  const rangeDateString = useRef([]);
  const typeValue = useRef(defaultValue);

  const startDate = useRef(null);

  const onOpenChange = (open) => {
    if (open) return;
    const [start, end] = rangeDateString.current;
    if (!start && !end) return;

    const defStart = moment(start);
    const defEnd = moment(end);

    onChangeDateRange(defStart, defEnd, typeValue.current);
  };

  const onChange = useCallback(
    (value) => {
      typeValue.current = value;

      switch (value) {
        case 'current-day':
          startDate.current = moment().startOf('day');
          break;

        case 'current-week':
          startDate.current = moment().startOf('week');
          break;

        case 'current-month':
          startDate.current = moment().startOf('month');
          break;

        case 'current-year':
          startDate.current = moment().startOf('year');
          break;

        default:
          onOpenChange(false);
          setDateSelect(value);
          return;
      }

      startDate.current = moment(startDate.current);
      const endDate = moment();
      if (startDate.current && endDate) {
        onChangeDateRange(startDate.current, endDate, typeValue.current);
      }
      setDateSelect(value);
    },
    // eslint-disable-next-line
    [onChangeDateRange]
  );

  const onChangeRangePicker = (value, dateString) => {
    rangeDateString.current = dateString;
  };

  const disabledDate = (current) => {
    return current.diff(moment(), 'min') > 0;
  };

  useEffect(() => {
    if (!dateSelect) onChange('current-day');
  }, [dateSelect, onChange]);

  return (
    <Space>
      <Paragraph style={{ margin: 0 }}>Fecha: </Paragraph>
      <Select value={dateSelect} style={{ width: 200 }} onChange={onChange}>
        <Option value="current-day">Hoy</Option>
        <Option value="current-week">Esta semana</Option>
        <Option value="current-month">Este mes</Option>
        <Option value="current-year">Este año</Option>
        <Option value="range">Rango personalizado</Option>
      </Select>

      <RangePicker
        style={{ display: displayRangePicker }}
        onChange={onChangeRangePicker}
        onOpenChange={onOpenChange}
        disabledDate={disabledDate}
        defaultValue={rangeDefault}
      />
    </Space>
  );
};

DateSelectorGroup.propTypes = {
  onChangeDateRange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOf([
    'current-day',
    'current-week',
    'current-month',
    'current-year',
    'range',
  ]),
  rangeDefault: PropTypes.arrayOf(PropTypes.any),
};

DateSelectorGroup.defaultProps = {
  defaultValue: null,
  rangeDefault: [null, null],
};

export default DateSelectorGroup;
