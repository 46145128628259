/** CONFIG USERS */
const CONFIG_PROFILE_USERS_C_SUCCESS = `CONFIG_PROFILE_USERS_C_SUCCESS`;
const CONFIG_PROFILE_USERS_R_SUCCESS = `CONFIG_PROFILE_USERS_R_SUCCESS`;
const CONFIG_PROFILE_USERS_U_SUCCESS = `CONFIG_PROFILE_USERS_U_SUCCESS`;
const CONFIG_PROFILE_USERS_D_SUCCESS = `CONFIG_PROFILE_USERS_D_SUCCESS`;

const CONFIG_PROFILE_USERS_SWITCH_ACTIVE = `CONFIG_PROFILE_USERS_SWITCH_ACTIVE`;
const CONFIG_PROFILE_USERS_SWITCH_INACTIVE = `CONFIG_PROFILE_USERS_SWITCH_INACTIVE`;

/** CONFIG CLIENTS */
const CONFIG_PROFILE_CLIENTS_C_SUCCESS = `CONFIG_PROFILE_CLIENTS_C_SUCCESS`;
const CONFIG_PROFILE_CLIENTS_R_SUCCESS = `CONFIG_PROFILE_CLIENTS_R_SUCCESS`;
const CONFIG_PROFILE_CLIENTS_U_SUCCESS = `CONFIG_PROFILE_CLIENTS_U_SUCCESS`;
const CONFIG_PROFILE_CLIENTS_D_SUCCESS = `CONFIG_PROFILE_CLIENTS_D_SUCCESS`;

const CONFIG_PROFILE_CLIENTS_C_FAIL = `CONFIG_PROFILE_CLIENTS_C_FAIL`;
const CONFIG_PROFILE_CLIENTS_R_FAIL = `CONFIG_PROFILE_CLIENTS_R_FAIL`;
const CONFIG_PROFILE_CLIENTS_U_FAIL = `CONFIG_PROFILE_CLIENTS_U_FAIL`;
const CONFIG_PROFILE_CLIENTS_D_FAIL = `CONFIG_PROFILE_CLIENTS_D_FAIL`;

const CONFIG_PROFILE_CLIENTS_C_DETAIL = `CONFIG_PROFILE_CLIENTS_C_DETAIL`;

/** ALERTS CPO */
const ALERTS_CPO_R_SUCCESS = `ALERTS_CPO_R_SUCCESS`;
const ALERTS_CPO_R_FAIL = `ALERTS_CPO_R_FAIL`;

const ALERTS_CPO_TYPE_CF = `ALERTS_CPO_TYPE_CF`;

/** TARJETAS RFID */
const RFID_CARDS_ACTIVE_INACTIVE = `RFID_CARDS_ACTIVE_INACTIVE`;
const RFID_CARDS_C_SUCCESS = `RFID_CARDS_C_SUCCESS`;
const RFID_CARDS_C_FAIL = `RFID_CARDS_C_FAIL`;

const RFID_CARDS_U_SUCCESS = `RFID_CARDS_U_SUCCESS`;
const RFID_CARDS_U_FAIL = `RFID_CARDS_U_FAIL`;

const RFID_CARDS_WHITE_REQUEST_SUCCESS = `RFID_CARDS_WHITE_REQUEST_SUCCESS`;
const RFID_CARDS_WHITE_REQUEST_FAIL = `RFID_CARDS_WHITE_REQUEST_FAIL`;

/** METRICAS */
const METRICS_REQUEST_FAIL = `METRICS_REQUEST_FAIL`;

/** TABLERO CPO */
const EVSES_REQUEST_FAIL = `EVSES_REQUEST_FAIL`;
const EVSES_EVSE_NO_PLUSCODE = `EVSES_EVSE_NO_PLUSCODE`;
const EVSES_INFO_FAIL = `EVSES_INFO_FAIL`;

/** CARGADORES VIEW */
const EVSE_EVSE_FORM_C_SUCCESS = `EVSE_EVSE_FORM_C_SUCCESS`;
const EVSE_EVSE_FORM_C_FAIL = `EVSE_EVSE_FORM_C_FAIL`;
const EVSE_EVSE_FORM_U_SUCCESS = `EVSE_EVSE_FORM_U_SUCCESS`;
const EVSE_EVSE_FORM_U_FAIL = `EVSE_EVSE_FORM_U_FAIL`;

const EVSE_LOCATION_FORM_C_FAIL = `EVSE_LOCATION_FORM_C_FAIL`;
const EVSE_LOCATION_FORM_R_FAIL = `EVSE_LOCATION_FORM_R_FAIL`;

const EVSE_EVSE_R_FAIL = `EVSE_EVSE_R_FAIL`;

/** GLOBAL */
const REQUEST_DOWNLOAD_CSV = `REQUEST_DOWNLOAD_CSV`;
const REQUEST_DOWNLOAD_CSV_FAIL = `REQUEST_DOWNLOAD_CSV_FAIL`;
const REQUEST_CON_ERROR = `REQUEST_CON_ERROR`;

export {
    /** CONFIG USERS */
    CONFIG_PROFILE_USERS_C_SUCCESS,
    CONFIG_PROFILE_USERS_R_SUCCESS,
    CONFIG_PROFILE_USERS_U_SUCCESS,
    CONFIG_PROFILE_USERS_D_SUCCESS,

    CONFIG_PROFILE_USERS_SWITCH_ACTIVE,
    CONFIG_PROFILE_USERS_SWITCH_INACTIVE,

    /** CONFIG CLIENTS */
    CONFIG_PROFILE_CLIENTS_C_SUCCESS,
    CONFIG_PROFILE_CLIENTS_C_DETAIL,
    CONFIG_PROFILE_CLIENTS_C_FAIL,

    CONFIG_PROFILE_CLIENTS_R_FAIL,

    CONFIG_PROFILE_CLIENTS_U_SUCCESS,
    CONFIG_PROFILE_CLIENTS_U_FAIL,

    CONFIG_PROFILE_CLIENTS_D_SUCCESS,
    CONFIG_PROFILE_CLIENTS_D_FAIL,

    /** ALERTS CPO */
    ALERTS_CPO_R_SUCCESS,
    ALERTS_CPO_R_FAIL,

    ALERTS_CPO_TYPE_CF,


    /** TARJETAS RFID */
    RFID_CARDS_ACTIVE_INACTIVE,
    RFID_CARDS_C_SUCCESS,
    RFID_CARDS_C_FAIL,

    RFID_CARDS_U_SUCCESS,
    RFID_CARDS_U_FAIL,

    RFID_CARDS_WHITE_REQUEST_SUCCESS,
    RFID_CARDS_WHITE_REQUEST_FAIL,


    /** METRICAS */
    METRICS_REQUEST_FAIL,

    /** TABLERO CPO */
    EVSES_REQUEST_FAIL,
    EVSES_EVSE_NO_PLUSCODE,
    EVSES_INFO_FAIL,

    /** CARGADORES VIEW */
    EVSE_EVSE_FORM_C_SUCCESS,
    EVSE_EVSE_FORM_C_FAIL,
    EVSE_EVSE_FORM_U_SUCCESS,
    EVSE_EVSE_FORM_U_FAIL,

    EVSE_LOCATION_FORM_C_FAIL,
    EVSE_LOCATION_FORM_R_FAIL,

    EVSE_EVSE_R_FAIL,

    /** GLOBALS */
    REQUEST_DOWNLOAD_CSV,
    REQUEST_DOWNLOAD_CSV_FAIL,
    REQUEST_CON_ERROR,
}