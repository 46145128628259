const default_settings = {
    /**
     * Backend endpoint
     */
    // default_endpoint:'https://dev.backend.cargadores.t-test.online', // DEV
    // default_endpoint:'https://cargadores-qa-backend.celsia.com',  // QA
    default_endpoint: window.ENV.URL_BACKEND,

    /**
     * Sockets endpoint
     */
    // socket_default_endpoint:'https://cargadores.dev.realtime.t-test.online', // DEV
    // socket_default_endpoint:'https://cargadores-qa-realtime.celsia.com/', // QA 
    socket_default_endpoint: window.ENV.URL_REALTIME,

    /**
     * FAQ md enpoint
     */
    // default_md_endpoint: 'https://cpobackenddev001.blob.core.windows.net', // DEV
    // default_md_endpoint: 'https://cpobackendqa001.blob.core.windows.net',// QA
    default_md_endpoint: window.ENV.URL_FAQ_MD
}
export default default_settings;