import React from 'react';
import PropTypes from 'prop-types';

import BasicTable from '../components/BasicTable';
import CardTemplate from '../components/CardTemplate';

const History = (props) => {
  const { className } = props;

  return (
    <CardTemplate className={className} title="Historial de recargas">
      <BasicTable columns={null} data={null} isLoading />
    </CardTemplate>
  );
};

History.propTypes = {
  className: PropTypes.string,
};

History.defaultProps = {
  className: '',
};

export default History;
