import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Form, Select, Button, Typography, Alert } from 'antd';
import { apiCommands, apiSessions } from '@utils/services/cpo';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlug,faBolt, faClock, faStopwatch, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;
const { Paragraph } = Typography;

const CardSendCommand = (props) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const connectorsData = props.evseConnectors;
  const evseId = props.evseId;

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async (values) => {
    console.log("evseId: ", evseId)
    console.log('Finish:', values);

    setLoading(true);
    let response;
    try {
      switch (values.select_command) {
        case "start":
          var postData = {
            "evse_uid": evseId,
            "connector_id": values.select_connector,
          }
          response = await apiCommands.sendStart(postData);
          setSuccess(true);
          setLoading(false);
          break;
        case "stop":
          console.log("STOP")
          let sessionId;
          let getSession = await apiSessions.lastSession(evseId, values.select_connector).then(data => {
            sessionId = parseInt(data);
          }).catch((error) => {
            setLoading(false);
            error.message = "No hay cargas activas para el conector seleccionado";
            setError(error);
          });
          if (sessionId){
            var postData = {
              "session_id": sessionId
            }
            response = await apiCommands.sendStop(postData,user.user_id);
            setSuccess(true);
            setLoading(false);
          }
          break;
        case "restart":
          console.log("RESTART")
          var postData = {
            "evse_uid": evseId,
            "response_url": "http://localhost:8080",
            "type": "Hard",
          }
          console.log(postData);
          response = await apiCommands.sendRestart(postData);
          setSuccess(true);
          setLoading(false);
          break;
        default:
          break;
      }
    } catch (err) {
      setLoading(false);
      setError(err);
      return;
    }
  };

  const handleClose = () => {
    setSuccess(false);
  };

  return (
    <Card
      bodyStyle={{ overflow: 'hidden', height: '100%',padding:'30px' }}
      className={'card_comandos'}
      // title="Enviar comandos"
    > 
      <h3 style={{marginBottom:'40px',fontWeight:'bold',fontSize:'16px'}}>Enviar comandos</h3>
      <Form form={form} name="horizontal_login"  style={{textAlign:'center'}} onFinish={onFinish}>
        <Form.Item
          name="select_connector"
          label={<FontAwesomeIcon color={'gray'}  icon={faPlug} />}          
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un toma!',
            },
          ]}
        >
          <Select defaultValue="0" >
          <Option value="0">Seleccione un toma</Option>
            {connectorsData.map(result => {
              return (
                <Select.Option key={result.conn_id} value={result.conn_id}>
                  {result.conn_id}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="select_command"
          label={<FontAwesomeIcon color={'gray'} className={'metricas-card_icon_float'}  icon={faBolt} />}
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un comando!',
            },
          ]}
        >
          <Select defaultValue="0"  >
            <Option value="0">Seleccione acción</Option>
            <Option value="start">Iniciar carga</Option>
            <Option value="stop">Parar carga</Option>
            <Option value="restart">Reiniciar cargador</Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width:'100%',maxWidth:'200px'}}
              loading={loading}
              disabled={
                !form.isFieldsTouched(true) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Enviar
            </Button>
          )}
        </Form.Item>
      </Form>
      {success && (
        <Alert
          style={{ marginTop:20, marginBottom: 20 }}
          description="Comando enviado correctamente."
          type="success"
          showIcon
          closable
          afterClose={handleClose}
        />
      )}
      {error && (
        <Alert
          style={{ marginTop:20, marginBottom: 20 }}
          message="Error en consulta"
          description={error.message}
          type="error"
          showIcon
          closable
          afterClose={handleClose}
        />
      )}
    </Card>
  );
};

export default CardSendCommand;
