import React from 'react';
import { Space, Typography, Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const CompareByTime = () => {
  return (
    <Space>
      <Paragraph style={{ margin: 0 }}>Año anterior</Paragraph>
      <Statistic valueStyle={{ fontSize: 14 }} value="12%" prefix={<ArrowUpOutlined />} />
      <Statistic valueStyle={{ fontSize: 14 }} value="12%" prefix={<ArrowDownOutlined />} />
    </Space>
  );
};

export default CompareByTime;
