import React from 'react';
import { List, Avatar, Typography } from 'antd';

const { Paragraph } = Typography;

const hardcoreData = [
  {
    avatarUrl: 'https://pbs.twimg.com/profile_images/1080107688850268161/6v02KLV7_400x400.jpg',
    title: 'Nuevos servicios Celsia',
    description: 'Hola, ¿quieres ver las nuevas funciones que la plataforma ofrece para ti?',
  },
  {
    avatarUrl:
      'https://www.renaulttlalnepantla.com.mx/resrc/media/image/335595/renault-twizy-media-gallery-17.jpg',
    title: 'Alerta vehículo',
    description: 'Hola, el vehículo ELEC001 Twizy ha pasado los limites de velocidad permitidos',
  },
  {
    avatarUrl: 'https://pbs.twimg.com/profile_images/1080107688850268161/6v02KLV7_400x400.jpg',
    title: 'Nuevos servicios Celsia',
    description: 'Hola, ¿quieres ver las nuevas funciones que la plataforma ofrece para ti?',
  },
  {
    avatarUrl:
      'https://www.renaulttlalnepantla.com.mx/resrc/media/image/335595/renault-twizy-media-gallery-17.jpg',
    title: 'Alerta vehículo',
    description: 'Hola, el vehículo ELEC001 Twizy ha pasado los limites de velocidad permitidos',
  },
  {
    avatarUrl: 'https://pbs.twimg.com/profile_images/1080107688850268161/6v02KLV7_400x400.jpg',
    title: 'Nuevos servicios Celsia',
    description: 'Hola, ¿quieres ver las nuevas funciones que la plataforma ofrece para ti?',
  },
  {
    avatarUrl:
      'https://www.renaulttlalnepantla.com.mx/resrc/media/image/335595/renault-twizy-media-gallery-17.jpg',
    title: 'Alerta vehículo',
    description: 'Hola, el vehículo ELEC001 Twizy ha pasado los limites de velocidad permitidos',
  },
];

const NotificationTray = () => {
  return (
    <div
      style={{
        minHeight: 200,
        maxHeight: 420,
        overflowY: 'auto',
        padding: '0 24px',
      }}
    >
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={hardcoreData}
        renderItem={({ title, description, avatarUrl }) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={avatarUrl} />}
              title={<Paragraph strong>{title}</Paragraph>}
              description={<Paragraph>{description}</Paragraph>}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default NotificationTray;
