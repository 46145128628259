import { get, post, put } from './management';

const clientes = (exactEndpoint = null) => {
    if (!exactEndpoint) {
        return get('/client/list', true);
    }
    return get(exactEndpoint, true, true);
}

const crear_cliente = (body) => {
    return post('/client/create/',body,true);
}

const desactivar_cliente = (body) => {
    const id = body.id;
    delete body.id;
    console.log(body);
    return put(`/client/change-status/${id}/`,body,true);
}

const editar_cliente = (body) => {
    return put(`/client/update/${body.id}/`,body,true);
}

const obtener_ciudades = (code) => {
    return get(`/client/cities/?country_code=${code}`,true);
}

const obtener_paises = () => {
    return get(`/client/countries/`,true);
}

export default { clientes, crear_cliente, editar_cliente, obtener_ciudades, obtener_paises, desactivar_cliente };