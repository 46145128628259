import React, { useEffect, useState, useRef } from 'react';
import { Select, Input, Typography, Card, Table, Button,Row,Col } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { apiLocations } from '@utils/services/cpo';
import { useSelector } from 'react-redux';

import { apiOperators } from '@utils/services/cpo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { comprobar_permiso } from '@utils/auth/permissions';
import { faCircle,faEdit,faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { export_to_csv } from '@utils/helpers/exportCSVUtil';
import defaultSettings from '@utils/services/default';
const { Search } = Input;
const { Paragraph } = Typography;

const estado_definicion = {
  AVAILABLE:["Disponible","#0DC390"],
  BLOCKED:["BLOCKED","#262626"],
  CHARGING:["Cargando","#49A2D8"],
  INTEROPERATIVE:["INTEROPERATIVE","#262626"],
  OUTOFORDER:["Fuera de servicio","#262626"],
  PLANNED:["Fallo de conexión","#F60141"],
  REMOVED:["REMOVED","#262626"],
  RESERVED:["Reservado","#F0B518"],
  UNKNOWN:["UNKNOWN","#F60141"],
  UNKNOW:["UNKNOW","#F60141"]
}

const columnsBase = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    colSpan: 1,
  }, 
  {
    title: 'OPERADOR',
    dataIndex: 'operador',
    key: 'operador',
    sorter: (a, b) => a.operador - b.operador,
  },
  {
    title: 'ESTACION',
    dataIndex: 'estacion',
    key: 'estacion',
    sorter: (a, b) => a.estacion - b.estacion,
  },
  {
    title: 'CONECTOR ID',
    dataIndex: 'conector_id',
    key: 'conector_id',
    sorter: (a, b) => a.conector_id - b.conector_id,
  },
  {
    title: 'CONECTOR',
    dataIndex: 'conector',
    key: 'conector',
    sorter: (a, b) => a.conector - b.conector,
  },
  {
    title: 'ESTADO',
    dataIndex: 'estado',
    key: 'estado',
    sorter: (a, b) => a.estado - b.estado,
    render:(e) => {
      return ( 
      <div>
        <FontAwesomeIcon icon={faCircle} style={{ fontSize: 10,color:`${estado_definicion[`${e}`][1]}`,marginRight:10  }} />
        {`${estado_definicion[`${e}`][0]}`}
      </div>);
    }
  },
  {
    title: 'USO',
    dataIndex: 'uso',
    key: 'uso',
    sorter: (a, b) => a.uso - b.uso,
  },
  {
    title: 'DIRECCION',
    dataIndex: 'direccion',
    key: 'direccion',
    sorter: (a, b) => a.direccion - b.direccion,
  },
  
];

let columns = [];


const TablaCargadores = () => {
  const match = useRouteMatch();
  const { user, locations:locationsItems } = useSelector((state) => state);
  
  const [state, setState] = useState(
    {
      operators: [],
      locationsName: [],
      evseStatus: [],
      locations: [],
      loading: false
    }
  );

  const [ estaciones, setEstaciones ] = useState({
    data:[],
    loading:false, 
  });
  const [operators, setOperators] = useState([]);
  const [locationsName, setLocationsName] = useState([]);
  const [evseStatus, setEvsetatus] = useState([]);

  const cargarLocations = () => {
    setEstaciones({...estaciones,loading:true});
    apiLocations.getLocations(user.user_id).then( (rslt) => {
      setEstaciones({loading:false,data:rslt});
    }).catch( (err) => {
        console.log(err);
        setEstaciones({...estaciones,loading:false});
    });
  }



  const cargarData = () => {
    cargarLocations();
    // apiLocations
    //   .locations(user.user_id)
    //   .then(data => {
    //     console.log(user);
    //     let locations = data.map((item,index) => {
    //       return {
    //         key: `${item.evse["id"]}_${index}`,
    //         id: item.evse["id"],
    //         operador: item.evse["evse_operator"],
    //         estacion: item.evse["evse_location_name"],
    //         conector_id: item.conn_id,
    //         conector: item.standard,
    //         estado: item.evse["evse_status"],
    //         uso: item.evse["evse_type_use"],
    //         direccion: item.evse["evse_location_address"],
    //         acciones: `${match.path}/${item.evse["id"]}`
    //       }
    //     });
    //     setState({ ...state, locations, loading: false });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setState({ ...state, isFething: false });
    //   });

    apiOperators
    .operators()
    .then((operators) => {
      setOperators(operators);
    })
    .catch((error) => {
      setState({ ...state, isFething: false });
    });

    apiOperators
    .locationsName()
    .then((locations) => {
      setLocationsName(locations);
    })
    .catch((error) => {
      setEvsetatus({ ...state, isFething: false });
    });

    apiOperators
    .statusEvse()
    .then((evseStatus) => {
      setEvsetatus(evseStatus);
    })
    .catch((error) => {
      setState({ ...state, isFething: false });
    });
  }

  useEffect(() => {
    
    // setState({ ...state, loading: true });
    cargarData();

    // setInterval(cargarData,20000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(comprobar_permiso(user,7)){
      columns = columnsBase.concat([{
        title: 'ACCIONES',
        dataIndex: 'acciones',
        key: 'acciones',
        render: (link) => {
          return (
            <Row>
              <Col>
                <Link to={`${match.path}/${link}`}>
                  <FontAwesomeIcon className={'icon-table_acciones'} to={`${match.path}/${link}`} icon={faEye}/>
                </Link>
              </Col>
              <Col>
                <Link to={`/tablero-cpo/editar_cargador/${link}`} >
                  <FontAwesomeIcon  className={'icon-table_acciones'} icon={faPen}/>
                </Link>
              </Col>
            </Row>
          );
          // return (<Button type="link">
            
          //   
          // </Button>)
        }
      }])
    } 
  },[locationsItems.locations]);

  const params = useRef(new URLSearchParams({ export: "false" }))

  function handleFilter(type, value) {
    switch (type) {
      case "operators":
        params.current.set('evse__location__operator', value);
        break;
      case "locations":
        params.current.set('evse__location', value);
        break;
      case "status":
        params.current.set('evse__status', value);
        break;

      case "search":
        params.current.set('search', value);
        break;

      default:
        break;
    }
    setState({ ...state, loading: false });

    if (type == "export") {
      params.current.set('export', 'true');
      fetch(`${defaultSettings.default_endpoint}/ocpi/cpo/locations/get-connectors?` + params.current, {
        method: 'GET',
        headers: new Headers({
          "Content-Type": "application/vnd.ms-excel"
        })
      }).then(response => response.blob())
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "filename.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          params.current.set('export', 'false');
          setState({ ...state, loading: false });
        })
        .catch((error) => {
          setState({ ...state, loading: false });
        });
    } else {
      // apiLocations
      //   .locations(params.current)
      //   .then(data => {

      //     let locations = data.results.map(item => {
      //       return {
      //         key: item.evse["id"],
      //         id: item.evse["id"],
      //         operador: item.evse["evse_operator"],
      //         estacion: item.evse["evse_location_name"],
      //         conector_id: item.conn_id,
      //         conector: item.standard,
      //         estado: item.evse["evse_status"],
      //         uso: item.evse["evse_type_use"],
      //         direccion: item.evse["evse_location_address"],
      //         acciones: `${match.path}/${item.evse["id"]}`
      //       }
      //     });
      //     setState({ ...state, locations, loading: false });
      //   })
      //   .catch((error) => {
      //     setState({ ...state, isFething: false });
      //   });
    }
  }

  const [pageSize, setPageSize] = useState(10);

  const onChange = (page, pageSize) => {
    setPageSize(pageSize);
  };

  const { locations, loading } = state;

  const dataFilter = () => {
    return (
      locationsItems.locations.filter((v,i)=>{
        let ubicacion_seleccionada = params.current.get("evse__location");
        let busqueda_actual = params.current.get("search");

        if(ubicacion_seleccionada) ubicacion_seleccionada = ubicacion_seleccionada.toLowerCase();
        if(busqueda_actual) busqueda_actual = busqueda_actual.toLowerCase();

        // console.log(busqueda_actual);
        // console.log(v.id,`${v.id}`.includes(busqueda_actual),busqueda_actual);
        // console.log(v.operador,`${v.operador}`.includes(busqueda_actual),busqueda_actual);

        if(busqueda_actual == null || `${v.id}`.toLowerCase().includes(busqueda_actual) || `${v.operador}`.toLowerCase().includes(busqueda_actual) ||
        `${v.estacion}`.toLowerCase().includes(busqueda_actual) || `${v.conector_id}`.toLowerCase().includes(busqueda_actual) ||
        `${v.conector}`.toLowerCase().includes(busqueda_actual) || `${v.estado}`.toLowerCase().includes(busqueda_actual) || 
        `${v.uso}`.toLowerCase().includes(busqueda_actual) || `${v.direccion}`.toLowerCase().includes(busqueda_actual)){

          if(ubicacion_seleccionada != 0 && v.estacion.toLowerCase() == ubicacion_seleccionada) return true; 
          if(ubicacion_seleccionada == 0 || ubicacion_seleccionada == null) return true;  

        }else{
          return false;
        }

        
      })
    );
  }
  
  return (

    <Card className="alertas-prev-mant" size="small" title="">
      <div className="filtros-tabla">
        {/* <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todos los países"
          disabled
        >
        </Select>

        <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todas las ciudades"
          disabled
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="tom">Tom</Option>
        </Select> */}

        {/* <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todos los operadores"
          onChange={(value) => { handleFilter("operators", value) }}
        >
          {operators.map(({ id, name }) => {
            return (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            );
          })}
        </Select> */}

        <Select
          className="select-filtro"
          style={{ width: 170 }}
          defaultValue={0}
          onChange={(value) => { handleFilter("locations", value) }}
          loading={estaciones.loading}
        >
          <Select.Option key={0} value={0}>
                {estaciones.data.length > 0 ? 'Todas las estaciones' : estaciones.loading ? 'Cargando' : 'No disponibles'}
            </Select.Option>
          {estaciones.data.map(({ id, name }) => {
            return (
              <Select.Option key={id} value={name}>
                {name}
              </Select.Option>
            );
          })}
        </Select>

        {/* <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todos los estados"
          onChange={(value) => { handleFilter("status", value) }}
        >
          {evseStatus.map(({ id, name }) => {
            return (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            );
          })}

        </Select> */}

        <Search
          placeholder="Buscar palabras clave"

          onChange={(e) => {
            handleFilter("search", e.target.value) 
          }}
          style={{ width: 170 }}
        />

        {/* <Paragraph style={{ margin: 0 }}>Filtro por vehículo:</Paragraph>
        <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todas lass líneas"
          disabled
        >
          <Option value="jack">AVAILABLE</Option>
          <Option value="lucy">OFF</Option>
          <Option value="tom">ON</Option>
        </Select>
        <Select
          className="select-filtro"
          style={{ width: 170 }}
          placeholder="Todas las marcas"
          disabled
        >
          <Option value="jack">AVAILABLE</Option>
          <Option value="lucy">OFF</Option>
          <Option value="tom">ON</Option>
        </Select> */}
        <Button onClick={(value) => export_to_csv(null,dataFilter(),'Cargadores')} className="select-filtro" >
          Descargar
        </Button>
      </div>

      <Table
      style={{
        marginTop:10
      }}
        columns={columns}
        dataSource={dataFilter()}
        tableLayout="auto"
        loading={locationsItems.fetching}
        pagination={{
          pageSize,
          pageSizeOptions: [10, 20, 50],
          showSizeChanger: true,
          onChange,
        }}
      />
    </Card>
  );
};

export default TablaCargadores;
