import React from 'react';

import CardTemplateLayout from './CardTemplateData';
import CompareByTime from './CompareByTime';

const EnergiaRecargada = () => {
  return (
    <CardTemplateLayout
      title="Energía recargada"
      mainValue="500000"
      footerTitle="Prom. recargas diarias"
      footerValue="400"
      suffix="kWh"
      GraphData={CompareByTime}
    />
  );
};

export default EnergiaRecargada;
