import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const BasicTable = (props) => {
  const { columns, data, isLoading } = props;
  return (
    <Table
      rowSelection={false}
      size="small"
      columns={columns}
      dataSource={data}
      style={{ overflow: 'hidden', height: '100%' }}
      scroll={{ y: 240 }}
      loading={isLoading}
      pagination={false}
    />
  );
};

BasicTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

BasicTable.defaultProps = {
  columns: null,
  data: null,
  isLoading: false,
};

export default BasicTable;
