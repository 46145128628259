import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const ConnectorInfoItem = (props) => {
  const { dataConnector } = props;
  console.log(dataConnector);
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const date_formate = ( date ) => {
    let dateTemp = new Date(date);
    let dateUTC = new Date(Date.UTC(dateTemp.getFullYear(),dateTemp.getMonth(),dateTemp.getDate(),dateTemp.getHours(),dateTemp.getMinutes()));
    try{
      return `${dateUTC.getDate()} ${months[dateUTC.getMonth()].substring(0,3).toLowerCase()} ${dateUTC.getFullYear()} ${dateUTC.getHours()}:${dateUTC.getMinutes()}`;
    }catch(e){
      return `${date}`;
    }
    
}

  let data = [
    {
      name: 'Energía',
      value: dataConnector.last_charger_data.total_energy+' kWh',
    },
    {
      name: 'Potencia',
      value: `${dataConnector.max_voltage} kW (${dataConnector.max_amperage} A)`,
    },
    {
      name: 'Tarjeta',
      value: `${dataConnector.last_charger_data.card_code}`,
    },
    {
      name: 'Inicio de sesión',
      value: `${date_formate(dataConnector.last_charger_data.start_date_time)}`,
    },
    {
      name: 'Fin de sesión',
      value: `${date_formate(dataConnector.last_charger_data.end_date_time)}`,
    },
    {
      name: 'Duración',
      value: `${dataConnector.last_charger_data.total_period_time_secs}`,
    }
  ];
  return (
    <div>
      {data.map((item) => {
        const { name , value } = item;
        return(
          <div key={`div_${name}`} className="card-connector-info__data-item">
            <Paragraph type="secondary">{`${name}:`}</Paragraph>
            <Paragraph strong>{value}</Paragraph>
          </div>
        )
      })}
      
    </div>
  );
};

ConnectorInfoItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

ConnectorInfoItem.defaultProps = {
  className: null,
  name: null,
  value: null,
};

export default ConnectorInfoItem;
