import React, { useState } from 'react';
import { Select, Typography, Card, Table, Tabs, Radio,message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { DateSelectorGroup } from '@components/ui';
import getUTCString from '@utils/helpers/UtcRequestConverter';
import { ExportToCsv } from 'export-to-csv';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const textLanguage = Spanish({screen:'cpo-alerts'})

const alerta_definiciones = {
    CF:[textLanguage.ALERTS_CPO_TYPE_CF,`#49A2D8`]
}

const columns = [
    {
        title:'FECHA',
        dataIndex:'fecha',
        key:'fecha',
        sorter:(a,b) => {
            let dateA = new Date(a.fechaMillis);
            let dateB = new Date(b.fechaMillis);
            return dateA.getTime() - dateB.getTime();
        },
    },
    {
        title:'ID',
        dataIndex:'id',
        key:'id',
        sorter:(a,b) => {
            return a.id - b.id;
        },
    },
    {
        title:'OPERARIO',
        dataIndex:'operario',
        key:'operario',
        sorter:(a,b) => a.operario.localeCompare(b.operario),
    },
    {
        title:'ESTACIÓN',
        dataIndex:'estacion',
        key:'estacion',
        sorter:(a,b) => {
            console.log(a,b);
            return `${a.estacion}`.localeCompare(`${b.estacion}`);
        },
    },
    {
        title:'CONECTOR ID',
        dataIndex:'conector_id',
        key:'conector_id',
        sorter:(a,b) => a.conector_id.localeCompare(b.conector_id),
    },
    {
        title:'CONECTOR',
        dataIndex:'conector_tipo',
        key:'conector_tipo',
    },
    {
        title:'USO',
        dataIndex:'uso',
        key:'uso',
    },
    {
        title:'ALERTA',
        dataIndex:'alerta',
        key:'alerta',
        render:( obj )=> {
            return (
                <div style={{
                    color:`${alerta_definiciones[`${obj}`][1]}`
                }}>
                    <FontAwesomeIcon icon={faWifi} style={{ fontSize: 20, marginRight: 10 }} />
                    {`${alerta_definiciones[`${obj}`][0]}`}
                </div>
            );
        }
    },
]



const TablaAlertas = ( props ) => {
    
    const { data , loading, cargadores } = props;
    
    
    const [pageSize, setPageSize] = useState(10);
    const [ tipoCargador , setTipoCargador ] = useState('');
    // const user = useSelector((state) => state.user);
    // const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const onChangeDateRange = (start,end) => {
        let dateStart = new Date(start);
        let dateEnd = new Date(end);

        setRangoDate([dateStart,dateEnd]);
    };

    // useEffect(() => {
    //     // console.log(data);
    // },[data]);

    const rangoHoy = () => {
        let hoyTemp1 = new Date();
        let hoyTemp2 = new Date();

        hoyTemp1.setHours(0);
        hoyTemp1.setMinutes(0);
        hoyTemp1.setMilliseconds(0);

        hoyTemp2.setHours(23);
        hoyTemp2.setMinutes(59);
        hoyTemp2.setMilliseconds(59);

        return [hoyTemp1,hoyTemp2]
    }

    const [rangoDate , setRangoDate] = useState(rangoHoy());

    const onChange = (page, pageSize) => {
        setPageSize(pageSize);
      };

    const handleChargerChange = (e,v) => {
        setTipoCargador(e != 0 ? v.children : '');
    }

    const handleDownload = () => {
        // console.log("solicitando download");
        message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
        export_to_csv(null,data,'Alertas');
    }

    return(
        <Card>
            <div className="filtros-tabla"
            style={{
                display:"flex",
                alignItems:"center",
            }}>
                {/* 
                * Falta por defini el permiso especifico para esta accion
                <Select
                    className="select-filtro"
                    style={{ width:170,marginRight:20 }}
                    placeholder="Todos los operadores"
                    disabled>
                </Select> */}
                
                <DateSelectorGroup
                    onChangeDateRange={onChangeDateRange}/>

                <Paragraph style={{ 
                        marginLeft:10,
                        marginRight:10,
                        marginBottom:0,
                        marginTop:0,
                }}>Cargador(es):</Paragraph>
                <Select
                    className="select-filtro"
                    style={{ 
                        width:'auto',
                    }}
                    defaultValue={0}
                    onChange={handleChargerChange}
                    >
                        <Option key={0} value={0}>
                                    {'Todos los cargadores'}
                        </Option>
                        {
                            cargadores.map((value,index)=>{
                                return (
                                <Option key={value.id} value={value.id}>
                                    {value.name}
                                </Option>
                                );
                            })
                        }
                </Select>

                {/* <Button 
                    style={{
                        backgroundColor:'#EE7400',
                        color:'white',
                        marginLeft:20
                    }}>
                    Aplicar
                </Button> */}
                {/* <Button 
                    style={{
                        backgroundColor:'#EE7400',
                        color:'white',
                        marginLeft:20
                    }}
                    onClick={cargarAlertas}>
                    Refrescar
                </Button> */}
            </div>
            
            <Tabs
            style={{
                marginTop:20
            }} 
            defaultActiveKey='alerta-conexion'
            tabBarExtraContent={
                <Radio.Button
                style={{
                    borderColor:'#EE7400',
                    color:'#EE7400'
                }}
                onClick={handleDownload}>
                    Descargar
                </Radio.Button>
            }
            >
                
                <TabPane tab="General" key="alerta-general" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab="Conexión" key="alerta-conexion">
                    <Table
                        columns={columns}
                        dataSource={
                            data.filter( (obj) => {
                                let dateObj = new Date(obj.fechaMillis);
                                return (rangoDate[0] < dateObj && dateObj < rangoDate[1]) && obj ; 
                            })
                        }
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab="Infraestructura" key="alerta-infraestructura" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab="Avisos" key="alerta-avisos" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
            </Tabs>
            
            
        </Card>
    );
}

export default TablaAlertas;