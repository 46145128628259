const preKey = 'VEHICLE_VIEW';

exports.SET_PLACA = `${preKey}_SET_PLACA`;
exports.SET_VEHICLE_DATA = `${preKey}_SET_VEHICLE_DATA`;

exports.VEHICLE_DATA_FETCHING = `${preKey}_SET_VEHICLE_DATA`;
exports.VEHICLE_DATA_SUCCESS = `${preKey}_VEHICLE_DATA_SUCCESS`;
exports.VEHICLE_DATA_ERROR = `${preKey}_VEHICLE_DATA_ERROR`;
exports.VEHICLE_UPDATE = `${preKey}_VEHICLE_UPDATE`;

exports.RESET_STATE = `${preKey}_RESET_STATE`;
