import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BasicTable from '../components/BasicTable';
import CardTemplate from '../components/CardTemplate';

const columns = [
  {
    title: 'VARIABLE',
    dataIndex: 'var',
    width: '60%',
  },
  {
    title: 'DETALLE',
    dataIndex: 'detail',
  },
];
const dataSource = [
  {
    key: '1',
    var: 'Voltaje de alimentación',
    detail: '40 V',
  },
  {
    key: '2',
    var: 'Voltaje de entrada ACU',
    detail: '40 V',
  },
  {
    key: '3',
    var: 'Temperatura AUC (°C)',
    detail: '-7.5',
  },
  {
    key: '4',
    var: 'Temperatura BMS (°C)',
    detail: '-6.5',
  },
  {
    key: '5',
    var: 'Aislamiento BMS',
    detail: '-2',
  },
  {
    key: '6',
    var: 'Sistema de refrigeración (°C)',
    detail: '-2',
  },
];

const VarsSystem = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!isLoading) return;
    const interval = setInterval(() => {
      setIsLoading(false);
      setData(dataSource);
      clearInterval(interval);
    }, 1000);
  }, [isLoading]);

  return (
    <CardTemplate className={className} title="Variables actuales del sistema">
      <BasicTable columns={columns} data={data} isLoading={isLoading} />
    </CardTemplate>
  );
};

VarsSystem.propTypes = {
  className: PropTypes.string,
};

VarsSystem.defaultProps = {
  className: '',
};

export default VarsSystem;
