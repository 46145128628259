import constants from './constants';

const initState = {
  dateRangeType: null,
  dateRange: null,
  groupId: null,
  vehicles: null,
  vehiclesSelected: null,
  distanceChartData: null,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_RANGE_DATE:
    case constants.SET_GROUP_ID:
    case constants.SAVE_LAST_VEHICLES_INFO:
    case constants.SET_DISTANCE_CHART_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
