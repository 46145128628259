import React from 'react';
import PropTypes from 'prop-types';
import { Card, Space, Button } from 'antd';

import ConnectorInfoItem from '../components/ConnectorInfoItem';


const ConnectorInfo = (props) => {
  const connectorsData = props.evseConnectors;
  const { className, fetchingConnectorDetail } = props;

  let connectorData = connectorsData.map((item) => {
    return [
      {
        name: 'Energía',
        value: '2290 kWh',
      },
      {
        name: 'Potencia actual',
        value: '2290 kWh',
      },
      {
        name: 'Modo de uso',
        value: 'APP',
      },
      {
        name: 'Tarjeta',
        value: '',
      },
      {
        name: 'Correo',
        value: 'ACBOK002',
      },
      {
        name: 'Inicio de sesión',
        value: '20/05/2020, 14:57',
      },
      {
        name: 'Fin de sesión',
        value: '20/05/2020, 14:57',
      },
      {
        name: 'Duración',
        value: '00:35:20',
      }
    ];
  })

  

  return (
    <Card title="Toma 1:" extra={<a href="#">Tipo 1 <span class="dot"></span> Cargando</a>} className={className} loading={fetchingConnectorDetail}>
      <div style={{ position: 'relative' }}>
        <Button size="small" style={{ position: 'absolute', bottom: 0, right: 0 }}>
          Editar
        </Button>
        <Space direction="horizontal" size="middle">
            <div>
              {connectorData.map((item) => {
                const { name, value } = item;
                return (
                  <ConnectorInfoItem
                    key={name}
                    className={`${className}__data-item`}
                    name={name}
                    value={value}
                  />
                );
              })}
            </div>
        </Space>
      </div>
    </Card>
  );
};

ConnectorInfo.propTypes = {
  className: PropTypes.string,
  fetchingVehicleDetail: PropTypes.bool,
};

ConnectorInfo.defaultProps = {
  className: '',
  fetchingVehicleDetail: false,
};

export default (ConnectorInfo);
