import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { /* TablesView, */ MapView, VehicleView } from './views';

const GeneralStatus = () => {
  const match = useRouteMatch();

  const mapRoute = `${match.path}/mapa`;
  const vehicleRoute = `${match.path}/:vehicleId`;

  return (
    <Switch>
      {/* <Route path={tableRoute} component={TablesView} /> */}
      <Route path={mapRoute} component={MapView} />
      <Route path={vehicleRoute} component={VehicleView} />
      <Redirect to={mapRoute} />
    </Switch>
  );
};

export default GeneralStatus;
