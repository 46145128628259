import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'

import {
    AdministrarCargadores,
} from '@views/CargadoresCPO/views';

const path = '/cargadores-cpo';

const cargadoresCPO = {
    path,
    name: 'Cargadores - CPO',
    icon: <FontAwesomeIcon className="anticon" style={{ marginRight: 10, fontSize: 21, minWidth: "auto" }} icon={faChargingStation} />,
    key: 'cargadores-cpo',
    isSubMenu: true,
    children: [{
        path: `${path}/administrar-cargadores`,
        name: 'Administrar cargadores',
        component: AdministrarCargadores,
        key: 'admin-cargadores',
    },
        // {
        //     path: `${path}/control-novedades`,
        //     name: 'Control de novedades',            
        //     component: ControlNovedades,
        //     key: 'control-novedades'            
        // },
    ],
};

export default cargadoresCPO;