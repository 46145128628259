/* eslint-disable no-undef */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Marker from '@components/mapa/Marker';

const MarkerListRendimientoFlotas = (props) => {
  const { map } = props;
  const { vehiclesSelected, vehicles } = useSelector((state) => state.rendimientoFlotas);
  const [filterVehicle, setFilterVehicle] = useState([]);
  const isFirstTime = useRef(true);

  const adjustMapToMarkers = useCallback(
    (markersData) => {
      if (!isFirstTime.current || !map || markersData.length === 0) return;

      const bounds = new window.google.maps.LatLngBounds();
      markersData.forEach(({ lat, lon: lng }) => {
        if ((lat === 0 && lng === 0) || !lat || !lng) return;
        bounds.extend({ lat, lng });
      });
      map.fitBounds(bounds);

      isFirstTime.current = false;
      // eslint-disable-next-line
    },
    [map]
  );

  useEffect(
    () => {
      const valuesVehicles = Object.values(vehicles || {});
      const tempFilter = valuesVehicles.filter(({ lic }) => vehiclesSelected.includes(lic));
      setFilterVehicle(tempFilter);
      adjustMapToMarkers(tempFilter);
    },
    // eslint-disable-next-line
    [vehiclesSelected, vehicles, map]
  );

  return (
    <>
      {filterVehicle.map((item) => {
        const { lic } = item;
        return <Marker key={lic} item={item} />;
      })}
    </>
  );
};

MarkerListRendimientoFlotas.propTypes = {
  map: PropTypes.objectOf(PropTypes.any),
};

MarkerListRendimientoFlotas.defaultProps = {
  map: null,
};

export default MarkerListRendimientoFlotas;
