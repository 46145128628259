import ConfigUsuarios from '@views/ConfigUsuarios'

const path = '/configuracion-cuenta/usuarios';
const path2 = '/configuracion-cuenta/:cliente/usuarios';

const usuariosMenu = {
  path,
  path2,
  name: 'Usuarios',
  bread:'Configuración de cuenta/usuarios',
  breadCompose:(cliente) => {
    return cliente ? [[null,`Configuración de cuenta`],["/configuracion-cuenta/clientes",`Clientes`],[null,`${cliente}`],[null,'usuarios']] : [[null,`Configuración de cuenta`],[null,"usuarios"]];
  },
  icon: null,
  component:ConfigUsuarios,
  key: 'config-user-usuario',
  children: [],
};

export default usuariosMenu;