/* eslint-disable no-undef */

import React, { useState, useEffect } from 'react';
import { Marker as BasicMarker, InfoWindow } from '@react-google-maps/api';
import { Typography, Progress, Space } from 'antd';
import PropTypes from 'prop-types';

import { carOn, carOff, carChar, carStop, carDisconnected } from '@assets/img/markers';

const { Paragraph } = Typography;

function LabelData({ title, value }) {
  const defValue = value ? value.toFixed(2) : 0.0;

  return (
    <Space size="small">
      <Paragraph style={{ margin: 0 }}>{title}:</Paragraph>
      <Paragraph strong style={{ margin: 0 }}>
        {defValue}
      </Paragraph>
    </Space>
  );
}

LabelData.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const Marker = (props) => {
  const { item, infoWindowOnCloseClick, activeInfo } = props;
  const { lic, lat, lon: lng, sta, soc, cur, vol, spe } = item;

  const [showInfo, setShowInfo] = useState(null);
  const [markerMap, setMarkerMap] = useState(null);

  useEffect(() => {
    setShowInfo(activeInfo);
    // eslint-disable-next-line
  }, [activeInfo]);

  if (!lat || !lng) return null;

  const position = { lat, lng };
  const icon = (() => {
    switch (sta) {
      case 'ON':
        return carOn;
      case 'OFF':
        return carOff;
      case 'CHA':
        return carChar;
      case 'BAD':
        return carDisconnected;
      default:
        return carStop;
    }
  })();

  const potenciaActiva = Math.abs(cur * vol) / 1000 || 0;

  return (
    <>
      <BasicMarker
        position={position}
        icon={{
          url: icon,
          scaledSize: new window.google.maps.Size(48, 48),
        }}
        onClick={() => setShowInfo(!showInfo)}
        onLoad={(marker) => {
          setMarkerMap(marker);
        }}
      />

      {showInfo && (
        <InfoWindow
          position={position}
          onCloseClick={() => {
            setShowInfo(false);
            infoWindowOnCloseClick();
          }}
          anchor={markerMap}
        >
          <div style={{ width: 138, overflow: 'hidden' }}>
            <Paragraph style={{ marginBottom: 0 }} strong>
              {lic}
            </Paragraph>
            <Progress size="small" percent={soc} style={{ width: 138 }} />

            {(sta === 'ON' || spe > 0 || sta === 'CHA') && (
              <Space size={4} direction="vertical" style={{ marginTop: 8 }}>
                <LabelData title="Potencia activa" value={potenciaActiva} />
                <LabelData title="Velocidad" value={spe} />
              </Space>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

Marker.propTypes = {
  item: PropTypes.shape({
    lic: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    sta: PropTypes.string,
    soc: PropTypes.number,
    cur: PropTypes.number,
    vol: PropTypes.number,
    spe: PropTypes.number,
  }).isRequired,
  infoWindowOnCloseClick: PropTypes.func,
  activeInfo: PropTypes.bool,
};

Marker.defaultProps = {
  infoWindowOnCloseClick: () => {},
  activeInfo: false,
};

export default Marker;
