import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const VehicleInfoItem = (props) => {
  const { className, name, value } = props;

  return (
    <div className={className}>
      <Paragraph type="secondary">{`${name}:`}</Paragraph>
      <Paragraph strong>{value}</Paragraph>
    </div>
  );
};

VehicleInfoItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

VehicleInfoItem.defaultProps = {
  className: null,
  name: null,
  value: null,
};

export default VehicleInfoItem;
