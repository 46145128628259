/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { usuarios } from '@utils/menus/config_user/itemsMenu';
import Breadcrumb from '@containers/Breadcrumb';
import { TablaUsuarios } from '@views/ConfigUsuarios/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { apiConfigUsuarios } from '@utils/services/index';
import { ItemSelectorRol } from '@components/ui';
import { useSelector } from 'react-redux';
import './style.less';
import { Button, Card, Col, Row, Form, Input, Select, Drawer,message  } from 'antd';
import { comprobar_permiso } from '@utils/auth/permissions';
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
const { Option } = Select;

const TablesOrganizaciones = ( props ) => {
    const textLanguage = Spanish({screen:'config-users'})

    const { bread, breadCompose } = usuarios;
    const breadcrumbData = breadCompose(props.match.params.cliente);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [ modalTitle , setModalTitle ] = useState(`Crear nuevo cliente`);
    const [ usuarioForm , setUsuarioForm ] = useState({
        id:``,
        email:'',
        rol:``
    });
    const user = useSelector((state) => state.user);
    const [ crearUsuario_visible , setCrearUserVisible ] = useState( false );
    const [ rolesOperadores , setRolesOperadores ] = useState([]);
    const [ tableData , setTableData ] = useState([]);
    const [ tableLoading, setTableLoading ] = useState(false);
    const [ tableDataRaw , setTableDataRaw ] = useState([]);
    const [form] = Form.useForm();


    useEffect( () => {
        obtener_roles();
    },[]);

    useEffect( () => {
        cargarUsuarios()
    },[props.location.id]);

    const obtenerRolesRaw = ( key ) => {
        for(let i = 0 ; i < rolesOperadores.length ; i++){
            if(key == rolesOperadores[i].id) return rolesOperadores[i];
            if(key == rolesOperadores[i].name) return rolesOperadores[i];
        }
        return null
    }

    const obtenerUsuarioRaw = ( key ) => {
        console.log(tableDataRaw);
        for(let i = 0 ; i < tableDataRaw.length ; i++){
            if(key == tableDataRaw[i].id) return tableDataRaw[i];
        }
        return null
    }

    const cargarUsuarios = () => {
        setTableLoading(true);
        console.log(user);
        apiConfigUsuarios.usuarios(props.location.id ? props.location.id : user.clientSelected.client_id).then( (rslt) => {
            console.log(rslt);
            let data = [];
            for(let i = 0 ; i < rslt.length ; i++){
                data.push(tableItem(i,rslt[i]));
            }
            setTableData(data);
            setTableDataRaw(rslt);
            setTableLoading(false);
        }).catch( (err) => {
            console.log(err);
        });
    }

    const tableItem = (i,data) => {
        return {
            key:`${data.id}`,
            nombre:`${data.first_name} ${data.last_name}`,
            correo:data.email,
            rol:data.groups[0],
            last_login:getLocalDateString(data.date_joined),
            estado:`${data.is_active ? 1 : 0}`,
            acciones:''

        }
    }
    
    const handleEditarUsuario = ( data ) => {
        let dataUsuarioRaw = obtenerUsuarioRaw(data.key);
        setModalTitle(`Editar Usuario: ${dataUsuarioRaw.email}`);
        showCrearUsuario();
        console.log(dataUsuarioRaw);
        console.log(obtenerRolesRaw(dataUsuarioRaw.groups[0]));
        setUsuarioForm({
            id:`${dataUsuarioRaw.id}`,
            email:`${dataUsuarioRaw.email}`,
            rol:`${dataUsuarioRaw.groups[0] ? obtenerRolesRaw(dataUsuarioRaw.groups[0]).id : ``}`
        })
        form.setFieldsValue({
            email:dataUsuarioRaw.email,
            rol:`${dataUsuarioRaw.groups[0] ? obtenerRolesRaw(dataUsuarioRaw.groups[0]).id : `undefined`}`
        });
    }

    const invitarUsuario = ( rslt ) => {
        let objUsuario = {
            email:`${rslt.email}`,
            group_id:`${rslt.rol}`,
            client_id:`${props.location.id ? props.location.id : user.clientSelected.client_id}`
        }
        apiConfigUsuarios.usuario_invitacion(objUsuario).then( (rslt) => {
            if(rslt.success)
                message.success(rslt.success);

            if(rslt.error)
                message.error(rslt.error);

            cargarUsuarios();
            handleCancel();
        }).catch( (err) => {
            console.log(err);
        });
    }

    const editarUsuario = ( rslt ) => {
        let objEditarUsuarios = {
            user_id:usuarioForm.id,
            group_id:rslt.rol
        }
        apiConfigUsuarios.editar_usuario(objEditarUsuarios).then( (rslt) => {
            message.success(rslt);
            cargarUsuarios();
            handleCancel();
        }).catch( (err) => {
            message.success(err);
        });
    }

    const re_invitar = ( record ) => {
        let objReInvitar = {
            user_id:record.key,
            client_id:props.location.id ? props.location.id : user.clientSelected.client_id
        }
        apiConfigUsuarios.usuario_resend_invitacion(objReInvitar).then( (rslt) => {
            if(rslt.success)
                message.success(rslt.success);

            if(rslt.error)
                message.error(rslt.error);
        }).catch( (err) => {
            console.log(err);
        });
    }

    const obtener_roles = () => {
        apiConfigUsuarios.obtener_roles().then( (rslt) => {
            setRolesOperadores(rslt);
            console.log(rslt);
        }).catch( (err) => {
            console.log(err);
        });
    }

    const updatedUsuarioObj = (tag,value) => {
        let userUpdated = Object.assign({}, usuarioForm);
        userUpdated[`${tag}`] = value;
        setUsuarioForm(userUpdated);
    }

    const showCrearUsuario = () => {
        setCrearUserVisible(true);
    }

    const handleCrearUsuario = () => {
        showCrearUsuario();
        limpiarObj();
    }
    const limpiarObj = () => {
        setModalTitle(`Invitar usuarios`);
        setUsuarioForm({
            id:``,
            email:``,
            rol:`1`
        })
    }

    const handleEliminarUsuario = ( data ) => {
        let dataUsuarioRaw = obtenerUsuarioRaw(data.key);
        let objEliminarUsuario = {
            is_active:!dataUsuarioRaw.is_active,
        }
        
        apiConfigUsuarios.inactivar_usuario(dataUsuarioRaw.id,objEliminarUsuario).then((rslt) => {
            if(!rslt.is_active){
                // message.info(`Usuario ${dataUsuarioRaw.username} inactivo`);
                message.info(textLanguage.CONFIG_PROFILE_USERS_SWITCH_INACTIVE({username:dataUsuarioRaw.username}));
            }else{
                // message.success(`Usuario ${dataUsuarioRaw.username} activo`);
                message.success(textLanguage.CONFIG_PROFILE_USERS_SWITCH_ACTIVE({username:dataUsuarioRaw.username}));
            }
            console.log(rslt);
            cargarUsuarios();
        }).catch((err) => {
            console.log(err);
        });
    }

    const hideCrearUsuario = () => {
        setCrearUserVisible(false);
    }

    const handleOk = () => {
        form.validateFields().then( (rslt) => {
            console.log(rslt);
            if(rslt.errorFields) return;

            if(usuarioForm.id == ''){
                invitarUsuario(rslt);
            }else{
                editarUsuario(rslt);
            }
            
            hideCrearUsuario();
        }).catch( (err) => {
            console.log(err);
        });
    };

    const handleCancel = () => {
        form.resetFields();
        hideCrearUsuario();
    };

    const handleCSVDownload = () => {
        message.info(textLanguage.REQUEST_DOWNLOAD_CSV);
        export_to_csv(null,tableData,'Usuarios')
        // apiConfigUsuarios.getCSVUsuarios(props.location.id ? props.location.id : user.clientSelected.client_id).then( (rslt) => {
            
        // }).catch( (err) => {
        //     message.info(textLanguage.REQUEST_DOWNLOAD_CSV_FAIL);
        // });
    }

    return(
        <div id="config_general">
            <Breadcrumb breadcrumbData={breadcrumbData} />
            <Card className="organizaciones-top">
                <Row>
                    <Col span={16}>
                        <h3><strong>Administración de usuarios</strong></h3>
                        <h4>Las invitaciones llegaran a la bandeja del correo ingresado para la notificación</h4>
                    </Col>
                    <Col span={8}>
                        <Button
                        style={{
                            backgroundColor:'#EE7400',
                            color:'white',
                            marginLeft:20,
                            visibility:comprobar_permiso(user,1) ? 'visible' : 'hidden'
                        }}
                        onClick={() => handleCrearUsuario()}>
                            Crear usuarios
                        </Button>
                        <Button
                        style={{
                            borderColor:'#EE7400',
                            color:'#EE7400',
                            marginLeft:'10px'
                        }}
                        onClick={handleCSVDownload}>
                            Descargar usuarios
                        </Button>
                    </Col>
                </Row>
                
            </Card>
            <div className="organizaciones-table">
                <TablaUsuarios data={tableData} loading={tableLoading} refreshData={cargarUsuarios} editar_user={handleEditarUsuario} re_invitar={re_invitar} eliminar_user={handleEliminarUsuario} />
            </div>

            <Drawer
            style={{
                boxSizing:'border-box',
                height:'100%',
                float:"right",
                top:0,
                padding:0,
            }}
            placement="right"
            width={700}
            visible={crearUsuario_visible}
            onCancel={() => hideCrearUsuario()}
            onClose={() => hideCrearUsuario()}
            footer={[
                <Button style={{marginRight:'10px'}} key="Cancelar" onClick={handleCancel}>
                  Cancelar
                </Button>,
                <Button key="Enviar" type="primary" onClick={handleOk}  >
                  {usuarioForm.id == '' ? `Enviar invitación` : `Guardar Cambios`}
                </Button>,
              ]}
            >
                <div style={{
                    textAlign:"center",
                    width:'80%',
                    margin:'auto'
                }}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: 38,color:"#FF812E" }} />
                    <h2>{modalTitle}</h2>

                    <Form
                    form={form}
                    layout="vertical"
                    >
                        <Form.Item
                        label="Correo"
                        rules={[{required:true,message:'Debes ingresar un correo valido'}]}
                        name={'email'}
                        extra="Ingrese los correos electrónicos separados por coma. No ingrese espacios formato para añadir un correo es nombre de invitado <nombre@empresa.com>"
                        style={{
                            textAlign:"left"
                        }}>
                            <Input type={'email'} disabled={usuarioForm.id != ''} placeholder="descriptive name <emailname@empresa.com>" /* value={usuarioForm.email} onChange={(e) => updatedUsuarioObj('email',e.target.value)} */ />
                        </Form.Item>
                        <Form.Item
                        label="Rol" 
                        className="selector_rol"
                        name={'rol'}
                        initialValue={'1'} >
                            <Select  /*value={usuarioForm.rol ? `${usuarioForm.rol}`:`1`}  onChange={(e)=>updatedUsuarioObj('rol',e)}*/ >
                                <Option disabled key={`undefined`} value={`undefined`}>
                                    <ItemSelectorRol icon={0}  titulo={`Debes elegir un rol`} descripcion={""}/>
                                </Option>
                                {
                                    rolesOperadores.map( (e) => (
                                        <Option key={e.id} value={`${e.id}`}>
                                            <ItemSelectorRol icon={e.id}  titulo={e.name} descripcion={""}/>
                                        </Option>
                                    ))
                                }
                                {/* <Option key="operador">
                                    <ItemSelectorRol icon={"plataforma"}  titulo={"Operador de la flota"} descripcion={"Visualizar en tiempo real los datos de los vehículos"}/>
                                </Option> */}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
                
            </Drawer>
        </div>
    );
}


export default TablesOrganizaciones;