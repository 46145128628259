import React, { useState,useEffect } from 'react';
import { tarjetasParaRecarga } from '@utils/menus/main/itemsMenu';
import TablaTarjetas from '../../components/TableTarjetas';
import Breadcrumb from '@containers/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { apiConfigClientes } from '@utils/services/index';
import { Button, Card,Form, Input, Select, Drawer,message,Checkbox  } from 'antd';
import { apiTarjetas } from '@utils/services';
import { useSelector } from 'react-redux';
import { comprobar_permiso } from '@utils/auth/permissions';
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import { ExportToCsv } from 'export-to-csv';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
import './style.less';

const { Search } = Input;
const { Option } = Select;
const textLanguage = Spanish({screen:'cpo-rfid'})

const AdministrarTarjetasClientes = () => {
    const { name } = tarjetasParaRecarga;
    const breadcrumbData = [[null, name]];
    const user = useSelector((state) => state.user);
    const [ clientesSelect , setClientes ] = useState([]);
    const [ searchBar , setSearchBar ] = useState('');
    const [ client_filter , setClientFilter ] = useState(0);
    const [form] = Form.useForm();

    const [ formularioTarjetaState, setFormularioTarjetaState ] = useState({
        id:``,
        titulo:``,
        cliente:``,
        codigo:``,
        descripcion:``,
        active:true,
        visible:false,
    });
    const [ tableState , setTableState ] = useState({
        data:[
        // {
        //     key:1,
        //     id:1,
        //     fecha:"20/20/2020",
        //     cliente:"Celsia",
        //     descripcion:"test",
        //     codigo:`000`,
        //     estado:0,
        // },{
        //     key:2,
        //     id:2,
        //     fecha:"20/20/2020",
        //     cliente:"Celsia2",
        //     descripcion:"test",
        //     codigo:`000`,
        //     estado:1,
        // }
        ],
        dataRaw:[],
        loading:false,
    });

    

    const updateTableSate = ( changes ) => {
        let temp = Object.assign({},tableState);
        
        for(let i = 0 ; i < changes.length ; i++){
            const { tag, value } = changes[i];
            temp[`${tag}`] = value;
        }
        setTableState(temp);
    }

    const cargarClientes = () => {
        if(comprobar_permiso(user,5)){
            apiConfigClientes.clientes().then( (rslt) => {
                setClientes(rslt);
            }).catch( (err) => {
                console.log(err);
            });
        }
    }

    const updateFormularioTarjeta = ( changes ) => {
        let temp = Object.assign({},formularioTarjetaState);
        
        for(let i = 0 ; i < changes.length ; i++){
            const { tag, value } = changes[i];
            temp[`${tag}`] = value;
        }

        setFormularioTarjetaState(temp);
    }

    const cargarTarjetas = () => {
        updateTableSate([{tag:`loading`,value:true}]);
        apiTarjetas.obtener_tarjetas(false).then((rslt) =>{
            let temp = [];
            let tempRaw = [];
            
            updateTableSate([{tag:`loading`,value:false}]);
            for(let i = 0 ; i < rslt.length ; i++){
                let obj = rslt[i];
                tempRaw.push(obj);
                temp.push({
                    key:obj.id,
                    id:obj.id,
                    fecha:getLocalDateString(obj.created),
                    cliente:obj.client,
                    cliente_id:obj.client_id,
                    descripcion:obj.description,
                    codigo:obj.code,
                    estado:obj.is_active,
                });
            }
            updateTableSate([{tag:`dataRaw`,value:tempRaw},{tag:`data`,value:temp},]);
        }).catch((err) => {
            console.log(err);
            updateTableSate([{tag:`loading`,value:false}]);
        });
    }

    const obtener_tarjeta_raw = ( key ) => {
        let dataRaw = tableState.dataRaw;
        for(let i = 0 ; i < dataRaw.length ; i++){
            if(dataRaw[i].id == key) return dataRaw[i];
        }
    }

    const handleCancel = () => {
        updateFormularioTarjeta([{tag:'visible',value:false}]);
    }

    const handleNewCard = () => {
        console.log(tableState);
        
        updateFormularioTarjeta([
        {
            tag:'titulo',
            value:"Nueva tarjeta"
        },{
            tag:'visible',
            value:true
        },{
            tag:'id',
            value:''
        },{
            tag:'cliente',
            value: comprobar_permiso(user,5) ? '' : user.clientSelected.client_id
        },{
            tag:'codigo',
            value:''
        },{
            tag:'descripcion',
            value:''
        },{
            tag:'active',
            value:true
        }]);
        
    }

    const handleEditCard = ( record ) => {
        const tarjetaRaw = obtener_tarjeta_raw(record.id);
        console.log(tarjetaRaw);
        updateFormularioTarjeta([
            {
                tag:'titulo',
                value:"Editar tarjeta"
            },{
                tag:'visible',
                value:true
            },{
                tag:'id',
                value:record.id
            },{
                tag:'cliente',
                value: comprobar_permiso(user,5) ? tarjetaRaw.client_id : user.clientSelected.client_id
            },{
                tag:'codigo',
                value:record.code
            },{
                tag:'descripcion',
                value:record.description
            },{
                tag:'active',
                value:record.is_active
            }]);
    }


    const handleSwitchCard = ( record ) => {
        let temp = {
            id:record.id,
            is_active:!record.is_active
        }
        apiTarjetas.inactivar_tarjeta(temp).then((rslt) => {
            console.log(rslt);
            if(rslt && 'is_active' in rslt) message.success(textLanguage.RFID_CARDS_ACTIVE_INACTIVE({active:temp.is_active}));
            cargarTarjetas();
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleOk = () => {
        if(formularioTarjetaState.id == ''){
            let temp = {
                code:formularioTarjetaState.codigo,
                description:formularioTarjetaState.descripcion,
                is_active:formularioTarjetaState.active,
                client:parseInt(formularioTarjetaState.cliente),
            }
            console.log(temp);
            apiTarjetas.crear_tarjeta(temp).then((rslt) => {
                if(rslt.id){
                    message.success(textLanguage.RFID_CARDS_C_SUCCESS);
                }else{
                    message.error(textLanguage.RFID_CARDS_C_FAIL);
                }
                console.log(rslt);
                cargarTarjetas();
                handleCancel();
            }).catch((err) => {
                message.error(textLanguage.RFID_CARDS_C_FAIL);
                console.log(err);
            });
        }else{
            let temp = {
                id:formularioTarjetaState.id,
                code:formularioTarjetaState.codigo,
                description:formularioTarjetaState.descripcion,
                is_active:formularioTarjetaState.active,
                client:parseInt(formularioTarjetaState.cliente),
            }
            apiTarjetas.editar_tarjeta(temp).then((rslt) => {
                if(rslt.id)
                    message.success(textLanguage.RFID_CARDS_U_SUCCESS);
                cargarTarjetas();
                handleCancel();
            }).catch((err) => {
                message.error(textLanguage.RFID_CARDS_U_FAIL);
                console.log(err);
            });
        }   

    }

    const handleWhiteList = () => {
        console.log(user);
        apiTarjetas.enviar_lista_blanca({
            client_id: comprobar_permiso(user,5) ? client_filter : user.clientSelected.client_id
        }).then((rslt) => {
            console.log(rslt);
            message.info(`${rslt.result === 'ACCEPTED' ? textLanguage.RFID_CARDS_WHITE_REQUEST_SUCCESS : textLanguage.RFID_CARDS_WHITE_REQUEST_FAIL}`);
        }).catch((e) => {
            console.log(e);
        });
        
    }

    const handleDownload = () => {
        message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
        // apiTarjetas.obtener_tarjetas(true).then((rslt) =>{
        //     console.log(rslt);
        // }).catch((err) => {
        //     console.log(err);
        // });
        export_to_csv(null,tableState.data,'Tarjetas')
    }

    useEffect(() => {
        cargarTarjetas();
        cargarClientes();
    },[]);

    return(
        <div id="tarjetas_general">
            <Breadcrumb breadcrumbData={breadcrumbData} />

            <div className="tarjetas-table">
                <Card>
                    <div className='top_table'>
                        {
                            /**
                             * Por definir el permiso especifico
                             */
                            comprobar_permiso(user,5) && (
                                <Select defaultValue={client_filter != 0 ? client_filter : `Todos los clientes`}
                                    onChange={(e) => setClientFilter(e)}
                                    style={{ width: 220 }} >
                                        <Option key={0} value={`${0}`}>
                                            {'Todos los clientes'}
                                        </Option>
                                        { 
                                            clientesSelect.map( (e) => (
                                                <Option key={e.id} value={`${e.id}`}>
                                                    {e.name}
                                                </Option>
                                            ))
                                        }
                                </Select>
                            )
                            
                        }
                        

                        <Search placeholder="Buscar" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} style={{ width: 200 }} />
                        <Button onClick={ () => handleNewCard()} className="nueva_tarjeta_btn" type="primary" style={{
                            visibility:`${comprobar_permiso(user,4) ? 'visible' : 'hidden'}`
                        }}>Nueva tarjeta</Button>
                        <Button className="btn_lista_blanca" type="primary" onClick={handleWhiteList} disabled={false} >Enviar lista blanca</Button>
                        <Button type="default" onClick={handleDownload}>Descargar</Button>
                    </div>

                    <div className="tarjetas_body">
                        <TablaTarjetas user={user} handleSwitchCard={handleSwitchCard} handleEditCard={handleEditCard} dataRaw={tableState.dataRaw} 
                        data={(tableState.data.filter( obj => {
                            let keys = Object.keys(obj);

                            if(comprobar_permiso(user,5)){
                                if(client_filter != '0'){
                                    if(obj.cliente && `${obj.cliente_id}`.toLowerCase() == client_filter.toLowerCase()){
                                        for(let i = 0 ; i < keys.length ; i++){
                                            if(`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())){
                                                return true;
                                            }
                                        }
                                    }
                                }else{
                                    for(let i = 0 ; i < keys.length ; i++){
                                        if(`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())){
                                            return true;
                                        }
                                    }
                                }
                            }else{
                                if(obj.cliente && `${obj.cliente_id}`.toLowerCase() == user.clientSelected.client_id){
                                    for(let i = 0 ; i < keys.length ; i++){
                                        if(`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())){
                                            return true;
                                        }
                                    }
                                }
                            }
                            
                            
                            
                            return false;
                        }
                            
                            ))} loading={tableState.loading} />
                    </div>

                    <Drawer
                        style={{
                            boxSizing:'border-box',
                            height:'100%',
                            float:"right",
                            top:0,
                            padding:0,
                        }}
                        placement="right"
                        width={700}
                        placement="right"
                        onClose={handleCancel}
                        visible={formularioTarjetaState.visible}
                        footer={[
                            <Button key="Cancelar" onClick={handleCancel}>
                              Cancelar
                            </Button>,
                            <Button key="Enviar" type="primary" onClick={handleOk}>
                              {formularioTarjetaState.id == '' ? `Crear` : `Guardar`}
                            </Button>,
                        ]}
                    >
                        <div style={{
                            textAlign:"center",
                            width:'80%',
                            margin:'auto'
                        }}>
                            <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: 38,color:"#FF812E" }} />
                            <h2>{formularioTarjetaState.titulo}</h2>

                            <Form
                            form={form}
                            layout="vertical"
                            >
                                {
                                    comprobar_permiso(user,5) && (
                                        <Form.Item
                                        label="Cliente" className="selector_cliente" >
                                            
                                            <Select  value={formularioTarjetaState.cliente ? `${formularioTarjetaState.cliente}`:`Seleccione un cliente`}  onChange={(e)=>updateFormularioTarjeta([{tag:'cliente',value:e}])} >
                                                { 
                                                    clientesSelect.map( (e) => (
                                                        <Option key={e.id} value={`${e.id}`}>
                                                            {e.name}
                                                        </Option>
                                                    ))
                                                }    
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                                
                                <Form.Item
                                label="Código" 
                                required tooltip="This is a required field" 
                                extra="Necesario para iniciar la recarga de los cargadores"
                                style={{
                                    textAlign:"left"
                                }}>
                                    <Input placeholder="CD3_EJEMPLO" value={formularioTarjetaState.codigo} onChange={(e) => updateFormularioTarjeta([{tag:'codigo',value:e.target.value}])}/>
                                </Form.Item>
                                <Form.Item
                                label="Descripción" className="selector_rol" 
                                extra="Nombre para identificar la tarjeta mas facilmente"
                                style={{
                                    textAlign:"left"
                                }}>
                                    <Input placeholder="CARD_CD3_EJEMPLO" value={formularioTarjetaState.descripcion} onChange={(e) => updateFormularioTarjeta([{tag:'descripcion',value:e.target.value}])}/>
                                </Form.Item>
                                <Form.Item
                                className="check_active" 
                                style={{
                                    textAlign:"left"
                                }}
                                >
                                    <Checkbox checked={formularioTarjetaState.active} onChange={(e) => updateFormularioTarjeta([{tag:'active',value:e.target.checked}])}>ACTIVADA</Checkbox>
                                </Form.Item>
                            </Form>
                        </div>

                    </Drawer>
                </Card>
            </div>
        </div>
    );
}

export default AdministrarTarjetasClientes;