import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleActions, alertasActions, locationActions } from '@store/actions';
import io from 'socket.io-client';
import { getToken } from '@utils/auth/createAuthProvider';
import defaultSettings from '@utils/services/default';
import { message } from 'antd';

const useSocket = ( type, user ) => {
  const dispatch = useDispatch();

  const { user_clients: clients } = useSelector((state) => state.user);
  let stateSocket = useSelector((state) => state.socket);
  const { client_id: idClient = '' } = clients[0] || [];

  const socketConn = useCallback(() => {
    
    if(!stateSocket){
      stateSocket = io(defaultSettings.socket_default_endpoint);
      const room = `cid_${idClient}`;
      console.log(room);
      stateSocket.on('connect', async () => {
        const jwt = await getToken();
        console.log("connect");
        const auth = {
          room,
          jwt,
        };
        stateSocket.emit('join', auth);
      });
    } 

    stateSocket.on('message-room', (data) => {
      let json;
      console.log(data);
      try {
        json = JSON.parse(data);
      } catch (err) {
        if(`${data}`.startsWith('Socket')) return;
        json = data;
      }
      console.log(json);
      
      switch(json.event){
        case "OFFLINE":
          message.warning(`${json.id} Requiere tu atención\nEvento: Desconexión`);
          // dispatch(alertasActions.updateAlertas(json));
          /**
           * alerta para popup
           */
          break;
        case "CHANGE_STATUS":
          dispatch(locationActions.updateLocation(json));
          break; 
      }
    });

    stateSocket.on('error', (data) => {
      console.log(data);
      
    });

    // eslint-disable-next-line
  }, []);

  const fetchVehicles = useCallback(async () => {
    const getMapVehicles = () => dispatch(vehicleActions.getMapVehicles());
    const getMapVehiclesStatus = () => dispatch(vehicleActions.getMapVehiclesStatus());

    await Promise.all([getMapVehiclesStatus(), getMapVehicles()]);
    socketConn();

    // eslint-disable-next-line
  }, []);

  const fetchAlertas = useCallback(async () => {
    const getAlertas = () => dispatch(alertasActions.getAlertas(user));
    
    
    await Promise.all([getAlertas()]);
    socketConn();

    // eslint-disable-next-line
  }, []);

  const fetchEvses = useCallback( async () => {
    // const getAlertas = () => dispatch(alertasActions.getAlertas(user));
    const getEvses = () => dispatch(locationActions.getLocations(user));
    
    await Promise.all([getEvses()]);
    socketConn();
  },[]);

  useEffect(() => {
    fetchAlertas();
    fetchEvses();
    // eslint-disable-next-line
  }, []);
};

export default useSocket;

// socket.current.on('message', (data) => {
//   console.log('Incoming message:', data);
// });

// socket.current.on('message-all', (data) => {
//   console.log('Incoming message to all:', data);
// });
