import { apiVehicles2 } from '@utils/services';

import isVehicleDisconnect from '@utils/helpers/isVehicleDisconnect';
import mapVehiclesWithSocketData from '@utils/helpers/mapVehiclesWithSocketData';
import constants from './constants';

function fetchVehicles() {
  const {
    VEHICLES_FETCHING: fetching,
    VEHICLES_ERROR: error,
    VEHICLES_SUCCESS: success,
  } = constants;

  const initState = { vehiclesData: null, vehiclesError: null, vehiclesLoading: false };

  return (dispatch, getState) => {
    const { vehiclesData: data } = getState().mapView;
    if (data) return;

    dispatch({
      type: fetching,
      payload: { ...initState, vehiclesLoading: true },
    });

    apiVehicles2
      .getAllVehicles()
      .then((result) => {
        const vehiclesData = mapVehiclesWithSocketData(result);
        dispatch({
          type: success,
          payload: { ...initState, vehiclesData },
        });
      })
      .catch((vehiclesError) => {
        dispatch({
          type: error,
          payload: { ...initState, vehiclesError },
        });
      });
  };
}

function setFocusVehicle(focusVehicle) {
  const { FOCUS_VEHICLE } = constants;
  return (dispatch, getState) => {
    const { focusVehicle: focusVeh } = getState().mapView;
    if (focusVeh) {
      dispatch({ type: FOCUS_VEHICLE, payload: { focusVehicle: null } });
      setTimeout(() => dispatch({ type: FOCUS_VEHICLE, payload: { focusVehicle } }), 100);
      return;
    }
    dispatch({ type: FOCUS_VEHICLE, payload: { focusVehicle } });
  };
}

function updateBySocket(vehicleData) {
  const { UPDATE_VEHICLE_BY_SOCKET } = constants;
  return (dispatch, getState) => {
    const { vehiclesData: lastVehiclesData } = getState().mapView;
    if (!lastVehiclesData) return;

    const { lic } = vehicleData;
    const vehiclesData = { ...lastVehiclesData, [lic]: vehicleData };

    dispatch({
      type: UPDATE_VEHICLE_BY_SOCKET,
      payload: { vehiclesData },
    });
  };
}

function setPieStatusSelected(pieStatusSelected) {
  const { PIE_STATUS_SELECTED } = constants;
  return {
    type: PIE_STATUS_SELECTED,
    payload: { pieStatusSelected },
  };
}

function checkVehiclesConnection() {
  const { UPDATE_VEHICLES_STATUS } = constants;

  return (dispatch, getState) => {
    const { vehiclesData } = getState().mapView;
    if (!vehiclesData) return;

    const disconnectedVehicles = Object.values(vehiclesData).filter(isVehicleDisconnect);
    if (disconnectedVehicles.length <= 0) return;

    const updateVehiclesStatus = {};
    disconnectedVehicles.forEach((item) => {
      const { lic } = item;
      updateVehiclesStatus[lic] = { ...item, sta: 'BAD' };
    });

    const newVehiclesData = { ...vehiclesData, ...updateVehiclesStatus };

    dispatch({
      type: UPDATE_VEHICLES_STATUS,
      payload: { vehiclesData: newVehiclesData },
    });
  };
}

export default {
  fetchVehicles,
  setFocusVehicle,
  updateBySocket,
  setPieStatusSelected,
  checkVehiclesConnection,
};
