import React, { useState } from 'react';
import { Typography, Card, Table, Tabs } from 'antd';
import { Link,  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconDeletePopup } from '@components/ui';
import { useSelector } from 'react-redux';
import { comprobar_permiso } from '@utils/auth/permissions';
const { Paragraph } = Typography;
const { TabPane } = Tabs;



const TablaClientes = ( props ) => {

    const [pageSize, setPageSize] = useState(10);
    const user = useSelector((state) => state.user);
    const { refreshData, data, loading, editarCliente, eliminarCliente } = props;

    const onChangeDateRange = (/* start,end */) => {

    };

    const columns = [
        {
            title:'NOMBRE',
            dataIndex:'nombre',
            key:'nombre',
            defaultSortOrder: 'ascend',
            sorter:(a,b) => a.nombre.localeCompare(b.nombre),
        },
        {
            title:'NIT',
            dataIndex:'nit',
            key:'nit',
            sorter:(a,b) => a.nit - b.nit,
        },
        {
            title:'TELÉFONO',
            dataIndex:'telefono',
            key:'telefono',
            sorter:(a,b) => a.telefono - b.telefono,
        },
        {
            title:'CORREO ELECTRONICO',
            dataIndex:'correo_electronico',
            key:'correo_electronico',
            sorter:(a,b) => a.correo_electronico.localeCompare(b.correo_electronico),
        },
        {
            title:'PAÍS',
            dataIndex:'pais',
            key:'pais',
            sorter:(a,b) => a.pais.localeCompare(b.pais),
        },
        {
            title:'CIUDAD',
            dataIndex:'ciudad',
            key:'ciudad',
            sorter:(a,b) => a.ciudad.localeCompare(b.ciudad),
        },{
            title: 'ESTADO',
            dataIndex: 'estado',
            sorter: (a,b) => a.estado - b.estado,
            render: ( text , record, index ) => {
                return(
                    <IconDeletePopup icon={text == 1 ? faCheck : faTimes} 
                    style={{ 
                        fontSize: 20,
                        color:text == 1 ? "#FF812E" : "gray",
                        cursor:`${comprobar_permiso(user,17) ? 'pointer' : 'arrow'}`,
                        pointerEvents:`${comprobar_permiso(user,17) ? 'all' : 'none'}`,
                    }} 
                    eliminarCliente={eliminarCliente} 
                    title={text == 1 ? `¿Estas seguro que deseas desactivarlo?` : `¿Estas seguro que deseas activarlo?`} 
                    record={record} />
                );
            }
        },
        {
            title:'ACCIONES',
            dataIndex:'acciones',
            key:'acciones',
            render: (text, record, index) => {
                return (
                <div style={{
                    display:"flex",
                    justifyContent:"space-around",
                }}>
                    <Link to={{ pathname: `/configuracion-cuenta/${record.nombre}/usuarios` , id:record.key }}><p style={{ fontSize: 16,color:"#FF812E",margin:0 }} >Administrar usuarios</p></Link>
                    <FontAwesomeIcon onClick={ () => editarCliente(record)} 
                    icon={faPen} 
                    style={{ fontSize: 20,color:"#FF812E", cursor:"pointer",visibility:`${comprobar_permiso(user,2) ? `visible` : `hidden`}`, }} 
                    />
                    {/* <IconDeletePopup eliminarCliente={eliminarCliente} record={record}/> */}
                </div>
                )
            }
        },
    ]

    const onChange = (page, pageSize) => {
        setPageSize(pageSize);
    };

    return(
        <Card>
            <h4>Organizaciones</h4>
            <div className="filtros-tabla">
                <Table
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                loading={loading}
                pagination={{
                pageSize,
                pageSizeOptions: [10, 20, 50],
                showSizeChanger: true,
                onChange:onChange,
                }} />
            </div>
        </Card>
    );
}

export default TablaClientes;