import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import { useRouteMatch } from 'react-router-dom';
import { Card, Space, message, Row, Col,Tabs } from 'antd';

import Breadcrumb from '../../../../containers/Breadcrumb';

import { ChargerInfo,CardSendCommand } from './containers';
import { apiLocations } from '@utils/services/cpo';
import { ConnectorInfoItem,TablaConectores,TablaRecargas } from './components';
import { Spanish } from '@utils/helpers/dictIdiomas'
const { TabPane } = Tabs;

const CargadorView = (props) => {
  const textLanguage = Spanish({screen:'evse-view'})
  const {
    match: {
      params: { cargadorId },
    },
  } = props;

  const { path } = useRouteMatch();
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const splitPath = path.split("/");
  const breadcrumbData = [
    [null, 'Mis cargadores'],
    [null, `${cargadorId}`],
  ];

  const [evseData, setevseData] = useState([]);
  const [connectorsData, setconnectorsData] = useState({
    data:[],
    loading:false,
  });

  const date_formate = ( date ) => {
      let dateTemp = new Date(date);
      let dateUTC = new Date(Date.UTC(dateTemp.getFullYear(),dateTemp.getMonth(),dateTemp.getDate(),dateTemp.getHours(),dateTemp.getMinutes()));
      return `${dateUTC.getDate()} ${months[dateUTC.getMonth()].substring(0,3).toLowerCase()} ${dateUTC.getFullYear()} ${dateUTC.getHours()}:${dateUTC.getMinutes()}`;
  }

  useEffect(() => {
    setconnectorsData({...connectorsData,loading:true});
    apiLocations
      .getEvse(cargadorId)
      .then(data => {
        let evseData = [{
          name: 'id',
          value: data.uid,
        },
        {
          name: 'Estación',
          value: data.connectors[0].evse.evse_location_name,
        },
        {
          name: 'Uso',
          value: data.type_use,
        },
        {
          name: 'Operador',
          value: data.operator,
        },
        {
          name: 'Marca',
          value: `${data.evse_brand}`,
        },
        
        {
          name: 'Modelo',
          value: `${data.evse_model} `,
        },
        {
          name: 'Ubicación',
          value: data.address,
        },
        {
          name: 'Última petición recibida',
          value: date_formate(data.last_updated),
        }
        ];
        let connectorsData = data.connectors;
        setevseData(evseData);
        setconnectorsData({loading:false,data:connectorsData});
      })
      .catch((error) => {
        message.error(textLanguage.EVSE_EVSE_R_FAIL({name:cargadorId}));
        setconnectorsData({...connectorsData,loading:true});
      });
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <Breadcrumb breadcrumbData={breadcrumbData} />

      <Row style={{display:'flex',justifyContent:'center'}}>
        <Col sm={24} md={24} lg={22} xl={20} xxl={18}  >
          <Row className={'cargador-row-container'} gutter={[24, 16]} >
            <Col span={16}>
              <ChargerInfo className="charger-info" evseData={evseData} />
            </Col>
            <Col span={8}>
              <CardSendCommand 
              className="card-info" 
              evseId={cargadorId} 
              evseConnectors={connectorsData.data} />
            </Col>

            <Col span={24}>
              <Tabs key={'tabs-vista'} defaultActiveKey={"conectores"}>
                <TabPane tab="Conectores" key="conectores">
                  <TablaConectores loading={connectorsData.loading} conectores={connectorsData.data} />
                </TabPane>
                <TabPane tab="Recargas" key="recargas">
                  <TablaRecargas cargadorId={cargadorId}/>
                </TabPane>
                <TabPane tab="Logs" key="logs" disabled>
                </TabPane>
                <TabPane tab="Recarga en curso" key="recargacurso" disabled >
                </TabPane>
              </Tabs>
            </Col>
            
          </Row>
        </Col>
      </Row>
      
    </>
  );
};

CargadorView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cargadorId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default CargadorView;
