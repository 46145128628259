import mapVehiclesWithSocketDate from '@utils/helpers/mapVehiclesWithSocketData';

import { apiVehicles } from '../../utils/services';
import vehicleConst from '../constants/vehiclesConst';

const getMapVehicles = () => async (dispatch, getState) => {
  const state = getState();
  const {
    MAP_FETCHING_VEHICLES: fetching,
    MAP_SUCCESS_VEHICLES: success,
    MAP_ERROR_VEHICLES: error,
  } = vehicleConst;

  let response;
  dispatch({ type: fetching });
  try {
    const nextPage = state.vehicles.mapNextPage;
    response = await apiVehicles.vehicles(nextPage);
  } catch (err) {
    return dispatch({ type: error, payload: err });
  }

  const { results, next } = response;

  const dict = mapVehiclesWithSocketDate(results);

  const currentVehicles = state.vehicles.mapVehicles;

  const payload = { mapVehicles: { ...currentVehicles, ...dict }, mapNextPage: next };
  return dispatch({ type: success, payload });
};

const updateMapVehicle = (json) => (dispatch, getState) => {
  const { isReadyToSocket } = getState().vehicles;

  if (!isReadyToSocket) return;

  const { MAP_UPDATE_VEHICLE: update } = vehicleConst;

  const { lic, ...rest } = json;
  const newVehicle = { [lic]: { lic, ...rest } };

  dispatch({ type: update, payload: newVehicle });
};

const getMapVehiclesStatus = () => async (dispatch) => {
  const {
    MAP_FETCH_GRAPH: fetching,
    MAP_SUCCESS_GRAPH: success,
    MAP_ERROR_GRAPH: error,
  } = vehicleConst;

  let response;
  dispatch({ type: fetching });
  try {
    response = await apiVehicles.vehiclesStatus();
  } catch (err) {
    return dispatch({ type: error, payload: err });
  }

  const newResponse = {};
  Object.keys(response).forEach((key) => {
    const value = response[key];
    newResponse[key] = new Set(value);
  });

  return dispatch({ type: success, payload: newResponse });
};

const updateMapChart = (values) => (dispatch, getState) => {
  const { MAP_UPDATE_GRAPH: update } = vehicleConst;

  const state = getState();
  const { mapGraph } = state.vehicles;
  const clone = {}; // JSON.parse(JSON.stringify(mapGraph));
  Object.entries(mapGraph).forEach(([key, value]) => {
    clone[key] = new Set(value);
  });

  const dicDefinitions = {
    ON: 'total_on',
    OFF: 'total_off',
    CHA: 'total_chargin',
  };

  let runDispatch = false;

  values.forEach((item) => {
    const { lic, sta } = item;
    Object.keys(clone).forEach((key) => {
      const vehicleList = clone[key];
      if (vehicleList.has(lic) && dicDefinitions[sta] !== key) {
        clone[key].delete(lic);
        clone[dicDefinitions[sta]].add(lic);
        runDispatch = true;
      }
    });
  });

  if (runDispatch) dispatch({ type: update, payload: clone });
};

const trackingVehicle = (lic) => (dispatch, getState) => {
  const { MAP_TRACKING_VEHICLE: tracking } = vehicleConst;
  const state = getState();
  const { vehicleTracking } = state.vehicles;

  if (vehicleTracking === lic) {
    return dispatch({ type: tracking, payload: null });
  }

  return dispatch({ type: tracking, payload: lic });
};

const focusVehicle = (dataVehicle) => (dispatch) => {
  const { FOCUS_VEHICLE } = vehicleConst;
  return dispatch({ type: FOCUS_VEHICLE, payload: dataVehicle });
};

const readyToUseSocket = (isReady) => (dispatch) => {
  const { READY_USE_SOCKET: socket } = vehicleConst;
  return dispatch({ type: socket, payload: isReady });
};

export default {
  getMapVehicles,
  updateMapVehicle,
  getMapVehiclesStatus,
  updateMapChart,
  trackingVehicle,
  focusVehicle,
  readyToUseSocket,
};
