import React, { useEffect, useState } from 'react';
import { Space, Typography, Statistic, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { apiGeneral } from '@utils/services/cpo';

const { Paragraph } = Typography;

const DataItem = (props) => {
  const { title, value } = props;

  return (
    <div className="data-item">
      <Paragraph>{title}</Paragraph>
      <Statistic value={value} />
    </div>
  );
};

DataItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const CommonDivider = () => <Divider type="vertical" style={{ height: 40 }} />;

const ResumenGeneral = () => {
  const user = useSelector((state) => state.user);
  const [state, setState] = useState({ general: [] });


  const cargarData = () => {
    setState({ ...state });
    apiGeneral
      .general(user.user_id)
      .then((general) => {
        setState({ ...state, general });        
      })
      .catch((error) => {
        setState({ ...state, isFething: false });
      });
  }

  useEffect(() => {
    cargarData();

    setInterval(cargarData,20000);
    // eslint-disable-next-line
  }, []);

  const { general } = state;
  return (
    <div className="resumen-general">
      <Space size="large">
        <DataItem title="Total estaciones" value={general.total_locations ? general.total_locations  : 0} />
        <CommonDivider />
        <DataItem title="Total en cargadores" value={general.total_evses ? general.total_evses : 0} />
        <CommonDivider />
        <DataItem title="Disponibles" value={general.evses_available ? general.evses_available : 0} />
        <CommonDivider />
        <DataItem title="Fuera de Servicio" value={general.evses_out_service ? general.evses_out_service : 0} />
        <CommonDivider />
        <DataItem title="Error" value={general.evses_error ? general.evses_error : 0} />
      </Space>
    </div>
  );
};

export default ResumenGeneral;
