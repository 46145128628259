import moment from 'moment';

function isVehicleDisconnect(vehicleItem) {
  const { ts, sta } = vehicleItem;
  if (!ts || sta === 'BAD') return true;
  const date = moment(ts);
  if (!date.isValid()) return false;
  const diff = moment().diff(date, 'hours');
  return diff >= 1;
}

export default isVehicleDisconnect;
