import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useDataChart() {
  const vehiclesData = useSelector(({ mapView }) => mapView.vehiclesData);
  const loading = useSelector(({ mapView }) => mapView.vehiclesLoading);
  const [dataPie, setDataPie] = useState({ data: null });

  useEffect(
    () => {
      if (!vehiclesData) return;

      const initData = { ON: 0, OFF: 0, CHA: 0, BAD: 0 };

      const countData = Object.values(vehiclesData).reduce((acc, current) => {
        const { sta } = current;
        const newCount = acc[sta] + 1;
        return { ...acc, [sta]: newCount };
      }, initData);

      const data = [
        {
          sta: 'ON',
          name: 'Encendido',
          value: countData.ON,
          color: '#52c41a',
        },
        {
          sta: 'CHA',
          name: 'Cargando',
          value: countData.CHA,
          color: '#EE8119',
        },
        {
          sta: 'OFF',
          name: 'Apagado',
          value: countData.OFF,
          color: '#F4272B',
        },
        {
          sta: 'BAD',
          name: 'Sin conexión',
          value: countData.BAD,
          color: '#141414',
        },
      ];

      const total = Object.keys(vehiclesData).length;
      const colors = data.map(({ color }) => color);

      setDataPie({ data, total, colors });

      // console.log(data);
    },
    //
    [vehiclesData]
  );

  return { loading, dataPie };
}

export default useDataChart;
