import React from 'react';
import { Typography, Tabs } from 'antd';
import PropTypes from 'prop-types';

import logoCompuestoOrange from '@assets/img/logo_compuesto_orange.png';
import history from '@utils/history';

import LoginForm from './containers/LoginForm';
import './style.less';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const onChange = (key) => {
  switch (key) {

    case '/ingreso-cpo':
      history.push('/ingreso-cpo');
      break;

    default:
      break;
  }
};

const Sign = (props) => {
  const {
    location: { pathname },
  } = props;

  return (
    <div id="login">
      <div className="content">
        <section>
          <div>
            <header>
              <img width={200} src={logoCompuestoOrange} alt="Logo" />

              {/* <Title className="content__logotype" level={2}>
                CELSIA
              </Title> */}
              {/* <Title className="content__hashtag" level={4}>
                #Movilidad inteligente
              </Title> */}
              <Tabs className="tabs" activeKey={pathname} size="large" onChange={onChange} centered>
                <TabPane key="/ingreso-cpo" tab="Charge Point Operator">
                  <LoginForm />
                </TabPane>
              </Tabs>
            </header>
          </div>
        </section>

        <footer>
          <Paragraph>© Copyright 2016 Celsia S.A. E.S.P. - Medellín, Colombia</Paragraph>
        </footer>
      </div>
    </div>
  );
};

Sign.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Sign;
