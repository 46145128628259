import InProgressView from '../../../../views/InProgressView';

const path = '/configuracion-sitio/perfil';

const perfilMenu = {
  path,
  name: 'Perfil',
  icon: null,
  component:InProgressView,
  key: 'config-sitio-perfil',
  children: [],
};

export default perfilMenu;