import React, { useEffect, useState } from 'react';
import { Row,Col,Card, Table, Button,Popconfirm } from 'antd';
import './style.less';
import { faEdit, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormularioPerfil } from './components';
import { apiProfiles } from '@utils/services/cpo'
const Perfiles = () => {
    const [ formPerfilVisible, setFormPerfilVisible ] = useState(false);
    const [ profiles, setProfiles ] = useState({
        selected:[],
        editProfile:null,
        data:[
            // {
            //     key: '1',
            //     profile: 'A toda mecha',
            //     unit: 'Amperios',
            //     value: '3',
            // },
        ],
        loading:false,
    });
    const columns = [
        {
            title: 'Perfiles',
            dataIndex: 'name',
            key: 'name',
            filters:profiles.data.map( (e) => ({text:e.name,value:e.name})),
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Unidad',
            dataIndex: 'unit_name',
            key: 'unit',
            sorter: (a, b) => (a.unit < b.unit) ? 1 : (a.unit > b.unit) ? -1 : 0,
        },
        {
            title: 'Valor max',
            dataIndex: 'value',
            key: 'value',
            sorter: (a, b) => a.value - b.value,
            render: (v,e) => {
                return(
                    <p>{`${e.value} (${e.unit})`}</p>
                )
            }
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            render:(v,e) => {
                return (
                    <div>
                        <FontAwesomeIcon onClick={() => handleUpdateAction(v,e)} className={'style_action'}  icon={faPen}  />
                        <span className={'style_divider'}></span>
                        <Popconfirm 
                            placement="left"
                            title={`¿Deseas eliminar\n${e.name}?`}
                            onConfirm={() => handleDeleteAction(e)}
                            okText="Si"
                            cancelText="Cancelar"
                        >
                            <FontAwesomeIcon className={'style_action'}  icon={faTrash} />
                        </Popconfirm>
                        
                    </div>
                )
            }
        },
    ]
    

    useEffect(() => {
        loadProfiles();
    },[]);

    const handleNuevoPerfilClick = () => setFormPerfilVisible(true);

    const handleHidePerfilForm = () => setFormPerfilVisible(false);

    const loadProfiles = () => {
        setProfiles({...profiles,loading:true});
        apiProfiles.getProfiles().then( (rslt) => {
            console.log(rslt);
            setProfiles({selected:[],data:rslt.map((v,i)=> {
                let temp = v;
                temp.key = v.name;
                return temp;
            }),loading:false});
        }).catch( (err) => {
            setProfiles({...profiles,loading:false});
            console.log(err);
        });
    }

    const handleUpdateAction = ( obj,extra ) => {
        console.log(obj,extra);
        setFormPerfilVisible(true);
        setProfiles({
            ...profiles,
            editProfile:extra,
        });
    }

    const handleDeleteAction = ( obj ) => {
        apiProfiles.deleteProfile({
            id:obj.id
        }).then( (rslt) => {
            console.log(rslt);
            loadProfiles();
        }).catch( (err) => {
            setProfiles({...profiles,loading:false});
            console.log(err);
        });
    }

    const handleOnSelectChange = ( selectedRowKey ) => {
        console.log(selectedRowKey);
        setProfiles({...profiles,selected:selectedRowKey});
    }

    return(
        <Row className={'container-perfiles'}>
                
            <Col span={24}>
                <Button className={'btn-nuevo_perfil'} onClick={handleNuevoPerfilClick} type={'primary'} icon={<FontAwesomeIcon icon={faPlus} className={'btn-icon'} />} >Nuevo perfil</Button>
            </Col>
            <Col span={24}>
                <Card className={'card-table'}>
                    <Table  
                    rowSelection={{
                        selectedRowKeys:profiles.selected,
                        onChange:handleOnSelectChange
                    }}
                    loading={profiles.loading} 
                    dataSource={profiles.data} 
                    className={'perfiles-table'} 
                    columns={columns} />
                </Card>
            </Col>

            <FormularioPerfil editProfile={profiles.editProfile} loadProfiles={loadProfiles} visible={formPerfilVisible} handleHidePerfilForm={handleHidePerfilForm} />
        </Row>
    );
}


export default Perfiles;