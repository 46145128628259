/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Marker from '@components/mapa/Marker';

function MarkerOnMap({ map }) {
  const vehicleData = useSelector(({ vehicleView }) => vehicleView.vehicleData);

  useEffect(
    () => {
      if (!map || !vehicleData) return;

      const { lat, lon: lng } = vehicleData;
      const coords = new google.maps.LatLng({ lat, lng });
      map.panTo(coords);
    },
    //
    [vehicleData, map]
  );

  return <>{vehicleData ? <Marker item={vehicleData} activeInfo /> : null}</>;
}

MarkerOnMap.propTypes = {
  map: PropTypes.objectOf(PropTypes.any),
};

MarkerOnMap.defaultProps = {
  map: null,
};

export default MarkerOnMap;
