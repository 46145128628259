import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const CardTemplate = (props) => {
  const { className, title, children, loading } = props;

  return (
    <Card
      loading={loading}
      className={className}
      title={title}
      bodyStyle={{ overflow: 'hidden', height: '100%' }}
    >
      {children}
    </Card>
  );
};

CardTemplate.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

CardTemplate.defaultProps = {
  className: '',
  title: null,
  children: null,
  loading: false,
};

export default CardTemplate;
