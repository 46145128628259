// import { post, get } from './management';

// const createJwt = (body) => post('/auth/jwt/create/', body);
// const updateJwt = (body) => post('/auth/jwt/refresh/', body);
// const getUserData = () => get('/account/users/me/', true);

// export default { createJwt, updateJwt, getUserData };

import { baseUrl, baseRequest } from './urls';

function createJwt(body) {
  const endpoint = `${baseUrl}/auth/jwt/create/`;
  return baseRequest({ endpoint, body, method: 'POST' });
}

function updateJwt(body) {
  const endpoint = `${baseUrl}/auth/jwt/refresh/`;
  return baseRequest({ endpoint, body, method: 'POST' });
}

function getUserData() {
  const endpoint = `${baseUrl}/account/users/me/`;
  return baseRequest({ endpoint, requireAuth: true });
}

function resetPassword(body) {
  const endpoint = `${baseUrl}/account/users/reset_password/`;
  return baseRequest({ endpoint, body, method: 'POST' });
}

function resetPasswordConfirm(body) {
  const endpoint = `${baseUrl}/account/users/reset_password_confirm/`;
  return baseRequest({ endpoint, body, method: 'POST' });
}

export default { createJwt, updateJwt, getUserData, resetPassword, resetPasswordConfirm };
