import vehiclesConst from '../constants/vehiclesConst';

const {
  MAP_FETCHING_VEHICLES,
  MAP_SUCCESS_VEHICLES,
  MAP_ERROR_VEHICLES,
  MAP_UPDATE_VEHICLE,
  MAP_FETCH_GRAPH,
  MAP_SUCCESS_GRAPH,
  MAP_ERROR_GRAPH,
  MAP_UPDATE_GRAPH,
  MAP_TRACKING_VEHICLE,
  FOCUS_VEHICLE,
  READY_USE_SOCKET,
} = vehiclesConst;

const initState = {
  mapVehicles: {},
  mapNextPage: null,
  mapVehiclesFetching: false,
  mapVehiclesError: null,
  mapGraphFetching: false,
  mapGraph: null,
  mapGraphError: null,
  lastVehicleUpdated: null,
  vehicleTracking: null,
  vehicleFocus: null,
  isReadyToSocket: false,
};

const vehiclesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MAP_FETCHING_VEHICLES:
      return {
        ...state,
        mapVehiclesFetching: true,
      };

    case MAP_ERROR_VEHICLES:
      return {
        ...state,
        mapVehiclesError: payload,
        mapVehiclesFetching: false,
      };

    case MAP_SUCCESS_VEHICLES:
      return {
        ...state,
        ...payload,
        mapVehiclesFetching: false,
      };

    case MAP_UPDATE_VEHICLE:
      return {
        ...state,
        lastVehicleUpdated: payload,
        mapVehicles: { ...state.mapVehicles, ...payload },
      };

    case MAP_FETCH_GRAPH:
      return {
        ...state,
        mapGraphFetching: true,
      };

    case MAP_ERROR_GRAPH:
      return {
        ...state,
        mapGraphFetching: false,
        mapGraphError: payload,
      };

    case MAP_SUCCESS_GRAPH:
      return {
        ...state,
        mapGraphFetching: false,
        mapGraph: payload,
      };

    case MAP_UPDATE_GRAPH:
      return {
        ...state,
        mapGraph: payload,
      };

    case MAP_TRACKING_VEHICLE:
      return {
        ...state,
        vehicleTracking: payload,
      };

    case FOCUS_VEHICLE:
      return {
        ...state,
        vehicleFocus: payload,
      };

    case READY_USE_SOCKET:
      return {
        ...state,
        isReadyToSocket: payload,
      };

    default:
      return state;
  }
};

export default vehiclesReducer;
