import { useEffect, useCallback, useState } from 'react';
import moment from 'moment';

import { apiReportes } from '@utils/services';

function useRequestJourneyCharges(placa = null) {
  const [reqState, setReqState] = useState({
    journeys: null,
    charges: null,
    error: null,
    loading: false,
    dateRange: null,
  });

  const request = useCallback(
    () => {
      if (!placa) return;

      const start = moment().startOf('day');
      const end = moment().endOf('day');

      const dateRange = [start, end];
      const lics = [placa];

      const journeysReq = apiReportes.getJourneys({ dateRange, lics });
      const chargesReq = apiReportes.getCharges({ dateRange, lics });

      setReqState({ ...reqState, loading: true });
      Promise.all([journeysReq, chargesReq])
        .then(([journeys, charges]) => {
          setReqState({ ...reqState, journeys, charges, dateRange });
        })
        .catch((error) => setReqState({ ...reqState, error }));
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      request();
    },
    // eslint-disable-next-line
    []
  );

  return { ...reqState };
}

export default useRequestJourneyCharges;
