import React, { useState } from 'react';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDeletePopup } from '@components/ui';
import { faPen, faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';
import { comprobar_permiso } from '@utils/auth/permissions';

const TableTarjetas = ( props ) => {
    const { data, loading, dataRaw, handleEditCard, handleSwitchCard, user  } = props;
    const [ selectedData, setSelected ] = useState([]);
    
    const columns = [
      {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder:'descend',
          sorter: (a,b) => a.id - b.id,
      },
      {
          title: 'FECHA DE CREACIÓN',
          dataIndex: 'fecha',
      },
      {
          title: 'CLIENTE',
          dataIndex: 'cliente',
      },
      {
          title: 'DESCRIPCIÓN',
          dataIndex: 'descripcion',
      },
      {
          title: 'CÓDIGO',
          dataIndex: 'codigo',
      },
      {
          title: 'ESTADO',
          dataIndex: 'estado',
          render: ( text , record, index ) => {
              return(
                  <IconDeletePopup icon={text == 1 ? faCheck : faTimes} style={{ 
                    fontSize: 20,
                    color:text == 1 ? "#FF812E" : "gray",
                    cursor:`${comprobar_permiso(user,18) ? 'pointer' : 'arrow'}`,
                    pointerEvents:`${comprobar_permiso(user,18) ? 'all' : 'none'}`,
                  }} title={text == 1 ? `¿Estas seguro que deseas desactivarlo?` : `¿Estas seguro que deseas activarlo?`} record={record} eliminarCliente={() => handleSwitchCard(obtener_tarjeta_raw(record.key))}/>
              );
          }
      },
  ];

  if(comprobar_permiso(user,18)) columns.push(
      {
          title: 'ACCIONES',
          dataIndex: 'acciones',
          render: ( text, record, index ) => {
              return(
                  <div style={{
                      display:"flex",
                      justifyContent:"space-around",
                  }}>
                      <FontAwesomeIcon icon={faPen} onClick={() => handleEditCard(obtener_tarjeta_raw(record.key))} style={{ fontSize: 20,color:"#FF812E",cursor:"pointer" }} />
                      
                  </div>
              );
          }
      },
  );


  const onSelectChange = selectedRowKeys => {
    console.log(dataRaw);
      setSelected({ selectedRowKeys });
  };
  
  const obtener_tarjeta_raw = ( key ) => {
    console.log(dataRaw);
    for(let i = 0 ; i < dataRaw.length ; i++){
        if(dataRaw[i].id == key) return dataRaw[i];
    }
  }

  const rowSelection = {
      selectedData,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            setSelected(newSelectedRowKeys);
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            setSelected(newSelectedRowKeys);
          },
        },
      ],
    };

    

    return (
        <Table  rowSelection={rowSelection} columns={columns} dataSource={data} loading={loading}/>
    );
}

export default TableTarjetas;