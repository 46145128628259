import React, { useState, useCallback, useEffect } from 'react';
import { Button, Form, Input, Select, Row, Col, Divider, Affix, Modal, message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { apiLocations, } from '@utils/services/cpo';
import { faArrowsAlt, faPlug,faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {
    AimOutlined,
    CloseOutlined,
    PlusOutlined,
    UserOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spanish } from '@utils/helpers/dictIdiomas'
const FormularioEvse = ( props ) => {
    const textLanguage = Spanish({screen:'evse-form'})
    const { 
        dataEvseForm, modifyFormSend,setDataEvseForm,
        setModalVisible, evseMapState, setModalBrandVisible,setModalModelVisible,
        conectoresMapState,setConectoresMapState,
        conectores,setConectores,updateConector,
        locations,locationsLoading,cargadorId
    } = props;
    const { Option } = Select;
    const { user, locations:locationsItems } = useSelector((state) => state);
    const [ evseObj , setEvseObj ] = useState({
        data:null,
        loading:false
    });
    const [ firstSetting , setFirstSetting ] = useState(false);
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const requiredSettings = { 
        required: true,
        message:'Campo obligatorio',
    }
    
    const [ brands, setBrands ] = useState({
        data:[],
        loading:false   
    });
    const [ models, setModels ] = useState({
        data:[],
        loading:false,      
    });
    

    useEffect( () => {
        cargarBrands();
        cargarAllLocations();
    },[]);

    useEffect( () => {
        let brandExist = false;
        for(let i = 0 ; i < brands.data.length ; i ++){
            let brandTemp = brands.data[i];
            if(brandTemp.id == dataEvseForm.evse_brand){
                brandExist = true;
            }
        }
        if(!brandExist){
            cargarBrands();
            cargarModelos(dataEvseForm.evse_brand);
        } else{
            if(dataEvseForm.evse_brand != 0) {
                if(!cargadorId){
                    setDataEvseForm({...dataEvseForm,evse_model:0});
                }else if(cargadorId && firstSetting){
                    setDataEvseForm({...dataEvseForm,evse_model:0});
                }
                cargarModelos(dataEvseForm.evse_brand);
                setFirstSetting(true);
            }
        }

        
        
    },[dataEvseForm.evse_brand]);

    useEffect( () => {
        let modelExist = false;
        for(let i = 0 ; i < models.data.length ; i++){
            let modelTemp = models.data[i];
            if(modelTemp.id == dataEvseForm.evse_model){
                modelExist = true;
                break;
            }
        }

        if(!modelExist) cargarModelos(dataEvseForm.evse_brand);
    },[dataEvseForm.evse_model]);


    const cargarAllLocations = () => {
        if(cargadorId){
            setEvseObj({...evseObj,loading:true});
            apiLocations
            .allLocations(user.user_id)
            .then((info) => {
                console.log(info);
                cargarInfoEvseEdit(info)
            })
            .catch((error) => {
                setEvseObj({...evseObj,loading:false});
                message.error(textLanguage.EVSE_LOCATION_FORM_R_FAIL);
            });
        }
    }

    const cargarInfoEvseEdit = ( evse ) => {
        console.log(evse);
        let evseEdit = null;
            
        const evseConectors = evse.filter((v) => {
            if(v.evses){
                const evseTemp = v.evses.filter((l)=>{
                    if(l.evse_id == cargadorId){
                        evseEdit = l;
                        return true;
                    } 

                    return false;
                });
                return evseTemp.length > 0;    
            }
            return false;
        });
        console.log(evseEdit);
        console.log(evseConectors);

        setDataEvseForm({
            evse_id:  evseEdit.evse_id,
            location:  evseEdit.location,
            type_use: evseEdit.type_use,
            evse_version: evseEdit.evse_version,
            ip: evseEdit.evse_ip, 
            puerto: evseEdit.port_evse,
            chargerbox_id:evseEdit.evse_id, 
            evse_brand:evseEdit.evse_brand_id,
            evse_model:evseEdit.evse_model_id,
            maximum_consumption:evseEdit.maximum_consumption,
            latitude:evseEdit.latitude,
            longitude:evseEdit.longitude
        });

        let conectoresObj = [];
        let conectoresMap = [];
        const connectorsIn = evseEdit.connectors;
        for(let i = 0 ; i < connectorsIn.length ; i++){
            conectoresObj.push({
                conn_id:connectorsIn[i].conn_id,
                standard: connectorsIn[i].standard,
                max_voltage: connectorsIn[i].max_voltage,
                max_amperage: connectorsIn[i].max_amperage,
                max_electric_power: connectorsIn[i].max_electric_power
            });
            conectoresMap.push({
                standard: {
                    msg:`Asignar un standard valido`,
                    status:'success'
                },
                max_voltage: {
                    msg:`Asignar un voltaje valido`,
                    status:'success'
                },
                max_amperage: {
                    msg:`Asignar un amperaje valido`,
                    status:'success'
                },
                max_electric_power: {
                    msg:`Asignar una potencia valida`,
                    status:'success'
                },
            });
        }

        setConectoresMapState(conectoresMap)

        setConectores({
            data:conectoresObj
        });

        setEvseObj({loading:false,data:evseEdit});
    }

    const handleAgregarConector = () => {
        setConectoresMapState([
            ...conectoresMapState,
            {
                standard: {
                    msg:`Asignar un standard valido`,
                    status:'success'
                },
                max_voltage: {
                    msg:`Asignar un voltaje valido`,
                    status:'success'
                },
                max_amperage: {
                    msg:`Asignar un amperaje valido`,
                    status:'success'
                },
                max_electric_power: {
                    msg:`Asignar una potencia valida`,
                    status:'success'
                },
            }
        ]);
        setConectores({
            data:[
                ...conectores.data,
                {
                    conn_id:parseInt(conectores.data.length === 0 ? 0 : conectores.data[conectores.data.length - 1].conn_id) + 1,
                    standard: "0",
                    max_voltage: "",
                    max_amperage: "",
                    max_electric_power: ""
                }
            ]
        });
    }
    const showNuevaLoc = () => {
        setModalVisible(true);
    }

    const showNuevaMarca = () => {
        setModalBrandVisible(true);
    }



    const eliminarConector = (index) => {
        const temp_conectores = conectores.data.filter( obj => obj.conn_id !== index);
        let temp_MapStatus = conectoresMapState;
        temp_MapStatus.length = temp_MapStatus.length - 1;
        setConectoresMapState(temp_MapStatus);
        setConectores({data:temp_conectores});
    }
    

    const cargarModelos = (brand) => {
        setModels({...models,loading:true});
        apiLocations.getModelsBrandsLocation(brand).then( (rslt) => {
            console.log(rslt);
            setModels({data:rslt,loading:false});
        }).catch( (err) => {
            setModels({...models,loading:false});
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
        });
    }

    const cargarBrands = () => {
        setBrands({...brands,loading:true});
        apiLocations.getBrandsLocation().then( (rslt) => {
            setBrands({data:rslt,loading:false});
        }).catch( (err) => {
            setBrands({...brands,loading:false});
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
        });
    }

    return(
        <Col span={24} >
            <Row style={{
                justifyContent:'center'
            }}>
                <Col span={22}>
                    <section className={'seccion_formulario_crear_cargadir'}>
                        

                        <Row style={{
                            justifyContent:'center',
                        }}>
                            <Col span={24}>
                                <h2
                                style={{
                                    fontWeight:'bold',
                                    marginTop:'30px'
                                }}>{cargadorId ? `Editar estación de carga: ${cargadorId}` : `Crear nueva estación de carga (EVSE)`}</h2>
                            </Col>

                            <Col span={12}
                            style={{
                                marginTop:'30px'
                            }}>
                                <Spin tip={'Cargando información'} spinning={evseObj.loading}>

                                
                                    <Form {...layout}
                                    name="control-ref">
                                        <Form.Item label="Evse ID" 
                                        labelAlign={'left'} 
                                        name={'form-evse_id'}
                                        valuePropName={'evse_id'}
                                        rules={[requiredSettings]}
                                        validateStatus={evseMapState.evse_id.status}
                                        help={evseMapState.evse_id.status === 'error' ? evseMapState.evse_id.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Input
                                            disabled={cargadorId} 
                                            placeholder={'Ingrese ID'} onChange={(e) => modifyFormSend('evse_id',e.target.value.trim())}  value={dataEvseForm.evse_id} />
                                        </Form.Item>
                                        <Form.Item 
                                        label="Locación" 
                                        valuePropName={'location'}
                                        labelAlign={'left'} 
                                        name={'form-evse_locacion'}
                                        rules={[requiredSettings]}
                                        validateStatus={evseMapState.location.status}
                                        help={evseMapState.location.status === 'error' ? evseMapState.location.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Button onClick={showNuevaLoc} style={{
                                                position:'absolute',
                                                zIndex:0,
                                                left:'101%'
                                            }} type={'primary'} icon={<PlusOutlined/>} ></Button>
                                            <Select id={'control-ref_form-evse_location'} showSearch 
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } 
                                            loading={locationsLoading}
                                            value={dataEvseForm.location}
                                            onChange={(e) => modifyFormSend('location',e)} allowClear>
                                                <Option key={''} value={''}>{locations.length == 0 ? 'Debes crear una' : 'Seleccione uno'}</Option>
                                                {
                                                    locations.map( (e,index) => {
                                                        return(
                                                            <Option key={index} value={e.id}>{e.name}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item 
                                        name={'form-evse_type_use'}
                                        valuePropName={'type_use'}
                                        label="Tipo de uso" labelAlign={'left'} rules={[requiredSettings]}
                                        validateStatus={evseMapState.type_use.status}
                                        help={evseMapState.type_use.status === 'error' ? evseMapState.type_use.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Select
                                            showSearch 
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } 
                                            value={dataEvseForm.type_use} 
                                            onChange={(e) => modifyFormSend('type_use',e)} allowClear>
                                                <Option key={"0"} value={'0'}>Seleccione un tipo de uso</Option>
                                                <Option key={"DOMESTIC"} value="DOMESTIC">Domestico</Option>
                                                <Option key={"PRIVATE"} value="PRIVATE">Privado</Option>
                                                <Option key={"PUBLIC"} value="PUBLIC">Publico</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item  
                                        name={'form-evse_version'}
                                        valuePropName={'evse_version'}
                                        label="Version de OCPP" labelAlign={'left'} rules={[requiredSettings]}
                                        validateStatus={evseMapState.evse_version.status}
                                        help={evseMapState.evse_version.status === 'error' ? evseMapState.evse_version.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Select 
                                            showSearch 
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } 
                                            value={dataEvseForm.evse_version} onChange={(e) => modifyFormSend('evse_version',e)}  allowClear>
                                                <Option value="0">Seleccione una version</Option>
                                                <Option value="v15">1.5</Option>
                                                <Option value="v16">1.6</Option>
                                            </Select>
                                        </Form.Item>
                                        { 
                                            dataEvseForm.evse_version === 'v15' && 
                                            <Form.Item 
                                            name={'form-evse_ip'}
                                            valuePropName={'ip'}
                                            label="Ip" labelAlign={'left'} rules={
                                            [
                                                requiredSettings
                                            ]}
                                            validateStatus={evseMapState.ip.status}
                                            help={evseMapState.ip.status === 'error' ? evseMapState.ip.msg : null}
                                            style={{
                                                textAlign:'left'
                                            }}>
                                                <Input placeholder={'186.114.146.91'} onChange={(e) => modifyFormSend('ip',e.target.value)} value={dataEvseForm.ip} />
                                            </Form.Item>
                                        }
                                        { 
                                            dataEvseForm.evse_version === 'v15' && 
                                            <Form.Item 
                                            name={'form-evse_port'}
                                            valuePropName={'puerto'}
                                            label="Puerto" labelAlign={'left'} rules={
                                            [
                                                requiredSettings
                                            ]}
                                            validateStatus={evseMapState.puerto.status}
                                            help={evseMapState.puerto.status === 'error' ? evseMapState.puerto.msg : null}
                                            style={{
                                                textAlign:'left'
                                            }}>
                                                <Input placeholder={'8080'} onChange={(e) => modifyFormSend('puerto',e.target.value)} value={dataEvseForm.puerto}/>
                                            </Form.Item>
                                        }
                                        <Form.Item  
                                        name={'form-evse_brand'}
                                        valuePropName={'evse_brand'}
                                        label="Evse Marca" labelAlign={'left'} rules={[requiredSettings]}
                                        validateStatus={evseMapState.evse_brand.status}
                                        help={evseMapState.evse_brand.status === 'error' ? evseMapState.evse_brand.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Button onClick={showNuevaMarca} style={{
                                                position:'absolute',
                                                zIndex:0,
                                                left:'101%'
                                            }} type={'primary'} icon={<PlusOutlined/>} ></Button>
                                            <Select showSearch 
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            loading={brands.loading}
                                            value={dataEvseForm.evse_brand} onChange={(e) => modifyFormSend('evse_brand',e)} allowClear>
                                                <Option key={''} value={0}>Seleccione uno</Option>
                                                {
                                                    brands.data.map( (e,index) => (
                                                        <Option key={index} value={e.id}>{e.description}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item  
                                        name={'form-evse_model'}
                                        valuePropName={'evse_model'}
                                        label="Evse Modelo" labelAlign={'left'} rules={[requiredSettings]}
                                        validateStatus={evseMapState.evse_model.status}
                                        help={evseMapState.evse_model.status === 'error' ? evseMapState.evse_model.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            {dataEvseForm.evse_brand != 0 ? (<Button onClick={() => setModalModelVisible(true)} style={{
                                                position:'absolute',
                                                zIndex:0,
                                                left:'101%'
                                            }} type={'primary'} icon={<PlusOutlined/>} ></Button>) :'' }
                                            <Select showSearch 
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } 
                                            loading={models.loading}
                                            value={dataEvseForm.evse_model} onChange={(e) => modifyFormSend('evse_model',e)} allowClear>
                                                <Option key={''} value={0}>{models.data.length === 0 ? 'Sin modelos disponibles' : 'Seleccione uno'}</Option>
                                                {
                                                    models.data.map( (e,index) => (
                                                        <Option key={index} value={e.id}>{e.description}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item  
                                        name={'form-evse_max_consum'}
                                        valuePropName={'maximum_consumption'}
                                        label="Potencia max (kW)" labelAlign={'left'} rules={[requiredSettings]}
                                        validateStatus={evseMapState.maximum_consumption.status}
                                        help={evseMapState.maximum_consumption.status === 'error' ? evseMapState.maximum_consumption.msg : null}
                                        style={{
                                            textAlign:'left'
                                        }}>
                                            <Input type={'number'} placeholder={'Ingrese valor'} onChange={(e) => modifyFormSend('maximum_consumption',e.target.value)} value={`${dataEvseForm.maximum_consumption}`} />
                                        </Form.Item>

                                        <h3 style={{textAlign:'left',fontWeight:'bold'}}>Coordernadas (Opcional)</h3>
                                        <Form.Item 
                                        name={'form-evse_latitude'}
                                        valuePropName={'latitude'}
                                        label="Latitud" labelAlign={'left'}>
                                            <Input type={'number'} onChange={(e) => modifyFormSend('latitude',e.target.value)} placeholder={'3.1451789654'} value={`${dataEvseForm.latitude}`}/>
                                        </Form.Item>
                                        <Form.Item  
                                        name={'form-evse_longitude'}
                                        valuePropName={'longitude'}
                                        label="Longitud" labelAlign={'left'}>
                                            <Input type={'number'} onChange={(e) => modifyFormSend('longitude',e.target.value)} placeholder={'-71.6546'} value={`${dataEvseForm.longitude}`}/>
                                        </Form.Item>

                                        <h3 style={{textAlign:'left',fontWeight:'bold'}}>Conectores</h3>
                                        {
                                            conectores.data.map( (value,index) => (
                                                <Row key={index} className={'background_new_conector'}>
                                                    <Col span={2} style={{
                                                        color:'#ee7400'
                                                    }}>
                                                        <FontAwesomeIcon className="anticon" icon={faPlug} />
                                                        {` #${value.conn_id}`}
                                                    </Col>
                                                    <Col span={20} style={{
                                                        display:'inline-block'
                                                    }}>
                                                        <Row>
                                                            <Col span={6} className={'col_conector_param'} >
                                                                <Form.Item  
                                                                wrapperCol={24} style={{marginBottom:0}} 
                                                                validateStatus={conectoresMapState[index].standard.status}
                                                                help={conectoresMapState[index].standard.status === 'error' ? conectoresMapState[index].standard.msg : null}>
                                                                    <p style={{
                                                                        width:'20%',
                                                                        margin:0,
                                                                        display:'inline-block'
                                                                    }} >Tipo:</p>
                                                                    <Select  
                                                                    id={'control-ref_form-conector_standard'}
                                                                    showSearch 
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    } 
                                                                    onChange={(e)=> updateConector(value.conn_id,'standard',e)} value={conectores.data[index].standard} style={{
                                                                        width:'80%'
                                                                    }}  allowClear>
                                                                        <Option value="0">Seleccione uno</Option>
                                                                        <Option value="TIPO_1">TIPO_1</Option>
                                                                        <Option value="TIPO_2">TIPO_2</Option>
                                                                        <Option value="SCHUKO">SCHUKO</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6} className={'col_conector_param'}>
                                                                <Form.Item 
                                                                name={'form-conector_max_voltage'} 
                                                                valuePropName={'max_voltage'}
                                                                wrapperCol={24} style={{marginBottom:0}}
                                                                validateStatus={conectoresMapState[index].max_voltage.status}
                                                                help={conectoresMapState[index].max_voltage.status === 'error' ? conectoresMapState[index].max_voltage.msg : null}>
                                                                    <Input type={'number'} value={conectores.data[index].max_voltage} onChange={(e)=> updateConector(value.conn_id,'max_voltage',e.target.value)} placeholder={'Voltaje(V)'}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6} className={'col_conector_param'}>
                                                                <Form.Item 
                                                                name={'form-conector_max_amperage'} 
                                                                valuePropName={'max_amperage'}
                                                                wrapperCol={24} style={{marginBottom:0}}
                                                                validateStatus={conectoresMapState[index].max_amperage.status}
                                                                help={conectoresMapState[index].max_amperage.status === 'error' ? conectoresMapState[index].max_amperage.msg : null}>
                                                                    <Input type={'number'} value={conectores.data[index].max_amperage} onChange={(e)=> updateConector(value.conn_id,'max_amperage',e.target.value)} placeholder={'Amperaje(A)'}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6} className={'col_conector_param'}>
                                                                <Form.Item 
                                                                name={'form-conector_max_electric_power'} 
                                                                valuePropName={'max_electric_power'}
                                                                wrapperCol={24} style={{marginBottom:0}}
                                                                validateStatus={conectoresMapState[index].max_electric_power.status}
                                                                help={conectoresMapState[index].max_electric_power.status === 'error' ? conectoresMapState[index].max_electric_power.msg : null}>
                                                                    <Input type={'number'} value={conectores.data[index].max_electric_power} onChange={(e)=> updateConector(value.conn_id,'max_electric_power',e.target.value)}  placeholder={'Potencia(kW)'}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={2}>
                                                        <CloseOutlined onClick={() => eliminarConector(value.conn_id)} />
                                                    </Col>
                                                </Row>
                                                
                                            ))
                                        }
                                        <Col span={24} style={
                                            {
                                                display:'flex',
                                                justifyContent:'left',
                                                marginTop:'10px',
                                                marginBottom:'20px'
                                            }
                                        }>
                                            <Button onClick={handleAgregarConector} icon={<PlusOutlined  />}>Agregar Conector</Button>
                                        </Col>
                                    </Form>
                                </Spin>
                            </Col>
                        </Row>

                    
                    </section>    
                </Col>
            </Row>
        </Col>
    );
}

FormularioEvse.defaultProps = {
    locationsLoading:false
}

export default FormularioEvse;