import React, { useEffect, useState } from 'react'; 
import { Row,Col, Form, Select, Card, Tooltip as TooltipAnt,Button,Popover, message } from 'antd';
import { CardDefault,CardCategory, CardGraphic, CardEnergy } from './components';
import Breadcrumb from '@containers/Breadcrumb';
import { useSelector } from 'react-redux';
import { apiMetricas } from '@utils/services/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faClock, faStopwatch, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DateSelectorGroup } from '@components/ui';
import { apiOperators,apiLocations } from '@utils/services/cpo';
import { Spanish } from '@utils/helpers/dictIdiomas'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  } from 'recharts';

import './style.less';
import CardCake from './components/card_cake';
const Metricas = () => {
    const textLanguage = Spanish({screen:'cpo-metrics'})
    const user = useSelector((state) => state.user);
    const usoDefaultData = [
        {
            connector_id__standard: "TIPO_1",
            evse_uid__evse_id: "NA",
            kwh: 0,
            time_use: "0:0:0.000000",
        },
        {
            connector_id__standard: "TIPO_2",
            evse_uid__evse_id: "NA",
            kwh: 0,
            time_use: "0:0:0.000000",
        },
        {
            connector_id__standard: "CHADEMO",
            evse_uid__evse_id: "NA",
            kwh: 0,
            time_use: "0:0:0.000000",
        }
    ];

    const recargasDefaultData = {
        charge_avg:0,
        energy_avg: 0,
        energy_delivered: 0,
        peak_hours_recharges: 0,
        recharges_avg: 0,
        recharges_done: 0,
        recharges_remote_command: 0,
        recharges_rfid: 0,
        sessions_active: 0,
        time_use: 0,
    }

    const tarjetasDefaultData = {
        active_cards:0,
        cards_avg:0
    }

    const dataAreaChart = [
        {
          name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
        },
        {
          name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
        },
        {
          name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
        },
        {
          name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
        },
        {
          name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
        },
        {
          name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
        },
        {
          name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
        },
    ];

    const disponibilidadDefaultData = [
        {
            evse_uid: 'Conector', disponible: 0, fallo: 0, desconocido: 0, 
        },
    ];


    const [ locations, setLocations ] = useState({
        data:[],
        selected:0,
        loading:false
    });
    const { Option } = Select;
    const breadcrumbData = [[null, 'Mis Metricas']];
    const [ metricasDispo, setMetricaDispo ] = useState(disponibilidadDefaultData);
    const [ metricasRecargas, setMetricasRecargas] = useState(recargasDefaultData);
    const [ metricasTarjetas, setMetricasTarjetas ] = useState(tarjetasDefaultData);
    const [ metricasEnergy, setMetricasEnergy ] = useState({
        data:[],
        loading:false
    });
    const [ metricasUsos, setMetricasUsos ] = useState({
        data:usoDefaultData
    });

    const rangoHoy = () => {
        let hoyTemp1 = new Date();
        let hoyTemp2 = new Date();

        hoyTemp1.setHours(0);
        hoyTemp1.setMinutes(0);
        hoyTemp1.setMilliseconds(0);

        hoyTemp2.setHours(23);
        hoyTemp2.setMinutes(59);
        hoyTemp2.setMilliseconds(59);

        return [hoyTemp1,hoyTemp2]
    }

    const [rangoDate , setRangoDate] = useState(rangoHoy());

    useEffect(()=>{
        cargarLocations();
    },[]);


    useEffect( () => {
        cargarUsos();
        cargarMeticasTarjetas();
        cargarDisponibilidad();
        cargarCargas();
        cargarDataEnergy();
    },[locations,rangoDate]);

    const format_date_request = ( item ) => {
        return `${item.getUTCFullYear()}-${(item.getUTCMonth()+1)}-${item.getUTCDate()} ${item.getUTCHours()}:${item.getUTCMinutes()}:${item.getUTCSeconds()}`;
    }

    const cargarCargas = () => {
        let arrayTemp = [];
        if(locations.selected != 0){
            arrayTemp.push(locations.selected);
        }else{
            for(let i = 0 ; i < locations.data.length ; i++){
                arrayTemp.push(locations.data[i].id);
            }
        }

        if(arrayTemp.length >= 1){
            apiMetricas.obtener_cargas({locations_id:arrayTemp,start_date:format_date_request(rangoDate[0]),end_date:format_date_request(rangoDate[1])}).then( (rslt) => {
                if(rslt.error){
                    message.info(textLanguage.METRICS_REQUEST_FAIL);
                }else{
                    setMetricasRecargas({
                        charge_avg:rslt.charge_avg,
                        energy_avg: rslt.energy_avg,
                        energy_delivered: rslt.energy_delivered,
                        peak_hours_recharges: rslt.peak_hours_recharges,
                        recharges_avg: rslt.recharges_avg,
                        recharges_done: rslt.recharges_done,
                        recharges_remote_command: rslt.recharges_remote_command,
                        recharges_rfid: rslt.recharges_rfid,
                        sessions_active: rslt.sessions_active,
                        time_use: rslt.time_use,
                    });
                }
            }).catch( (err) => {
                console.log(err);
                setMetricasRecargas(recargasDefaultData);
            });
        } 
    }

    const cargarUsos = () => {
        let arrayTemp = [];
        if(locations.selected != 0){
            arrayTemp.push(locations.selected);
        }else{
            for(let i = 0 ; i < locations.data.length ; i++){
                arrayTemp.push(locations.data[i].id);
            }
        }
        
        if(arrayTemp.length >= 1){
            apiMetricas.obtener_usos({
                locations_id:arrayTemp,start_date:format_date_request(rangoDate[0]),end_date:format_date_request(rangoDate[1])})
            .then((rslt)=>{
                setMetricasUsos({
                    data:rslt
                });
            })
            .catch((err)=>{
                setMetricasUsos({
                    data:usoDefaultData
                });
            });
        }

    }

    const cargarMeticasTarjetas = () => {
        apiMetricas.obtener_tarjetas({client_id:user.clientSelected.client_id,start_date:format_date_request(rangoDate[0]),end_date:format_date_request(rangoDate[1])})
        .then((rslt) => {
            setMetricasTarjetas(rslt);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    const cargarDataEnergy = () => {
        setMetricasEnergy({...metricasEnergy,loading:true});
        let arrayTemp = [];
        if(locations.selected != 0){
            arrayTemp.push(locations.selected);
        }else{
            for(let i = 0 ; i < locations.data.length ; i++){
                arrayTemp.push(locations.data[i].id);
            }
        }
        
        if(arrayTemp.length >= 1){
            apiMetricas.obtener_energia_conector({locations_id:arrayTemp,start_date:getUTCString(rangoDate[0]),end_date:getUTCString(rangoDate[1])})
            .then( (rslt) => {
                setMetricasEnergy({data:rslt,loading:false});
            })
            .catch( (err) => {
                console.log(err);
                setMetricasEnergy({...metricasEnergy,loading:false});
            });
        }
    }

    const onChangeDateRange = (start,end) => {
        let dateStart = new Date(start);
        let dateEnd = new Date(end);

        setRangoDate([dateStart,dateEnd]);
    };

    const cargarLocations = () => {
        setLocations({...locations,loading:true});
        apiLocations.allLocations(user.user_id).then( (rslt) => {
            setLocations({...locations,data:rslt,loading:false});
        }).catch( (err) => {
            setLocations({...locations,loading:false});
            console.log(err);
        });
    }

    const cargarDisponibilidad = () => {
        let arrayTemp = [1];
        if(locations.selected != 0){
            arrayTemp.push(locations.selected);
        }else{
            for(let i = 0 ; i < locations.data.length ; i++){
                arrayTemp.push(locations.data[i].id);
            }
        }
        
        if(arrayTemp.length >= 1){
            apiMetricas.obtener_disponibilidad({
                locations_id:arrayTemp,start_date:format_date_request(rangoDate[0]),end_date:format_date_request(rangoDate[1])})
            .then((rslt)=>{
                setMetricaDispo(rslt);
            })
            .catch((err)=>{
                setMetricasUsos({
                    data:usoDefaultData
                });
            });
        }
    }

    const tooltipPeekRecharges = () => {
        return(
            <Row className={'metricas-tooltip'}>
                <Col span={24}>
                    <p className={'metricas-tooltip_title'}>Horas pico</p>
                    <p>
                        Horario de mayor demanda en la red eléctrica en el país.<br/>
                        <strong>De:</strong> 18:00 a 21:00
                    </p>
                    <p className={'metricas-tooltop_legend'}>
                        <strong>No</strong> cargar en horas pico ayuda al <strong>medio ambiente</strong>
                    </p>
                </Col>
            </Row>
        );
    }



    return(
        <Row className={'metricas-row_main'}>
            <Breadcrumb breadcrumbData={breadcrumbData}></Breadcrumb>

            <Col className={'metricas-col'} span={23} >
                <Form>
                    <Row>
                        <DateSelectorGroup
                        onChangeDateRange={onChangeDateRange}/>
                        
                        <Col className={'filtro-col'} span={6}>
                            <Form.Item className={'metricas-form_filtro'} label={'Estacion'}>
                                <Select
                                value={locations.selected}
                                loading={locations.loading}
                                onChange={(value) => { setLocations({...locations,selected:value}) }}
                                >
                                <Select.Option key={0} value={0}>
                                    {locations.loading ? 'Cargando' : locations.data.length > 0 ? 'Todas las estaciones' : 'Sin estaciones disponibles'}
                                </Select.Option>
                                {locations.data.map(({ id, name }) => {
                                    return (
                                    <Select.Option key={id} value={id}>
                                        {name}
                                    </Select.Option>
                                    );
                                })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col className={'metricas-col'} span={23}>
                <Row>
                    <Col className={'metricas-card_data'} span={12}>
                        <CardDefault 
                        title={'Recargas realizadas'}
                        bottomMsg={'Promedio de recargas diarias'}
                        mainValue={metricasRecargas.recharges_done}
                        bottomCount={metricasRecargas.recharges_avg}>
                            <Row className={'metricas-card_children'}>
                                <Col span={22}>{`Recargas con tarjeta RFID`}</Col>
                                <Col className={'metricas-card_children_value'} span={2}>{metricasRecargas.recharges_rfid}</Col>
                            </Row>
                            <Row className={'metricas-card_children'}>
                                <Col span={22}>{`Iniciadas remotamente`}</Col>
                                <Col className={'metricas-card_children_value'} span={2}>{metricasRecargas.recharges_remote_command}</Col>
                            </Row>
                        </CardDefault>
                    </Col>
                    <Col className={'metricas-card_data'} span={12}>
                        <CardDefault 
                        title={'Energía entregada'}
                        bottomMsg={'Recargas en curso'}
                        mainValue={`${metricasRecargas.energy_delivered}kWh`}
                        bottomCount={metricasRecargas.sessions_active}>
                            <ResponsiveContainer minHeight={50}>
                                <AreaChart
                                    data={dataAreaChart}
                                    margin={{
                                    top: 5, right: 0, left: 0, bottom: 5,
                                    }}
                                >
                                    <Area type="monotone" dataKey="uv" stroke="#FF812E" fill="#FF812E" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </CardDefault>
                    </Col>
                    {/* <Col className={'metricas-card_data'} span={8}>
                        <CardDefault 
                        title={'Tarjetas activas'}
                        bottomMsg={'Promedio de tarjetas registradas'}
                        mainValue={metricasTarjetas.active_cards}
                        bottomCount={metricasTarjetas.cards_avg}>
                            <Row className={'metricas-card_children'}>
                                <Col style={{visibility:'hidden'}} span={24}>{`Año anterior`} <strong className={'metricas-card_children_porc'}>{`${metricasRecargas.recharges_rfid}%`}</strong><FontAwesomeIcon color={'#0dc390'} className={'metricas-card_icon_float'}  icon={faSortUp} /></Col>
                            </Row>
                        </CardDefault>
                    </Col> */}
                </Row>
                
                <CardEnergy  locations={locations} dataRequest={metricasEnergy} rangoDate={rangoDate} />
                <Row>
                    <Col className={'metricas-card_data'} span={24}>
                        {/* <CardGraphic title={'Disponibilidad de cargadores'} dataRaw={metricasDispo} type={'disponibilidad'} graphicType={'bar-horizontal'}></CardGraphic> */}
                    </Col>
                </Row>
                <Row>
                    <Col className={['metricas-card_data','metricas-space']} sm={24} lg={12}>
                        {/* <CardGraphic labelY={'kWh'} dataRaw={metricasUsos.data} type={'energia_entregada'} title={'Energía entregada por cargador'} graphicType={'bar-vertical'}></CardGraphic> */}
                        <CardCake dataRequest={metricasEnergy}/>
                    </Col>
                    <Col className={'metricas-space'} sm={24} lg={12} >
                        <Row>
                            <Col className={['metricas-card_data']} span={12}>
                                <CardCategory mainValue={`${metricasRecargas.time_use}%`} icon={faChargingStation} iconColor={'#0dc390'} description={`Porcentaje de uso de cargadores`}></CardCategory>
                            </Col>
                            <Col className={['metricas-card_data']} span={12} >
                                <CardCategory mainValue={metricasRecargas.energy_avg.toFixed(2)} icon={faBolt} iconColor={'#ee7400'} description={`Energia promedio entregada (kWh)`}></CardCategory>
                            </Col>
                            <Col className={['metricas-card_data','info-grid_space']} span={12}>
                                <CardCategory mainValue={metricasRecargas.charge_avg} icon={faClock} iconColor={'#52a7da'} description={`Duración promedio de carga`} ></CardCategory>
                            </Col>
                            <Col className={['metricas-card_data','info-grid_space']} span={12}>
                                <CardCategory toolChilds={(
                                    <Popover placement="top"  color={`#fff`} active={true} content={tooltipPeekRecharges}>
                                        <FontAwesomeIcon className={'metricas-card_tooltip'}  icon={faQuestionCircle} />
                                    </Popover>
                                )} mainValue={`${metricasRecargas.peak_hours_recharges}%`} icon={faStopwatch} iconColor={'#3f3f3f'} description={`Porcentaje de cargas en horas pico`}></CardCategory>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Metricas;