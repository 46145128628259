import { getTenant } from './management';

const vehicles = (exactEndpoint = null) => {
  if (!exactEndpoint) {
    return getTenant('/vehicles/list', true);
  }
  return getTenant(exactEndpoint, true, true);
};

const vehiclesStatus = () => {
  return getTenant('/vehicles/status', true);
};

const trakersLatest = (tracker) => getTenant(`/trackers/latest/${tracker}`);

const vehicleGroups = () => getTenant('/groups/list', true);
const vehiclesByGroupId = (groupId) => getTenant(`/vehicles/list/?group_id=${groupId}`, true);

export default { vehicles, trakersLatest, vehiclesStatus, vehicleGroups, vehiclesByGroupId };
