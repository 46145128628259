import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, List, Input, Spin, Typography, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import VehicleItem from '../components/VehicleItem';
import sortValuesVehiclesByStatus from '../helper/sortValuesVehiclesByStatus';

const { Paragraph } = Typography;

function VehiclesTable() {
  const loading = useSelector(({ mapView }) => mapView.vehiclesLoading);
  const vehiclesData = useSelector(({ mapView }) => mapView.vehiclesData);
  const pieStatusSelected = useSelector(({ mapView }) => mapView.pieStatusSelected);

  const [filter, setFilter] = useState('');

  const onChange = useCallback(
    ({ target: { value } }) => {
      setFilter(value);
    },
    //
    []
  );

  const data = vehiclesData ? Object.values(vehiclesData) : [];
  let dataSource =
    filter.length === 0
      ? data
      : data.filter(({ lic }) => lic.toUpperCase().includes(filter.toUpperCase()));

  dataSource = sortValuesVehiclesByStatus(dataSource);
  if (pieStatusSelected) dataSource = dataSource.filter(({ sta }) => sta === pieStatusSelected);

  return (
    <Card
      className="grid-info__vehiclesTable"
      cover={
        <div className="cover-card list__header">
          <Input
            prefix={<SearchOutlined />}
            className="search"
            placeholder="Buscar por placa"
            onChange={onChange}
          />
        </div>
      }
    >
      {loading && <Spin tip="Cargando vehículos" style={{ marginTop: 20, marginBottom: 20 }} />}

      {vehiclesData && (
        <>
          <List
            className="list"
            dataSource={dataSource}
            renderItem={(item) => <VehicleItem key={item.lic} item={item} />}
          />
          <Divider style={{ marginTop: 0, marginBottom: 12 }} />
          <Paragraph strong style={{ textAlign: 'center' }}>
            {dataSource.length} vehículo(s)
          </Paragraph>
        </>
      )}
    </Card>
  );
}

export default VehiclesTable;
