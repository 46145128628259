import React from 'react';
import { Typography, Space, Button, Tabs } from 'antd';

import NotificationTray from './NotificationTray';

const { Title } = Typography;
const { TabPane } = Tabs;

const Content = () => {
  return (
    <>
      <Space size="large" style={{ padding: '8px 24px' }}>
        <Title level={3} style={{ margin: 0 }}>
          Notificaciones
        </Title>
        <Button type="primary">¿Cómo podemos mejorar?</Button>
      </Space>

      <Tabs tabBarStyle={{ padding: '0 24px', margin: 0 }}>
        <TabPane tab="Hoy (4)" key="hoy">
          <NotificationTray />
        </TabPane>
        <TabPane tab="Ayer (0)" key="ayer">
          <NotificationTray />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Content;
