import React from 'react';
import { Card, Typography, Space, Statistic } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';

import './style.less';

const { Paragraph } = Typography;

const simulateData = [
  {
    value: 0.7,
    typeValue: 'kWh/km',
    title: 'Eficiencia',
  },
  {
    value: '01:30:15',
    typeValue: 'hh:mm:ss',
    title: 'Tiempo estacionado',
  },
  {
    value: '00:45:15',
    typeValue: 'hh:mm:ss',
    title: 'Tiempo en movimiento',
  },
  {
    value: 100,
    typeValue: 'kWh',
    title: 'Consumo de energía',
  },
  {
    value: 25,
    typeValue: 'Km',
    title: 'Distancia recorrida',
  },
  {
    value: 0,
    typeValue: 'kWh',
    title: 'Energía recargada',
  },
];

const ResumeCards = () => {
  return (
    <div className="resume-cards">
      {simulateData.map(({ value, typeValue, title }, index) => {
        const key = title + index;
        return (
          <Card key={key}>
            <Space direction="vertical" size="large">
              <DatabaseOutlined style={{ fontSize: '32px' }} />

              <div>
                <Statistic valueStyle={{ fontSize: 28 }} value={value} />
                <Statistic valueStyle={{ fontSize: 18 }} value={typeValue} />
              </div>

              <Paragraph>{title}</Paragraph>
            </Space>
          </Card>
        );
      })}
    </div>
  );
};

export default ResumeCards;
