/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-undef */
import { getToken } from '@utils/auth/createAuthProvider';
import default_settings from './default';
// const baseUrl = 'https://oraculobackend-qa-wa.azurewebsites.net';
console.log(`env de backend: ${process.env.REACT_APP_BACKEND_HOST}`);

const baseUrl = process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : default_settings.default_endpoint;
let tenantUrl = '';
const headers = new Headers({
  'Content-Type': 'application/json',
});

const promiseRequest = async (
  endpoint,
  extraConfig,
  hasTenant = false,
  requireToken = false,
  exact = false
) => {
  const temp = hasTenant ? `${tenantUrl}${endpoint}` : `${baseUrl}${endpoint}`;
  const defEndpoint = exact ? endpoint : temp;

  if (requireToken) {
    const token = await getToken();
    headers.set('Authorization', `JWT ${token}`);
  } else {
    headers.set('Authorization', '');
  }

  
  return new Promise((resolve, reject) => {
    fetch(defEndpoint, { headers, ...extraConfig })
      .then((response) => {
        return response.json();
      })
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
};

const promiseCSVRequest = async (endpoint, extraConfig,hasTenant = false,requireToken = false,exact = false) => {
  const temp = hasTenant ? `${baseUrl}${endpoint}` : `${baseUrl}${endpoint}`;
  const defEndpoint = exact ? endpoint : temp;

  const headers = new Headers({
    "Content-Type": "application/vnd.ms-excel"
  });

  if (requireToken) {
    const token = await getToken();
    // console.log(defEndpoint,token);
    headers.set('Authorization', `JWT ${token}`);
  } else {
    headers.set('Authorization', '');
  }

  return fetch(defEndpoint, { headers, ...extraConfig }).then(response => response.blob())
  .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = "filename.csv";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    })
  .catch((error) => {
      console.log(error);
  });
}

const get = (endpoint, requireToken) =>
  promiseRequest(endpoint, { method: 'GET' }, false, requireToken);

const getCSV = (endpoint, requireToken) => promiseCSVRequest(endpoint, { method: 'GET' },false,requireToken);

const post = (endpoint, body, requireToken) =>
  promiseRequest(endpoint, { method: 'POST', body: JSON.stringify(body) }, false, requireToken);

const put = (endpoint, body, requireToken) =>
  promiseRequest(endpoint, { method: 'PUT', body: JSON.stringify(body) }, false, requireToken);

const getTenant = (endpoint, requireToken, exact) =>
  promiseRequest(endpoint, { method: 'GET' }, true, requireToken, exact);

const postTenant = (endpoint, body, requireToken) =>
  promiseRequest(endpoint, { method: 'POST', body: JSON.stringify(body) }, true, requireToken);

const setTenantUrl = (newUser) => {
  tenantUrl = `${baseUrl}/tenant/${newUser}/api/1.0`;
};

export { setTenantUrl, get,getCSV, post, put, getTenant, postTenant };
