import React from 'react';
import { Card, Button, Table } from 'antd';

const columns = [
  {
    title: 'VEH (ID)',
    dataIndex: 'idVehicle',
    key: 'idVehicle',
    sorter: true,
  },
  {
    title: 'ESTADO',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'CONDUCTOR',
    dataIndex: 'conductor',
    key: 'conductor',
  },
  {
    title: 'BATERÍA',
    dataIndex: 'bateria',
    key: 'bateria',
    width: '15%',
  },
];

const data = [
  {
    key: '1',
    idVehicle: 'CH011110',
    status: 'Encendido y detenido',
    conductor: 'Andrés Perez',
    bateria: 50,
  },
  {
    key: '2',
    idVehicle: 'CH011111',
    status: 'Apagado',
    conductor: 'Camilo Jose',
    bateria: 20,
  },
  {
    key: '3',
    idVehicle: 'CH011112',
    status: 'Cargando',
    conductor: 'Armando Hernandez',
    bateria: 20,
  },
  {
    key: '4',
    idVehicle: 'CH011112',
    status: 'En movimiento',
    conductor: 'Fabian Bolaños',
    bateria: 80,
  },
  {
    key: '5',
    idVehicle: 'CH011112',
    status: 'En movimiento',
    conductor: 'Fabian Bolaños',
    bateria: 80,
  },
  {
    key: '6',
    idVehicle: 'CH011112',
    status: 'En movimiento',
    conductor: 'Fabian Bolaños',
    bateria: 80,
  },
  {
    key: '7',
    idVehicle: 'CH011112',
    status: 'En movimiento',
    conductor: 'Fabian Bolaños',
    bateria: 80,
  },
  {
    key: '8',
    idVehicle: 'CH011112',
    status: 'En movimiento',
    conductor: 'Fabian Bolaños',
    bateria: 80,
  },
];

const EstadoVehiculos = () => {
  return (
    <Card
      size="small"
      className="estado-vehiculos"
      title="Estado de vehículos"
      extra={<Button type="link">Más detalles</Button>}
    >
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        scroll={{ y: 312 }}
        tableLayout="auto"
      />
    </Card>
  );
};

export default EstadoVehiculos;
