import React, { useState, useCallback, useEffect } from 'react';
import { Button, Form, Input, Select, Row, Col, Divider, Affix, Modal, message } from 'antd';
import { OpenLocationCode } from 'open-location-code';
import { useSelector } from 'react-redux';
import Map from '@components/mapa/GoogleMap';
import { apiConfigClientes } from '@utils/services/index';
import { faArrowsAlt, faPlug,faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { MarkerClusterer, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import { apiLocations, } from '@utils/services/cpo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    AimOutlined,
    CloseOutlined,
    PlusOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Spanish } from '@utils/helpers/dictIdiomas'
const { Option } = Select;
const LocationForm = ( props ) => {
    const textLanguage = Spanish({screen:'evse-form'})
    const { 
        isModalVisible, setModalVisible,
        dataLocationSend ,setDataLocationForm,
        handleCrear, handleCancelar
    } = props;

    const user = useSelector((state) => state.user);
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const [ paises, setPaises ] = useState([]);
    const [ ciudades, setCiudades ] = useState([]);
    const [ ciudadEnCola, setCiudadCola ] = useState('');
    const openLocation = new OpenLocationCode();
    const [map, setMap] = useState(null);
    const [ isMapModalVisible, setMapModalVisible ] = useState(false);
    const [ locationMapState, setLocationMapState ] = useState({
        address:{
            msg:`Debes asignar una direccion`,
            status:'success'
        },
        name:{
            msg:`Debes asignar un nombre`,
            status:'success'
        },
        city:{
            msg:`Debes seleccionar una ciudad`,
            status:'success'
        },
        country:{
            msg:`Debes seleccionar un país`,
            status:'success'
        },
        plus_code:{
            msg:`Debes asignar un codigo plus`,
            status:'success'
        },
    });
    const [ myLocation, setMyLocation ] = useState({
        visible:false,
        lat:0,
        lng:0,
        zoom:10
    });
    const [ mapCenter, setMapCenter ] = useState({
        lat: 4.570868,
        lng: -74.297333,
    });
    const [ location_pluscode, setLocationPlus] = useState({
        lat: 0,
        lng: 0,
    });
    const [ plus_code , setPlusCode ] = useState('');

    const openMapPlusCode = () => {
        setMapModalVisible(true);
    }


    const handleCenterChange = (e) => {
    }

    const handleZoomChange = (e) => {
    }

    const cancelMapPlusCode = () => {
        
        setMapModalVisible(false);
    }

    const obtenerPaisRaw = ( { key, name  } ) => {
        for(let i = 0 ; i < paises.length ; i++){
            if(key && key == paises[i].id) return paises[i];
            if(name && name == paises[i].name) return paises[i];
        }
        return null
    }

    const geoLocationReverse = () => {
        const busqueda = `https://nominatim.openstreetmap.org/reverse?lat=${location_pluscode.lat}&lon=${location_pluscode.lng}&format=json&nameDetails=1&zoom=10`;
        return fetch(busqueda).then( async (rslt)=>{
            return rslt.json();
        }).catch((err)=>{
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
            return null;
        });
    }

    const limpiarLocationForm = () => {
        setDataLocationForm({
            address:'',
            name:'',
            city:0,
            country:0,
            client:0,
            plus_code:''
        });
    }

    const comprobarCamposLocation = () => {
        const locationDefault = {
            address:'',
            name:'',
            city:0,
            country:0,
            plus_code:''
        }

        let keys = Object.keys(dataLocationSend);

        let rslt = null;
        let locationStatusTemp = locationMapState;

        for(let i = 0 ; i < keys.length ; i++){
            if(dataLocationSend[`${keys[i]}`] === locationDefault[`${keys[i]}`]){
                locationStatusTemp = {
                    ...locationStatusTemp,
                    [`${keys[i]}`]:{
                        ...locationStatusTemp[`${keys[i]}`],
                        status:'error'
                    }
                }; 

                if(!rslt) rslt = [false,locationStatusTemp[`${keys[i]}`].msg];
            }else{
                locationStatusTemp = {
                    ...locationStatusTemp,
                    [`${keys[i]}`]:{
                        ...locationStatusTemp[`${keys[i]}`],
                        status:'success'
                    }
                }; 
            } 
        }

        setLocationMapState(locationStatusTemp);

        if(rslt) return rslt;

        return true;
        
    }

    const enviarFormulario = () => {
        const comprobacion = comprobarCamposLocation();

        if(comprobacion != true) return;

        handleCrear();

    }

    const modifyLocationFormSend = (param,value) => {
        switch(param){
            case 'country':
                if(value){
                    const paisTemp = obtenerPaisRaw({ key:value });
                    if(!paisTemp) cargarCiudades(paisTemp.iso2);
                }
                
                
                break;
        }

        setDataLocationForm({
            ...dataLocationSend,
            [`${param}`]:value
        });
    }

    const cargarMiUbicacion = () => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition( ( position ) => {
                setMyLocation({
                    visible:true,
                    lat:position.coords.latitude,
                    lng:position.coords.longitude,
                    zoom:15,
                });
                setMapCenter({
                    lat:position.coords.latitude,
                    lng:position.coords.longitude
                });

            });
            
        }
    }

    const cargarPaises = () => {
        apiConfigClientes.obtener_paises().then( (rslt) => {
            setPaises(rslt);
        }).catch( (err) => {
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
        });
    }

    const seleccionarCiudad = ( finding ) => {
        if(finding){
            const ciudadTemp = ciudades.filter((value) => (finding.toLowerCase() === value.name.toLowerCase()));
            if(ciudadTemp[0]){
                setDataLocationForm({
                    ...dataLocationSend,
                    city:ciudadTemp[0].id
                });
            }   
        }
    }

    const cargarCiudades = ( code ) => {
        apiConfigClientes.obtener_ciudades( code ? code : "CO").then( (rslt) => {
            setCiudades(rslt);
        }).catch( (err) => {
            console.log(err);
            message.warn(textLanguage.REQUEST_CON_ERROR);
        });
    }

    useEffect(()=>{
        cargarPaises();
    },[]);
    useEffect(() => {
        const pais = obtenerPaisRaw({key:dataLocationSend.country});
        if(pais)cargarCiudades(pais.iso2);
    },[dataLocationSend.country]);

    useEffect(()=>{
        if(ciudadEnCola !== ''){
            seleccionarCiudad(ciudadEnCola);
            setCiudadCola('');
        }
    },[ciudades]);

    useEffect(() => {
        console.log(location_pluscode);
        if(location_pluscode.lat != 0 && location_pluscode.lng != 0) generarLocationPlus();
    },[location_pluscode]);

    const getMap = useCallback((mapInstance) => {
        setMap(mapInstance);
        // eslint-disable-next-line
    }, []);

    // const cancelLocacion = () => {
    //     limpiarLocationForm();
    //     setModalVisible(false);
    // }

    const setMarkerPosition = (e) => {
        setLocationPlus({
            lat:parseFloat(e.latLng.lat()),
            lng:parseFloat(e.latLng.lng())
        })
    }

    const generarLocationPlus = () => {
        const locPlus = openLocation.encode(location_pluscode.lat,location_pluscode.lng);
        setPlusCode(locPlus);
    }

    const setLocationPlusCode = async () => {
        // modifyLocationFormSend('plus_code',plus_code);
        const geoDecode = await geoLocationReverse();
        const address = geoDecode.address;
        const countryTemp = obtenerPaisRaw({ name:address.country });
        if(address.city) await setCiudadCola(address.city);
        
        await setDataLocationForm({
            ...dataLocationSend,
            plus_code:`${plus_code}`,
            country:countryTemp.id
        });

        if(countryTemp.id === dataLocationSend.country) seleccionarCiudad(address.city);
        setPlusCode('');
        cancelMapPlusCode();
    }

    const closeLocationPlusCode = () => {
        setPlusCode('');
        setLocationPlus({
            lat:0,
            long:0,
        });
        cancelMapPlusCode();
    }

    return(
        <Modal 
        className={'modal-nuevo_conector'}
        width={800}
        style={{
            textAlign:'center',
        }}  
        onCancel={handleCancelar}
        visible={isModalVisible}
        >
            <Row style={{
                    justifyContent:'center'
                }}>
                <Col span={14}>
                    <h2
                    style={{
                        marginBottom:'30px'
                    }}>Crear nueva locación</h2>
                    <Form {...layout}  labelAlign={'left'}>
                        <Form.Item name={'form-location_name'} label={'Nombre locación'}
                        validateStatus={locationMapState.name.status}
                        help={locationMapState.name.status === 'error' ? locationMapState.name.msg : null}
                        style={{
                            textAlign:'left'
                        }}>
                            <Input placeholder={'Ingrese un nombre'} value={dataLocationSend.name} onChange={(e) => modifyLocationFormSend('name',e.target.value)}/>
                        </Form.Item>
                        <Form.Item name={'form-location_plus_code'} className={'ubicacion_switcher'} label={'Ubicación'}
                        validateStatus={locationMapState.plus_code.status}
                        help={locationMapState.plus_code.status === 'error' ? locationMapState.plus_code.msg : null}
                        style={{
                            textAlign:'left'
                        }}>
                            <Button style={{
                                alignSelf:'flex-start'
                            }} 
                            onClick={openMapPlusCode}
                            icon={<FontAwesomeIcon style={{marginRight:5}} icon={faArrowsAlt} />}>{dataLocationSend.plus_code === '' ? 'Asignar código plus' : dataLocationSend.plus_code}</Button>
                        </Form.Item>
                        <Form.Item name={'form-location_country'} label={'País'}
                        validateStatus={locationMapState.country.status}
                        valuePropName={'country'}
                        help={locationMapState.country.status === 'error' ? locationMapState.country.msg : null}
                        style={{
                            textAlign:'left'
                        }}>
                            <Select showSearch 
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={dataLocationSend.country} onChange={(e) => modifyLocationFormSend('country',e)}>
                                <Option key={''} value={0} 
                                >Seleccione país</Option>
                                {
                                    paises.map( (e,index) => <Option key={index} value={e.id}>{e.name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name={'form-location_city'} label={'Ciudad'}
                        validateStatus={locationMapState.city.status}
                        valuePropName={'city'}
                        help={locationMapState.city.status === 'error' ? locationMapState.city.msg : null}
                        style={{
                            textAlign:'left'
                        }}>
                            <Select showSearch 
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } 
                            value={dataLocationSend.city} onChange={(e) => modifyLocationFormSend('city',e)} >
                                <Option key={''} value={0}>Seleccione ciudad</Option>
                                {
                                    ciudades.map( (e,index) => <Option key={index} value={e.id}>{e.name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name={'form-location_address'} label={'Dirección'}
                        validateStatus={locationMapState.address.status}
                        help={locationMapState.address.status === 'error' ? locationMapState.address.msg : null}
                        style={{
                            textAlign:'left'
                        }}>
                            <Input placeholder={'Ingrese una dirección'} value={dataLocationSend.address}  onChange={(e) => modifyLocationFormSend('address',e.target.value)}/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
                
            <div
            style={{
                width:'100%',
                justifyContent:'flex-end',
                display:'flex'
            }}>
                <Button style={{
                    marginRight:'5px'
                }}
                onClick={handleCancelar}>Cancelar</Button>
                <Button onClick={enviarFormulario} type={'primary'}  icon={<PlusOutlined/>}>Crear locación</Button>
            </div>
            <div
            style={{
                display:`${isMapModalVisible ? 'flex' : 'none'}`,
                zIndex:12
            }} 
            className={'crear-cargador-map_container'}>
                <Map
                onClick={setMarkerPosition}
                center={mapCenter}
                zoom={myLocation.zoom}
                onCenterChanged={handleCenterChange}
                onZoomChanged={handleZoomChange}>
                    {
                        location_pluscode && <Marker key={'marker_plus'} visible={location_pluscode.lat != 0 && location_pluscode.lng != 0} position={location_pluscode}  />
                    }
                    {/* <MarkerClusterer>
                    {
                        (clusterer) => {

                            return <Marker key={'marker_plus'} visible={location_pluscode.lat != 0 && location_pluscode.lng != 0} position={location_pluscode} clusterer={clusterer} />;
                        }
                    }
                    </MarkerClusterer> */}
                    {
                        myLocation.visible && 
                        <InfoWindow onCloseClick={()=>setMyLocation({...myLocation,visible:false})}  position={{ lat: myLocation.lat, lng: myLocation.lng }}>
                            <p style={{marginBottom:0}}>Mi Ubicación</p>
                        </InfoWindow>
                        
                    }
                    {
                        myLocation.visible && 
                        <Circle radius={20} onClick={() => {
                            setMarkerPosition({
                                latLng:{
                                    lat: () => myLocation.lat,
                                    lng: () => myLocation.lng
                                }
                            })
                            setMyLocation({
                                ...myLocation,
                                visible:false
                            });
                        }} options={{  strokeWeight:1,strokeColor:'#ffffff',fillOpacity:'1',fillColor:"#1a73e8"}} visible={myLocation.visible}  center={{ lat: myLocation.lat, lng: myLocation.lng }}/> 
                        
                    }

                    <Button onClick={cargarMiUbicacion} style={{position:'absolute',right:20,bottom:20}}><AimOutlined/>Mi Ubicación</Button>
                </Map>
                <Row className={'plus_code_container'}>
                    <Col span={24}><h3 style={{textAlign:'left'}}>Codigo plus</h3></Col>
                    <Col span={24}><Input width={120} style={{marginBottom:0}} placeholder={'Ingresa un codigo plus'} value={plus_code} onChange={ (e) => setPlusCode(e.value)}/></Col>
                    <Col span={24}><a target='_blank' href={'https://maps.google.com/pluscodes/'}>¿Que es esto?</a></Col>
                    <Col span={24} className={'btn_footer'}>
                        <Button onClick={closeLocationPlusCode}>Cancel</Button>
                        <Button onClick={setLocationPlusCode} type='primary'>Confirmar</Button>
                    </Col>
                </Row>
            </div>
                
        </Modal>
    );
}

export default LocationForm;