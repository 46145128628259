import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Text, Brush
} from 'recharts';
import { Row, Col, Card, Table, Empty} from 'antd';
import mapColor from '@utils/helpers/colorMap'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import './style.less'

const CardEnergy = ( props ) => {
    const { dataRequest } = props;
    const [ data , setData ] = useState({
        table:[],
        graphic:[],
        connectorsLabel:[],
        colorsLabel:[],
        tableFilters:{
            location:[],
            evse:[],
            connector:[],
            type:[],
        },
        loadingTable:false,
    });
    const [ maxValue, setMaxValue ] = useState(0);

    const columns = [
        {
            title: 'Estación',
            dataIndex: 'location',
            key: 'location',
            fixed: 'left',
            filters:data.tableFilters.location,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
            sorter: (a, b) => a.location.length - b.location.length,
        },
        {
            title: 'Cargador',
            dataIndex: 'evse',
            key: 'evse',
            filters:data.tableFilters.evse,
            onFilter: (value, record) => record.evse.indexOf(value) === 0,
            sorter: (a, b) => a.evse.length - b.evse.length,
        },
        {
            title: 'Conector',
            dataIndex: 'connector',
            key: 'connector',
            filters:data.tableFilters.connector,
            onFilter: (value, record) => record.connector.indexOf(value) === 0,
            sorter: (a, b) => a.connector.length - b.connector.length,
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            filters:data.tableFilters.type,
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            sorter: (a, b) => a.type.length - b.type.length,
        },
        {
            title: 'Energía',
            dataIndex: 'energy',
            key: 'energy',
            sorter: (a, b) => a.energy.length - b.energy.length,
            render: (obj) => (`${obj}kWh`)
        },
    ]

    const simulate_data = () => {
        const data = [];
        
        for(let i = 0 ; i < 20 ; i++){
            data.push({
                name: 'Page A_'+i,
                disponible: Math.random() * (100 - 0 ) + 0,
                fallo: Math.random() * (100 - 0 ) + 0,
                amt: Math.random() * (100 - 0 ) + 0,
            },);
        }

        return data;
    }

    const StaticXAxis = () => {
        const dxIni = 0;
        const widthCustom = 100;
        const widthSteps = widthCustom/10;
        return (
            <div className={'x-axis-wrapper'}>
                <svg className="recharts-surface svg-custom-xaxis" width="100%" >
                    <g className="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis"  >
                        <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="800" height="30" x={dxIni}    viewBox="0 0 800 300" stroke="#666" fill="none" x1={dxIni}  x2={`${widthCustom}%`}  ></line>
                        <g className="recharts-cartesian-axis-ticks">
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*0}%`} y1="5"   x2={`${widthSteps*0}%`} y2="0"  ></line>
                                <g >
                                    <text dx={dxIni} dy="16" textAnchor="left" fill="#666">{parseInt(maxValue*0)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*1}%`} y1="5"  x2={`${widthSteps*1}%`} y2="0" ></line>
                                <g >
                                    <text dx={`${widthSteps*1}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.1)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*2}%`} y1="5"   x2={`${widthSteps*2}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*2}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.2)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*3}%`} y1="5"  x2={`${widthSteps*3}%`} y2="0"  ></line>
                                <g >
                                    <text dx={`${widthSteps*3}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.3)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*4}%`} y1="5"  x2={`${widthSteps*4}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*4}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.4)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*5}%`} y1="5"  x2={`${widthSteps*5}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*5}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.5)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*6}%`} y1="5"  x2={`${widthSteps*6}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*6}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.6)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*7}%`} y1="5"  x2={`${widthSteps*7}%`}  y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*7}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.7)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*8}%`} y1="5"  x2={`${widthSteps*8}%`}  y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*8}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.8)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"   viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps*9}%`} y1="5"  x2={`${widthSteps*9}%`} y2="0"  ></line>
                                <g>
                                    <text dx={`${widthSteps*9}%`} dy="16" textAnchor="middle" fill="#666">{parseInt(maxValue*0.9)}</text>
                                </g>
                            </g>
                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0"  stroke="#666" fill="none" x1={`${widthSteps*10}%`} y1="5"  x2={`${widthSteps*10}%`} y2="0" ></line>
                                <g>
                                    <text dx={`${widthSteps*10}%`} dy="16" textAnchor="end" fill="#666">{parseInt(maxValue)}</text>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }

    const CustomizedAxisTick = props => {
        const { x, y, payload } = props
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text dy={16} textAnchor='middle' fill='#666'>{payload.value}%</text>
          </g>
        )
    
    
    }

    const CustomizedYAxisTick = props => {
        const { x, y, payload } = props
        const yname = `${payload.value}`.split('/');
        if(yname.length > 0){
            const location = yname[0];
            const conn = yname[1];
            return (
            <g transform={`translate(${x},${y})`}>
                <text dy={-3} textAnchor='end' fill='#666' >{location}</text>
                <text dy={11} textAnchor='end' fill='#666' >{conn}</text>
            </g>
            )
        }else{
            return(
                <g transform={`translate(${x},${y})`}>
                    <text dy={-3} textAnchor='end' fill='#666' >{'Desconocido'}</text>
                    <text dy={11} textAnchor='end' fill='#666' >{'Desconocido'}</text>
                </g>
            )
        }
        
    }

    useEffect(() => {
        console.log(dataRequest.data);
        if(dataRequest.data && dataRequest.data.length > 0){
            const arrayData = dataRequest.data;
            const arrayConnectors = [];
            const arrayGraphicConnectors = [];
            const maxArrayConnectors = new Set();
            const maxArrayColors = [];
            let maxV = 0;
            const locTemp = new Set();
            const evseTemp = new Set();
            const conTemp = new Set();
            const typeTemp = new Set();

            for(let i = 0 ; i < arrayData.length ; i++){
                let location = arrayData[i];
                if(!locTemp.has(location.location_name)) locTemp.add(location.location_name);
                if(!evseTemp.has(location.evse_uid)) evseTemp.add(location.evse_uid);
                let connectors = location.connectors;
                let gObject = {
                    name: `${location.location_name}/${location.evse_uid}`,
                }

                for(let c = 0 ; c < connectors.length ; c++){
                    let connector = connectors[c];
                    if(!conTemp.has(connector.conn_id)) conTemp.add(connector.conn_id);
                    if(!typeTemp.has(connector.standard)) typeTemp.add(connector.standard);

                    if(!maxArrayConnectors.has(connector.conn_id)){
                        // const selected = parseInt((Math.random() * (mapColor().length - 0 ) + 0));
                        const color = mapColor()[c % mapColor().length]
                        maxArrayConnectors.add(connector.conn_id);
                        maxArrayColors.push(color);
                    }
                    arrayConnectors.push({
                        key:`${i}${c}`,
                        location:location.location_name,
                        evse:location.evse_uid,
                        connector:connector.conn_id,
                        type:connector.standard,
                        energy:connector.energy
                    });
                    
                    // gObject[`conector_${connector.conn_id}`] = (Math.random() * (100 - 0 ) + 0);//connector.energy;
                    gObject[`conector_${connector.conn_id}`] = connector.energy;
                    if(maxV < connector.energy) maxV = connector.energy;
                }

                arrayGraphicConnectors.push(gObject);
            }

            setMaxValue(maxV);

            setData({
                ...data,
                table:arrayConnectors,
                tableFilters:{
                    location:[...locTemp].map((e) => ({text:e,value:e}) ),
                    evse:[...evseTemp].map((e) => ({text:e,value:e}) ),
                    connector:[...conTemp].map((e) => ({text:e,value:e}) ),
                    type:[...typeTemp].map((e) => ({text:e,value:e}) ),
                },
                loadingTable:false,
                graphic:arrayGraphicConnectors,
                connectorsLabel:[...maxArrayConnectors],
                colorsLabel:[...maxArrayColors]
            });
        }
        
    },[dataRequest.data]);

    return(
        <Row>
            <Col sm={24} lg={12}  className={['card-energy','metricas-space']}>
                <Card>
                    <h2 className={'energy-title'}>Energía entregada por conector</h2>
                    <StaticXAxis/>
                    <div className={'div-graphic'}>
                        <ResponsiveContainer className={'graphic-bar'} minHeight={(30 * (data.graphic.length+4))}>
                            <BarChart
                            // width={500}
                            // height={500}
                            data={data.graphic}
                            layout={'vertical'}
                            >
                                <CartesianGrid  stroke="#f5f5f5" />
                                <XAxis hide axisLine tick={CustomizedAxisTick} domain={[0,100]} ticks={[0,10,20,30,40,50,60,70,80,90,100]} type={'number'} />
                                <YAxis  interval={0} tick={CustomizedYAxisTick} width={110}  dataKey={'name'} type={'category'}/>
                                <Tooltip />
                                <Legend  align={'left'} verticalAlign={'top'} height={60} />
                                {
                                    data.connectorsLabel.map((v,i) => {
                                        
                                        return (<Bar key={i} dataKey={`conector_${v}`} name={`Conector ${v}`} stackId="a" fill={`${data.colorsLabel[i]}`}    />)
                                    })
                                }
                                {/* <Bar dataKey="conector_1" name={'Conector 1'} stackId="a" fill="#52a7da"    />
                                <Bar dataKey="conector_2" name={'Conector 2'} stackId="a" fill="#0dc392" /> */}
                                {/* <Bar dataKey="desconocido" name={'Desconocido'} stackId="a" fill="#ff8838" /> */}
                            </BarChart>
                        </ResponsiveContainer>
                        
                    </div>
                </Card>
            </Col>
            <Col sm={24} lg={12} className={['card-energy','metricas-space']} >
                <Table 
                    loading={!dataRequest ? data.loadingTable : dataRequest.loading}
                    dataSource={data.table}
                    columns={columns}
                />
            </Col>
        </Row>
    );
}


export default CardEnergy;