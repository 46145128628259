import { get, post, put } from './management';


const obtener_cargas = ( {locations_id,start_date,end_date} ) => {
    let locationsSelected = ``;
    for(let i = 0 ; i < locations_id.length ; i++){
        locationsSelected += (i==0 ? `${locations_id[i]}`:`,${locations_id[i]}`);
    }
    let urlTemp = `/analitycs/recharges?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}`;
    // console.log(urlTemp);
    return get(urlTemp,true);
}

const obtener_usos = ( {locations_id,start_date,end_date} ) => {
    let locationsSelected = ``;
    for(let i = 0 ; i < locations_id.length ; i++){
        locationsSelected += (i==0 ? `${locations_id[i]}`:`,${locations_id[i]}`);
    }
    let urlTemp = `/analitycs/usages?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}`;
    // console.log(urlTemp);
    return get(urlTemp,true);
}

const obtener_tarjetas = ( {client_id,start_date,end_date} ) => {
    let urlTemp = `/analitycs/cards?client_id=${client_id}&start_date=${start_date}&end_date=${end_date}`;
    // console.log(urlTemp);
    return get(urlTemp,true);
}

const obtener_disponibilidad = ( {locations_id,start_date,end_date} ) => {
    let locationsSelected = ``;
    for(let i = 0 ; i < locations_id.length ; i++){
        locationsSelected += (i==0 ? `${locations_id[i]}`:`,${locations_id[i]}`);
    }
    let urlTemp = `/analitycs/availabilty?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}`;
    // console.log(urlTemp);
    return get(urlTemp,true);
}

const obtener_energia_conector = ( {locations_id,start_date,end_date} ) => {
    let locationsSelected = ``;
    for(let i = 0 ; i < locations_id.length ; i++){
        locationsSelected += (i==0 ? `${locations_id[i]}`:`,${locations_id[i]}`);
    }
    let urlTemp = `/analitycs/usage-time-energy?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}`;
    // console.log(urlTemp);
    return get(urlTemp,true);
}

export default { obtener_energia_conector,obtener_cargas, obtener_usos, obtener_tarjetas, obtener_disponibilidad }