import { apiVehicles2 } from '@utils/services';
import mapVehiclesWithSocketData from '@utils/helpers/mapVehiclesWithSocketData';
import constants from './constants';

function setPlaca(placa) {
  const { SET_PLACA } = constants;
  return {
    type: SET_PLACA,
    payload: { placa },
  };
}

function fetchVehicleByPlaca(placa) {
  const {
    VEHICLE_DATA_FETCHING: fetching,
    VEHICLE_DATA_SUCCESS: success,
    VEHICLE_DATA_ERROR: error,
  } = constants;

  return (dispatch) => {
    dispatch({ type: fetching, payload: { placa, vehicleLoading: true } });
    apiVehicles2
      .getVehicleByLic(placa)
      .then((result) => {
        const tempData = Array.isArray(result)
          ? result.find(({ license_plate: lic }) => placa === lic)
          : result;

        const { [placa]: vehicleData } = mapVehiclesWithSocketData([tempData]);
        dispatch({ type: success, payload: { vehicleData, vehicleLoading: false } });
      })
      .catch((vehicleError) =>
        dispatch({ type: error, payload: { vehicleError, vehicleLoading: false } })
      );
  };
}

function resetState() {
  const { RESET_STATE } = constants;
  return {
    type: RESET_STATE,
  };
}

function updateBySocket(vehicleData) {
  const { SET_VEHICLE_DATA } = constants;
  const { lic } = vehicleData;

  return (dispatch, getState) => {
    const placaTarget = getState().vehicleView.placa;
    if (placaTarget !== lic) return;

    dispatch({
      type: SET_VEHICLE_DATA,
      payload: { vehicleData },
    });
  };
}

export default { setPlaca, fetchVehicleByPlaca, resetState, updateBySocket };
