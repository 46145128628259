import { ExportToCsv } from 'export-to-csv';

const export_to_csv = ( filterData, rawData , title) => {
    let options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:`${title}`,
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: `${title}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    }

    const csvExporter = new ExportToCsv(options);
    if(filterData && filterData.length > 0){
        csvExporter.generateCsv(filterData);
    }else{
        csvExporter.generateCsv(rawData);
    }
    
}


export { export_to_csv };