exports.MAP_FETCHING_VEHICLES = 'MAP_FETCHING_VEHICLES';
exports.MAP_SUCCESS_VEHICLES = 'MAP_SUCCESS_VEHICLES';
exports.MAP_ERROR_VEHICLES = 'MAP_ERROR_VEHICLES';
exports.MAP_UPDATE_VEHICLE = 'MAP_UPDATE_VEHICLE';
exports.MAP_FETCH_GRAPH = 'MAP_FETCH_GRAPH';
exports.MAP_SUCCESS_GRAPH = 'MAP_SUCCESS_GRAPH';
exports.MAP_ERROR_GRAPH = 'MAP_ERROR_GRAPH';
exports.MAP_UPDATE_GRAPH = 'MAP_UPDATE_GRAPH';
exports.MAP_TRACKING_VEHICLE = 'MAP_TRACKING_VEHICLE';
exports.MAP_ADD_INSTANCE = 'MAP_ADD_INSTANCE';
exports.FOCUS_VEHICLE = 'FOCUS_VEHICLE';
exports.READY_USE_SOCKET = 'READY_USE_SOCKET';
