function sortValuesVehiclesByStatus(vehicles) {
  const movementOnFilter = vehicles
    .filter(({ sta }) => sta === 'ON')
    .sort(({ spe: s1 }, { spe: s2 }) => s2 - s1);

  const chargingFilter = vehicles.filter(({ sta }) => sta === 'CHA');
  const offFilter = vehicles.filter(({ sta }) => sta === 'OFF');
  const disconnectedFilter = vehicles.filter(({ sta }) => sta === 'BAD');

  const sortedVehicles = [
    ...movementOnFilter,
    ...chargingFilter,
    ...offFilter,
    ...disconnectedFilter,
  ];

  return sortedVehicles;
}

export default sortValuesVehiclesByStatus;
