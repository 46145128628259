/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Marker from '@components/mapa/Marker';
import actions from '@store/reducers/tableroGeneral/mapView/actions';

function MapMarkerList({ map }) {
  const dispatch = useDispatch();
  const adjustMapCam = useRef(true);
  const vehiclesData = useSelector(({ mapView }) => mapView.vehiclesData);
  const focusVehicle = useSelector(({ mapView }) => mapView.focusVehicle);

  useEffect(
    () => {
      if (!vehiclesData || !map || !adjustMapCam.current) return;

      const bounds = new window.google.maps.LatLngBounds();
      Object.values(vehiclesData).forEach(({ lat, lon: lng }) => {
        if (!lat || !lng || (lat === 0 && lng === 0)) return;
        bounds.extend({ lat, lng });
      });

      map.fitBounds(bounds);
      map.panBy(-120, 0);
      adjustMapCam.current = false;
    },
    // eslint-disable-next-line
    [vehiclesData, map]
  );

  useEffect(
    () => {
      if (!focusVehicle || !map) return;
      const { lat, lon: lng } = focusVehicle;
      if (!lat || !lng) return;

      map.setZoom(20);
      map.panTo({ lat, lng });
      map.panBy(-120, 0);
    },
    // eslint-disable-next-line
    [focusVehicle]
  );

  return (
    <>
      {vehiclesData &&
        Object.values(vehiclesData).map((item, index) => {
          const key = `marker_${index}`;
          const { lic } = item;
          const { lic: focusLic } = focusVehicle || {};

          return (
            <Marker
              key={key}
              item={item}
              activeInfo={focusLic === lic}
              infoWindowOnCloseClick={() => dispatch(actions.setFocusVehicle(null))}
            />
          );
        })}
    </>
  );
}

MapMarkerList.propTypes = {
  map: PropTypes.objectOf(PropTypes.any),
};

MapMarkerList.defaultProps = {
  map: null,
};

export default MapMarkerList;
