import React from 'react';
import { UserOutlined } from '@ant-design/icons';

const path = '/usuarios';

const usuariosMenu = {
  path,
  name: 'Usuarios',
  icon: <UserOutlined />,
  isSubMenu: true,
  children: [
    {
      path: `${path}/conductores`,
      name: 'Conductores',
      icon: <UserOutlined />,
      key: 'item-2',
    },
    {
      path: `${path}/administrador-flotas`,
      name: 'Administrador de flotas',
      icon: <UserOutlined />,
      key: 'item-3',
    },
  ],
};

export default usuariosMenu;
