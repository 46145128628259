import React from 'react';
import { Typography, Card, Divider, Statistic, Space } from 'antd';

const { Title, Paragraph } = Typography;

const simulateDate = [
  {
    value: 3,
    title: 'frenos\nbruscos',
  },
  {
    value: 15,
    title: 'sobre\n60km',
  },
  {
    value: 7,
    title: 'Puertas\nabiertas',
  },
  {
    value: 15,
    title: 'Bajo\nvoltaje',
  },
  {
    value: 15,
    title: 'Error\npuerta',
  },
  {
    value: 15,
    title: 'Error\nfrenos',
  },
  {
    value: 15,
    title: 'Temp\ncontrolador',
  },
  {
    value: 15,
    title: 'Errores\nAC',
  },
];

const VehicleInfo = () => {
  return (
    <Card className="vehicle-info">
      <Title level={2}>Marca Linea</Title>
      <Title level={4}>BKN353</Title>
      <Paragraph>Identificador: CH10000110</Paragraph>
      <Divider />

      <div className="values">
        {simulateDate.map(({ value, title }, index) => {
          const key = title + index;
          return (
            <Space direction="horizontal" align="center" key={key}>
              <div className="value-item">
                <Statistic valueStyle={{ fontSize: 21 }} value={value} />
              </div>

              <div className="title-item">
                {title.split('\n').map((item, i) => {
                  const keyTitle = item + i;
                  return <Paragraph key={keyTitle}>{item}</Paragraph>;
                })}
              </div>
            </Space>
          );
        })}
      </div>
    </Card>
  );
};

export default VehicleInfo;
