import React, { useCallback, useState } from 'react';
import { Card, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { PieChart, Pie, Cell, Label, Tooltip, Legend } from 'recharts';

import actions from '@store/reducers/tableroGeneral/mapView/actions';
import useDataChart from './hooks/useDataChart';
import PieActiveShape from './components/PieActiveShape';

function GeneralChart() {
  const dispatch = useDispatch();
  const { loading, dataPie } = useDataChart();
  const { data, total, colors } = dataPie;

  const [activeIndex, setActiveIndex] = useState(null);

  const onClickPie = useCallback(
    ({ sta }, index) => {
      const finalIndex = index === activeIndex ? null : index;
      setActiveIndex(finalIndex);

      dispatch(actions.setPieStatusSelected(finalIndex !== null ? sta : null));
    },
    // eslint-disable-next-line
    [activeIndex]
  );

  return (
    <Card className="chart">
      {loading && <Spin tip="Cargando gráfica" />}
      {data && (
        <PieChart width={300} height={160}>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={75}
            dataKey="value"
            onClick={onClickPie}
            activeIndex={activeIndex}
            activeShape={PieActiveShape}
          >
            {data.map(({ name }, index) => (
              <Cell key={name} fill={colors[index % colors.length]} />
            ))}

            <Label
              fill="#EE7400"
              value={total}
              position="centerBottom"
              style={{ transform: 'translateY(-5px)' }}
            />
            <Label fill="#2D2B2B" value="Vehículos" position="centerTop" />
          </Pie>
          <Tooltip />
          <Legend align="left" verticalAlign="middle" layout="vertical" />
        </PieChart>
      )}
    </Card>
  );
}

export default GeneralChart;
