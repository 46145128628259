/* eslint-disable no-undef */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'

import { cargadoresCPO } from '@utils/menus/main/itemsMenu';
import Breadcrumb from '@containers/Breadcrumb';


import {
  ResumenGeneral,
  TablaCargadores,
  Filtros
} from './components';

import './style.less';
import CargadorView from '../CargadorView';

const AdministrarCargadores = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
 

  const match = useRouteMatch();
  const cargadorRoute = `${match.path}/:cargadorId`;

  const { name, children } = cargadoresCPO;  
  const { name: childName } = children.find((item) => item.path === path);
  const breadcrumbData = [
    [null, name], 
    [null, childName],   
  ];

  return (    
    

    <Switch>
      {/* <Route path={tableRoute} component={TablesView} /> */}
      <Route path={cargadorRoute} component={CargadorView} />
      <Route path={match.path} render={() => (
        <div id="admin-cargadores">      
          <Breadcrumb breadcrumbData={breadcrumbData} />
          <div className="general-grid">          
            <ResumenGeneral />
            <TablaCargadores />
          </div>
        </div>
      )} />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default AdministrarCargadores;
