import isVehicleDisconnect from '@utils/helpers/isVehicleDisconnect';

const mapVehiclesWithSocketDate = (vehicles) => {
  const dict = vehicles.reduce((result, item) => {
    const {
      license_plate: lic,
      status: sta,
      tracker_data: trackerData,
      model: mod,
      tracker: dev,
      ...rest
    } = item;
    const { cell_temperatures: ct, speed: spe, time: ts, ...restTracker } = trackerData || {};
    const updateResult = result;

    const finalSta = isVehicleDisconnect({ sta, ts }) ? 'BAD' : sta;
    updateResult[lic] = { lic, mod, ct, spe, ts, dev, sta: finalSta, ...restTracker, ...rest };

    return updateResult;
  }, {});

  return dict;
};

export default mapVehiclesWithSocketDate;
