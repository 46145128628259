import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { apiSessions } from '@utils/services/cpo'
import filters from '@utils/services/cpo/filters';
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import { ExportToCsv } from 'export-to-csv';
const TablaRecargas = ( props ) => {

    const { 
        filterDates, 
        dataSource,
        filteredTable , setFilteredTable,
        filtersRecargas , setFiltersRecargas
    } = props; 

    

    
    
    const cargarColumnas = () => {
        return [
            {
                title: 'Ciudad',
                dataIndex: 'city',
                key: 'city',
                sorter: (a, b) => a.city.length - b.city.length,
                filters: filtersRecargas.ciudades,
                filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.city ? filteredTable.filteredInfo.city : null,
                onFilter: (value, record) => record.city == value,
            },
            {
                title: 'Estación',
                dataIndex: 'location',
                key: 'location',
                sorter: (a, b) => a.location.length - b.location.length,
                filters: filtersRecargas.estaciones,
                filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.location ? filteredTable.filteredInfo.location : null,
                onFilter: (value, record) => record.location == value,
            },
            {
                title: 'Cargador',
                dataIndex: 'evse',
                key: 'cargador',
                sorter: (a, b) => a.evse.length - b.evse.length,
                filters: filtersRecargas.cargadores,
                filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.cargador ? filteredTable.filteredInfo.cargador : null,
                onFilter: (value, record) => record.evse ==  value,
            },
            {
                title: 'Conector',
                dataIndex: 'connector',
                key: 'connector',
                sorter: (a, b) => a.connector.length - b.connector.length,
                filters: filtersRecargas.conector,
                filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.connector ? filteredTable.filteredInfo.connector  : null,
                onFilter: (value, record) => record.connector == value,
            },
            {
                title: 'RFID',
                dataIndex: 'rfid',
                key: 'rfid',
                sorter: (a, b) => a.rfid.length - b.rfid.length,
                filters: filtersRecargas.rfid,
                filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.rfid ? filteredTable.filteredInfo.rfid : null,
                onFilter: (value, record) => record.rfid == value,
                // sortOrder: filteredTable.sortedInfo.columnKey === 'rfid' && filteredTable.sortedInfo.order,
            },
            {
                title: 'Inicio',
                dataIndex: 'start',
                key: 'start',
                sorter: (a, b) => a.start.length - b.start.length,
            },
            {
                title: 'Fin',
                dataIndex: 'end',
                key: 'end',
                sorter: (a, b) => a.end.length - b.end.length,
            },
            {
                title: 'Duración',
                dataIndex: 'duration',
                key: 'duration',
                sorter: (a, b) => a.duration.length - b.duration.length,
            },
            {
                title: 'Energía',
                dataIndex: 'energy',
                key: 'energy',
                sorter: (a, b) => a.energy.length - b.energy.length,
            },
        ];
    }
    let columnsBase = cargarColumnas();
    const [ columns , setColumns ] = useState(columnsBase);

    

    useEffect( () => {
        columnsBase = cargarColumnas();
        setColumns(columnsBase);
    },[filtersRecargas,filteredTable]);

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredTable({
          filteredInfo: filters,
          sortedInfo: sorter,
          filteredData:extra.currentDataSource
        });
    };

    


    return(
        <Table 
        onChange={handleChange}
        dataSource={dataSource.data} 
        loading={dataSource.loading} 
        columns={columns} />
    );
}

TablaRecargas.defaultProps = {
    filterDates:{
        start_date:new Date(),
        end_date:new Date()
    }
}

export default TablaRecargas;