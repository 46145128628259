import React from 'react';
import { Card, Spin, Empty, Alert } from 'antd';
import PropTypes from 'prop-types';

import ChartRangesStatus from '@components/canvaGraph/ChartRangesStatus';
import normalizeData from './helpers/normalizeData';

function HistorialHabitos(props) {
  const { journeys, charges, loading, error, dateRange } = props;
  const chartData = normalizeData(journeys, charges, dateRange);

  return (
    <Card
      title="Historial de hábitos de conducción (Hoy)"
      extra={!loading ? null : <Spin size="small" />}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {!error && !chartData && <Empty description="Esperando información" />}

        {error && (
          <Alert
            message="Error"
            description="Lo sentimos, hubo un error al consultar la información"
            type="error"
            showIcon
          />
        )}

        {chartData && <ChartRangesStatus dateRange={dateRange} rangesData={[chartData]} />}
      </div>
    </Card>
  );
}

HistorialHabitos.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.any),
  charges: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  error: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.arrayOf(PropTypes.any),
};

HistorialHabitos.defaultProps = {
  journeys: null,
  charges: null,
  loading: false,
  error: null,
  dateRange: null,
};

export default HistorialHabitos;
