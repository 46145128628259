import constants from './constants';

const initState = {
  vehiclesLoading: false,
  vehiclesError: null,
  vehiclesData: null,
  focusVehicle: null,
  pieStatusSelected: null,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.VEHICLES_FETCHING:
    case constants.VEHICLES_ERROR:
    case constants.VEHICLES_SUCCESS:
    case constants.FOCUS_VEHICLE:
    case constants.UPDATE_VEHICLE_BY_SOCKET:
    case constants.PIE_STATUS_SELECTED:
    case constants.UPDATE_VEHICLES_STATUS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
