import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Statistic , message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarked, faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { apiGeneral } from '@utils/services/cpo';
import { useSelector } from 'react-redux';
import { Spanish } from '@utils/helpers/dictIdiomas'
import './style.less';

const { Paragraph } = Typography;

const DataCards = () => {
  const textLanguage = Spanish({screen:'cpo-tablero'})
  const user = useSelector((state) => state.user);
  const [state, setState] = useState({ general: [], loading: false });

  useEffect(() => {
    setState({ ...state, loading: true });
    apiGeneral
      .general(user.user_id)
      .then((general) => {   
        general = [
          {
            value: general.total_locations,
            title: 'Total estaciones',
            icon: faMapMarked,
            color: 'black'
          },
          {
            value: general.total_evses,
            title: 'Total cargadores',
            icon: faChargingStation,
            color: 'black'
          },
          {
            value: general.evses_out_service,
            title: 'Fuera de servicio',
            icon: faChargingStation,
            color: 'orange'
          },
          {
            value: general.evses_error,
            title: 'Fallo de conexión',
            icon: faChargingStation,
            color: 'grey'
          },
          {
            value: general.evses_available,
            title: 'Disponibles',
            icon: faChargingStation,
            color: 'green'
          },
          {
            value: general.evses_chargin,
            title: 'Cargando',
            icon: faChargingStation,
            color: '#5dc4bf'
          },
        ]; 
        setState({ ...state, general });
      })
      .catch((error) => {
        setState({ ...state, isFething: false });
        message.error(textLanguage.EVSES_INFO_FAIL);
      });
    // eslint-disable-next-line
  }, []);

  const { general, loading } = state;

  return (
    <div className="data-cards">
      {general.map(({ value, title, icon, color }, index) => {
        const key = title + index;
        return (
          <Card key={key} loading={loading}>
            <Space direction="vertical" size="large">
            <FontAwesomeIcon className="anticon" style={{ color: color, fontSize: 21, minWidth: "auto" }} icon={icon} />
              <div>
                <Statistic valueStyle={{ fontSize: 28, color: color }} value={value} />
              </div>

              <Paragraph>{title}</Paragraph>
            </Space>
          </Card>
        );
      })}
    </div>
  );
};

export default DataCards;
