/* eslint-disable no-continue */
import moment from 'moment';

function missingRanges(rangesArray, totalRange) {
  const sortRanges = rangesArray.sort(([startA], [startB]) => {
    if (startA.isSame(startB)) return 0;
    return startA.isBefore(startB) ? -1 : 1;
  });

  let rangeGlobal = totalRange.map((item) => item.valueOf());
  const missingData = [];

  for (let i = 0; i < sortRanges.length; i += 1) {
    const range = sortRanges[i];
    const [startG, endG] = rangeGlobal;
    const [start, end] = range.map((item) => item.valueOf());

    rangeGlobal = [end, endG];
    if (startG === start) continue;

    missingData.push([startG, start]);

    if (i !== sortRanges.length - 1) continue;
    if (endG > end) missingData.push([end, moment().valueOf()]);
  }

  const mapToMoments = missingData.map(([start, end]) => [moment(start), moment(end)]);
  return mapToMoments;
}

function alternive() {}

export { missingRanges, alternive };
