import constants from '../constants/rendimientoFlotasConst';

const initState = {
  vehicles: null,
  fetchingVehicles: false,
  errorMessage: null,
  vehiclesSelected: null,
  dateRangeInput: null,
  groupIdSelected: null,
  dateRangeType: null,
  // --> Fetchings
  fetchingDistanceChart: false,
  fetchingEficiencia: false,
  // --> Errors charts
  errorDistanceChart: null,
  errorEficienciaChart: null,
  // --> data charts
  dataDistanceChart: null,
  dataEficienciaChart: null,
  granularity: 'days',
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.FETCHING_VEHICLES:
      return {
        ...state,
        fetchingVehicles: true,
      };

    case constants.ERROR_VEHICLES:
      return {
        ...state,
        fetchingVehicles: false,
        errorMessage: payload,
      };

    case constants.SUCCESS_VEHICLES:
      return {
        ...state,
        fetchingVehicles: false,
        errorMessage: null,
        vehicles: payload,
        vehiclesSelected: Object.keys(payload),
      };

    case constants.UPDATE_VEHICLES_SELECTED:
      return {
        ...state,
        vehiclesSelected: payload,
      };

    case constants.SET_RANGE_DATE:
      return {
        ...state,
        dateRangeInput: payload.range,
        granularity: payload.granularity,
        dateRangeType: payload.typeDate,
      };

    case constants.FETCHING_DISTANCE_CHART:
      return {
        ...state,
        fetchingDistanceChart: true,
      };

    case constants.ERROR_DISTANCE_CHART:
      return {
        ...state,
        fetchingDistanceChart: false,
        errorDistanceChart: payload,
      };

    case constants.SUCCESS_DISTANCE_CHART:
      return {
        ...state,
        fetchingDistanceChart: false,
        errorDistanceChart: null,
        dataDistanceChart: payload,
      };

    case constants.UPDATE_VEHICLE:
      return {
        ...state,
        vehicles: { ...state.vehicles, ...payload },
      };

    case constants.FETCHING_EFICIENCIA_RF:
      return {
        ...state,
        fetchingEficiencia: true,
      };

    case constants.SUCCESS_EFICIENCIA_RF:
      return {
        ...state,
        fetchingEficiencia: false,
        errorEficienciaChart: null,
        dataEficienciaChart: payload,
      };

    case constants.ERROR_EFICIENCIA_RF:
      return {
        ...state,
        fetchingEficiencia: false,
        errorEficienciaChart: payload,
      };

    case constants.SELECT_GROUP_ID_RF:
      return {
        ...state,
        groupIdSelected: payload,
      };

    default:
      return state;
  }
};

export default reducer;
