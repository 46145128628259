import React,{ useEffect, useState } from 'react';
import { Card, Table, } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlug,faBolt, faClock, faMapMarkerAlt, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle, faCircle, faBuilding } from '@fortawesome/free-solid-svg-icons';
import GetStatusDict from '@utils/helpers/estadosConector';
const TablaConectores = ( props ) => {
    const { conectores,loading } = props;

    const [ data , setData ] = useState([]);

    useEffect(()=> {
        setData(conectores.map((v,i) => {
            return {
                key:i,
                conector:v.standard,
                estado:v.status_connector,
                potencia:`${v.max_voltage}kW (${v.max_amperage}A)`,
                rfid:v.last_charger_data.card_code ? `${v.last_charger_data.card_code}` : `No registra`,
                last_start:v.last_charger_data.start_date_time,
                last_start_millis:new Date(v.last_charger_data.start_date_time).getTime(),
                last_end:v.last_charger_data.end_date_time,
                last_end_millis:new Date(v.last_charger_data.last_end_millis).getTime(),
                energia:v.last_charger_data.total_energy ? `${v.last_charger_data.total_energy}kWh` : `No registra`,
                duracion:v.last_charger_data.total_period_time_secs
            }
        }));
    },[conectores]);

    const columns = [
        {
            title: 'Conector',
            dataIndex: 'conector',
            key: 'conector',
            sorter: (a, b) => {
                return `${a.conector}`.localeCompare(b.conector);   
            },
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            sorter: (a, b) => `${a.estado}`.localeCompare(b.estado),
            render:(v)=>{
                return(
                    <p><FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={`${GetStatusDict(v)[1]}`}  icon={faCircle} />{GetStatusDict(v)[0]}</p>
                )
            }
        },
        {
            title: 'Potencia',
            dataIndex: 'potencia',
            key: 'potencia',
            sorter: (a, b) => a.potencia - b.potencia,
        },
        {
            title: 'RFID',
            dataIndex: 'rfid',
            key: 'rfid',
            sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
        },
        {
            title: 'Ultimo Inicio',
            dataIndex: 'last_start',
            key: 'last_start',
            sorter: (a, b) => a.last_start_millis - b.last_start_millis,
        },
        {
            title: 'Ultimo Fin',
            dataIndex: 'last_end',
            key: 'last_end',
            sorter: (a, b) => a.last_end_millis - b.last_end_millis,
        },
        {
            title: 'Energía',
            dataIndex: 'energia',
            key: 'energia',
            sorter: (a, b) => a.energia - b.energia,
        },
        {
            title: 'Duración',
            dataIndex: 'duracion',
            key: 'duracion',
            sorter: (a, b) => a.duracion - b.duracion,
        },
    ]

    return(
        <Card key={'card-tabla-conectores'}>
            <Table key={'tabla-conectores'}
                columns={columns}
                dataSource={data}
                loading={loading}
            />
        </Card>
    );
}


export default TablaConectores;