import constants from './constants';

const initState = {
  placa: null,
  vehicleData: null,
  vehicleError: null,
  vehicleLoading: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_PLACA:
    case constants.SET_VEHICLE_DATA:
    case constants.VEHICLE_DATA_ERROR:
    case constants.VEHICLE_DATA_SUCCESS:
    case constants.VEHICLE_DATA_FETCHING:
      return {
        ...state,
        ...payload,
      };

    case constants.RESET_STATE:
      return initState;

    default:
      return state;
  }
};
