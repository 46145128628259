import React from 'react';
import { Space, Typography, Statistic, Divider } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const DataItem = (props) => {
  const { title, value } = props;

  return (
    <div className="data-item">
      <Paragraph>{title}</Paragraph>
      <Statistic value={value} />
    </div>
  );
};

DataItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const CommonDivider = () => <Divider type="vertical" style={{ height: 40 }} />;

const ResumenGeneral = () => {
  return (
    <div className="resumen-general">
      <Space size="large">
        <DataItem title="Total vehículos" value={320} />
        <CommonDivider />
        <DataItem title="Total en movimiento" value={120} />
        <CommonDivider />
        <DataItem title="Detenidos" value={64} />
        <CommonDivider />
        <DataItem title="Cargando" value={64} />
        <CommonDivider />
        <DataItem title="Apagados" value={20} />
        <CommonDivider />
        <DataItem title="Sin conexión" value={10} />
      </Space>
    </div>
  );
};

export default ResumenGeneral;
