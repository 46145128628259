import React from 'react';
import PropTypes from 'prop-types';
import { Card, Space, Divider, Button } from 'antd';
import { connect } from 'react-redux';

import { vehicleActions } from '../../../store/actions';
import VehicleInfoItem from '../components/VehicleInfoItem';

const GroupItems = ({ className, data }) => {
  return (
    <div>
      {data.map((item) => {
        const { name, value } = item;
        return (
          <VehicleInfoItem
            key={name}
            className={`${className}__data-item`}
            name={name}
            value={value}
          />
        );
      })}
    </div>
  );
};

GroupItems.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const VehicleInfo = (props) => {
  const { className, fetchingVehicleDetail } = props;

  const dataPersonal = [
    {
      name: 'Identificador',
      value: 'CH10000110',
    },
    {
      name: 'Placa',
      value: 'FWQ388',
    },
    {
      name: 'Marca y línea',
      value: 'Yutong / ZK6125CHEVG',
    },
    {
      name: 'Administrador',
      value: 'German Arias',
    },
    {
      name: 'Conductor actual',
      value: 'Nombre y apellido',
    },
    {
      name: 'Inicio de turno:',
      value: '20/05/2020, 14:57',
    },
  ];

  const dataVehicle = [
    {
      name: 'Estado del vehículo',
      value: 'Estado del vehículo:',
    },
    {
      name: 'Aire acondicionado',
      value: 'Encendido',
    },
    {
      name: 'Puertas',
      value: 'Cerradas',
    },
    {
      name: 'Kilometraje:',
      value: '230 km',
    },
  ];

  const dataDevice = [
    {
      name: 'Dispositivo',
      value: 'BiN001',
    },
    {
      name: 'Firmware',
      value: '2.1',
    },
    {
      name: 'Última petición',
      value: '20/05/2020, 14:57',
    },
  ];

  // useEffect(() => {
  //   if (Object.entries(vehicleDetail).length === 0 && !vehicleDetailError && !fetchingVehicleDetail)
  //     getVehicleDetail(tracker);
  // }, [tracker, vehicleDetail, vehicleDetailError, fetchingVehicleDetail, getVehicleDetail]);

  return (
    <Card className={className} loading={fetchingVehicleDetail}>
      <div style={{ position: 'relative' }}>
        <Button size="small" style={{ position: 'absolute', top: 0, right: 0 }}>
          Editar
        </Button>
        <Space direction="vertical" size="large">
          <GroupItems className={className} data={dataPersonal} />
          <GroupItems className={className} data={dataVehicle} />
        </Space>
      </div>

      <Divider />

      <div className={`${className}__footer-item`}>
        <Button size="small" style={{ position: 'absolute', top: 0, right: 0 }}>
          Desvincular
        </Button>
        <GroupItems className={className} data={dataDevice} />
      </div>
    </Card>
  );
};

VehicleInfo.propTypes = {
  className: PropTypes.string,
  fetchingVehicleDetail: PropTypes.bool,
};

VehicleInfo.defaultProps = {
  className: '',
  fetchingVehicleDetail: false,
};

const mapStateToProps = (state) => {
  return {
    fetchingVehicleDetail: state.vehicles.fetchingVehicleDetail,
    vehicleDetailError: state.vehicles.vehicleDetailError,
    vehicleDetail: state.vehicles.vehicleDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getVehicleDetail } = vehicleActions;
  return {
    getVehicleDetail: (tracker) => dispatch(getVehicleDetail(tracker)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
