import React from 'react';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';

import store from '@store';
import history from '@utils/history';

import './App.less';
import MainRoutes from './MainRoutes';

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <LastLocationProvider>
          <MainRoutes />
        </LastLocationProvider>
      </Router>
    </Provider>
  );
};

export default App;