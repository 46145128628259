import constants from './constants';

const initState = {
  dateRangeType: null,
  dateRangeInput: null,
  groupIdSelected: null,
  vehicleTarget: null,
  vehiclesGroup: null,
  lastGroupIdRequested: null,
};

export default (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_GROUP_ID:
      return {
        ...state,
        groupIdSelected: payload,
      };

    case constants.SET_RANGE_DATE:
      const { dateRangeType, dateRangeInput } = payload;
      return {
        ...state,
        dateRangeType,
        dateRangeInput,
      };

    case constants.SET_VEHICLE:
      return {
        ...state,
        vehicleTarget: payload,
      };

    case constants.SET_VEHICLES:
      const { vehicles: vehiclesGroup, lastGroupIdRequested } = payload;
      return {
        ...state,
        lastGroupIdRequested,
        vehiclesGroup,
      };

    default:
      return state;
  }
};
