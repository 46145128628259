const getStatusDict = (key) => {
    const estado_definicion = {
        AVAILABLE:["Disponible","#0DC390"],
        BLOCKED:["BLOCKED","#262626"],
        CHARGING:["Cargando","#49A2D8"],
        INTEROPERATIVE:["INTEROPERATIVE","#262626"],
        OUTOFORDER:["Fuera de servicio","#262626"],
        PLANNED:["Fallo de conexión","#F60141"],
        REMOVED:["REMOVED","#262626"],
        RESERVED:["Reservado","#F0B518"],
        UNKNOWN:["Desconocido","#F60141"],
        UNKNOW:["Desconocido","#F60141"]
    };

    let estado = estado_definicion[`${key}`];

    if(!estado){
        const keys = Object.keys(estado_definicion);
        for(let i = 0 ; i < keys.length ; i++){
            if(estado_definicion[`${keys[i]}`][0] == key){
                estado = estado_definicion[`${keys[i]}`];
                break;
            }
        }
    }

    return estado;
}

export default getStatusDict;