import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BasicTable from '../components/BasicTable';
import CardTemplate from '../components/CardTemplate';

const columns = [
  {
    title: 'CONDUCTOR',
    dataIndex: 'conductor',
    width: '30%',
  },
  {
    title: 'FECHA',
    dataIndex: 'date',
    width: '20%',
  },
  {
    title: 'ALERTA',
    dataIndex: 'alerta',
    width: '50%',
  },
];

const News = (props) => {
  const { className } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoading(false);
      clearInterval(interval);
    }, 1200);
  }, [isLoading]);

  return (
    <CardTemplate className={className} title="Bandeja de novedades">
      <BasicTable columns={columns} data={null} isLoading={isLoading} />
    </CardTemplate>
  );
};

News.propTypes = {
  className: PropTypes.string,
};

News.defaultProps = {
  className: '',
};

export default News;
