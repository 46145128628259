import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLastLocation } from 'react-router-last-location';
import './style.less';

import actions from '@store/reducers/tableroGeneral/vehicleView/actions';
import Map from '@components/mapa/GoogleMap';
import { tableroGeneral } from '@utils/menus/main/itemsMenu';
import Breadcrumb from '@containers/Breadcrumb';

import { News } from '../../containers';

import {
  VehicleInfo,
  VelocidadCorriente,
  VehicleVars,
  HistorialHabitos,
  MarkerOnMap,
  LogsDevice,
} from './containers';
import useRequestJournayCharges from './hooks/useRequestJourneysCharges';

const SingleStatus = (props) => {
  const [map, setMap] = useState();
  const dispatch = useDispatch();
  const {
    match: {
      params: { vehicleId: placa },
    },
  } = props;

  const { path, name } = tableroGeneral;
  const lastLocation = useLastLocation() || path;
  const { pathname = path } = lastLocation;

  const breadcrumbData = [
    [pathname, name],
    [null, `Vehículo ${placa}`],
  ];

  const {
    journeys,
    charges,
    loading: fetchJourneysCharges,
    error: errorJourneysCharges,
    dateRange: dateRangeJourneysCharges,
  } = useRequestJournayCharges(placa);


  useEffect(
    () => {
      dispatch(actions.fetchVehicleByPlaca(placa));
      return function cleanup() {
        dispatch(actions.resetState());
      };
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Breadcrumb breadcrumbData={breadcrumbData} />

      <div id="single-info-vehicle">
        <section>
          <VehicleInfo />
          <div className="map">
            <Map getMap={setMap}>
              <MarkerOnMap map={map} />
            </Map>
          </div>
          <VehicleVars />
          <News className="news" loading />
        </section>

        <section>
          <VelocidadCorriente />
          <HistorialHabitos
            journeys={journeys}
            charges={charges}
            loading={fetchJourneysCharges}
            error={errorJourneysCharges}
            dateRange={dateRangeJourneysCharges}
          />
          <LogsDevice />
        </section>
      </div>
    </>
  );
};

SingleStatus.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      vehicleId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default SingleStatus;
