import React from 'react';
import { UserOutlined } from '@ant-design/icons';

const path = '/mis-vehiculo-ev';

const misVehiculosEV = {
  path,
  name: 'Mis Vehículos EV',
  icon: <UserOutlined />,
  isSubMenu: true,
  children: [
    {
      path: `${path}/administrar-flotas-ev`,
      name: 'Administrar Flotas EV',
      icon: <UserOutlined />,
      key: 'item-4',
    },
    {
      path: `${path}/control-turnos`,
      name: 'Control de turnos',
      icon: <UserOutlined />,
      key: 'item-5',
    },
    {
      path: `${path}/administrar-geocercas`,
      name: 'Administrar geocercas',
      icon: <UserOutlined />,
      key: 'item-6',
    },
    {
      path: `${path}/administrar-dispositivos`,
      name: 'Administrar dispositivos',
      icon: <UserOutlined />,
      key: 'item-7',
    },
  ],
};

export default misVehiculosEV;
