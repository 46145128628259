import React from 'react';
import { Popover, Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import Content from './components/Content';

const NotificationCenter = () => {
  return (
    <Popover placement="bottomRight" trigger="click" content={Content} align="center">
      <Button
        size="large"
        type="text"
        icon={<FontAwesomeIcon icon={faBell} style={{ fontSize: 21,color:'white' }} />}
      />
    </Popover>
  );
};

export default NotificationCenter;
