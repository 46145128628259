import React, { useEffect, useState } from 'react';

import { Select, Input, Typography } from 'antd';
import { apiOperators } from '@utils/services/cpo';

const { Option } = Select;
const { Search } = Input;
const { Paragraph } = Typography;

const Filtros = () => {
    const [state, setState] = useState(
        { 
            operators: [], 
            locations: [], 
            evseStatus: [] 
        }
    );

    const [operators, setOperators] = useState([]);
    const [locations, setLocations] = useState([]);
    const [evseStatus, setEvsetatus] = useState([]);
    
    useEffect(() => {
        setState({ ...state, isFething: true });
        apiOperators
            .operators()
            .then((operators) => {
                setOperators(operators);
            })
            .catch((error) => {
                setState({ ...state, isFething: false });
            });

        apiOperators
            .locationsName()
            .then((locations) => {
                console.log(locations)
                setLocations(locations);
            })
            .catch((error) => {
                setEvsetatus({ ...state, isFething: false });
            });

        apiOperators
            .statusEvse()
            .then((evseStatus) => {
                setEvsetatus(evseStatus);
            })
            .catch((error) => {
                setState({ ...state, isFething: false });
            });
        // eslint-disable-next-line
    }, []);

    //const { operators, locations, evseStatus } = state;
    console.log({ operators, locations, evseStatus })
    return (
        <div className="filtros-tabla">
            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todos los países"
                disabled
            >
            </Select>

            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todas las ciudades"
                disabled
            >
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="tom">Tom</Option>
            </Select>

            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todos los operadores"
            >
                {operators.map(({ id, name }) => {
                    return (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    );
                })}
            </Select>

            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todas las estaciones"
            >
                {locations.map(({ id, name }) => {
                    return (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    );
                })}
            </Select>

            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todos los estados"
            >
                {evseStatus.map(({ id, name }) => {
                    return (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    );
                })}
                
            </Select>

            <Search
                placeholder="Buscar palabras clave"
                onSearch={value => console.log(value)}
                style={{ width: 170 }}
            />

            <Paragraph style={{ margin: 0 }}>Filtro por vehículo:</Paragraph>
            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todas lass líneas"
                disabled
            >
                <Option value="jack">AVAILABLE</Option>
                <Option value="lucy">OFF</Option>
                <Option value="tom">ON</Option>
            </Select>
            <Select
                className="select-filtro"
                style={{ width: 170 }}
                placeholder="Todas las marcas"
                disabled
            >
                <Option value="jack">AVAILABLE</Option>
                <Option value="lucy">OFF</Option>
                <Option value="tom">ON</Option>
            </Select>
        </div>
    );
};

export default Filtros;
